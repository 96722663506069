import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ADDRESS_ZIP_PATTERN } from '@app/features/clients/clients.constants';

/**
 * Form validator for Address fields.
 *
 * More info related to the regex pattern in https://app.asana.com/0/1200548108266942/1205466752371376
 */
export const addressFormInputValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    if (value && /[^\u0020-\u007E\u00A1-\u00FF]/i.test(value)) {
      return { illegalCharactersEncoding: true };
    }
    return null;
  };
};

export const createZipCodeValidator = (): ValidatorFn => {
  return ((form: FormGroup): ValidationErrors | null => {
    const country = form.get('country_code')?.value;
    const zipCode = form.get('zip_code')?.value;
    let zipInvalid = false;

    if (country === '276' && !ADDRESS_ZIP_PATTERN.test(zipCode)) {
      form.controls['zip_code']?.setErrors({ zipInvalid: true });
      zipInvalid = true;
    }

    return {
      ...(zipInvalid && { zipInvalid }),
    };
  }) as ValidatorFn;
};
