import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { CsvTemplate } from '@app/features/csv-upload-new/models/csv-template';
import { CsvTemplateService } from '@app/features/csv-upload-new/services/csv-template.service';
import { CoCsvUploadTemplateStore } from '@app/features/csv-upload-new/store/co-csv-upload-template.store';
import { ClientAutocompleteFieldComponent } from '@app/shared/components/client-autocomplete-field/client-autocomplete-field.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FileDropAreaComponent } from '@givve/csv-upload';
import { ContentLoadingComponent, ContentToolbarComponent } from '@givve/ui-kit/components';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { provideComponentStore } from '@ngrx/component-store';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-csv-upload-for-card-orders',
  standalone: true,
  imports: [
    CommonModule,
    ContentToolbarComponent,
    MatCardModule,
    FileDropAreaComponent,
    SvgIconComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatListModule,
    ClientAutocompleteFieldComponent,
    ContentLoadingComponent,
    FaIconComponent,
  ],
  providers: [provideComponentStore(CoCsvUploadTemplateStore)],
  templateUrl: './csv-upload-for-card-orders.component.html',
})
export class CsvUploadForCardOrdersComponent implements OnInit {
  private csvTemplateService = inject(CsvTemplateService);
  private coCsvUploadTemplateStore = inject(CoCsvUploadTemplateStore);

  vm$ = this.coCsvUploadTemplateStore.vm$;

  ngOnInit(): void {
    this.coCsvUploadTemplateStore.resetState();
  }

  handleFileDropAreaFileAdded(file: File) {
    this.coCsvUploadTemplateStore.preloadDataAndValidatePossibleStructure(file);
  }

  downloadTemplate(csvTemplate: CsvTemplate) {
    this.csvTemplateService.createCsvTemplate(csvTemplate);
  }

  openInfoForTemplateDialog(csvTemplate: CsvTemplate, event: Event) {
    event.stopPropagation();
    this.coCsvUploadTemplateStore.openInfoCsvPreviewDialog(csvTemplate);
  }
}
