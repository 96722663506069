<form
  [formGroup]="form"
  class="comment-form"
  data-cy="givve-comment-form"
>
  <mat-form-field class="input-field-without-padding">
    <textarea
      matInput
      formControlName="comment"
      [placeholder]="this.computedOptions().placeholder"
      [cdkAutosizeMinRows]="this.computedOptions().minTextareaRows"
      [cdkAutosizeMaxRows]="this.computedOptions().maxTextareaRows"
      cdkTextareaAutosize
    >
    </textarea>
  </mat-form-field>
  <div class="buttons">
    @if (this.computedOptions().enableCancelButton) {
      <button
        mat-flat-button
        (click)="onCancel()"
        color="secondary"
        data-cy="givve-comment-form-cancel-button"
        data-testid="givve-comment-form-cancel-button"
      >
        <span>{{ this.computedOptions().cancelButtonLabel }}</span>
      </button>
    }
    <button
      mat-flat-button
      (click)="onSubmit()"
      color="primary"
      type="button"
      [disabled]="form.invalid || form.pristine || isLoading()"
      data-cy="givve-comment-form-submit-button"
      data-testid="givve-comment-form-submit-button"
    >
      <givve-button-spinner [loading]="isLoading()">
        <span>{{ this.computedOptions().submitButtonLabel }}</span>
      </givve-button-spinner>
    </button>
  </div>
</form>
