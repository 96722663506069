@if (invoice()?.number) {
  <a
    onclick="event.stopPropagation()"
    target="_blank"
    [href]="redirectBasePath + invoice().id"
    >{{ invoice().number }}</a
  >
  &nbsp;
  <a
    onclick="event.stopPropagation()"
    target="_blank"
    [href]="apiBasePath + invoice().id + '.pdf'"
    ><fa-icon icon="file-pdf"
  /></a>
} @else {
  -
}
