import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { Component, EventEmitter, Output, Signal, computed, effect, input, viewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ButtonSpinnerComponent } from '@givve/ui-kit/components';

const DEFAULT_OPTIONS = {
  placeholder: 'Add a comment',
  enableCancelButton: true,
  cancelButtonLabel: 'Cancel',
  submitButtonLabel: 'Post',
  minTextareaRows: 5,
  maxTextareaRows: 10,
};

@Component({
  selector: 'givve-comment-form',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    ButtonSpinnerComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './comment-form.component.html',
  styleUrl: './comment-form.component.scss',
})
export class CommentFormComponent {
  @Output() formSubmit = new EventEmitter<string>();
  @Output() formCancel = new EventEmitter();

  isLoading = input(false);
  defaultValue = input<string>('');
  options = input<
    Partial<{
      placeholder: string;
      cancelButtonLabel: string;
      submitButtonLabel: string;
      enableCancelButton: boolean;
      minTextareaRows: number;
      maxTextareaRows: number;
    }>
  >();

  autosize = viewChild.required<CdkTextareaAutosize>('autosize');

  defaultValueEffect = effect(() => {
    if (this.defaultValue()) {
      this.form.get('comment')!.patchValue(this.defaultValue());
    }
  });

  /**
   * Provide default options
   *
   * @memberof CommentFormComponent
   */
  computedOptions: Signal<typeof DEFAULT_OPTIONS> = computed(() => ({
    ...DEFAULT_OPTIONS,
    ...this.options(),
  }));

  form = new FormGroup({
    comment: new FormControl('', [Validators.required]),
  });

  /**
   * Submit the form
   */
  onSubmit() {
    if (this.form.valid) {
      this.formSubmit.emit(this.form.value.comment as string);
      this.form.reset();
    }
  }

  /**
   * Cancel the form
   */
  onCancel() {
    this.formCancel.emit();
  }
}
