import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '@givve/ui-kit/models';

@Injectable()
export class FrameService {
  public logout = new Subject<void>();
  private profile = new Subject<User>();
  private openGroup = new Subject<string>();

  public logoutTrigger$ = this.logout.asObservable();
  public profileClick$ = this.profile.asObservable();
  public openGroup$ = this.openGroup.asObservable();

  public triggerOpenGroup(group: string): void {
    this.openGroup.next(group);
  }

  public triggerLogout(): void {
    this.logout.next();
  }

  public profileClicked(user: User): void {
    this.profile.next(user);
  }
}
