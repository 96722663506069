import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { VALID_COUNTRIES } from '@app/features/customer-create/constants';
import { TrimDirective } from '@givve/ui-kit/directives';
import { TranslateModule } from '@ngx-translate/core';
import * as i18nIsoCountries from 'i18n-iso-countries';

@Component({
  selector: 'app-client-address-form',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    CommonModule,
    MatSelectModule,
    TrimDirective,
  ],
  templateUrl: './client-address-form.component.html',
})
export class ClientAddressFormComponent {
  addressFormGroup = input.required<FormGroup>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  submit = output();

  countryOptions = VALID_COUNTRIES.map((code) => ({
    name: i18nIsoCountries.getName(code, 'de', { select: 'official' }),
    code,
  }));
}
