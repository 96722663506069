import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { environment } from '@app/env';
import { TranslateWithFallbackPipe } from '@app/shared/pipes/translate-with-fallback.pipe';
import { Invoice } from '@givve/ui-kit/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-invoice-state',
  imports: [MatChipsModule, CommonModule, TranslateModule, TranslateWithFallbackPipe],
  templateUrl: './invoice-state.component.html',
})
export class InvoiceStateComponent {
  invoice = input.required<Invoice>();

  onCancellationNumberClicked() {
    window.open(`${environment.adminApiUrlV1}/invoices/${this.invoice().id}/cancellation.pdf`, '_blank');
  }
}
