import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'givve-nav-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nav-footer.component.html',
  styleUrls: ['./nav-footer.component.scss'],
})
export class NavFooterComponent {}
