import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { LetDirective } from '@ngrx/component';
import { FrameStore } from '../../store/frame.store';
import { ToolbarProfileComponent } from './toolbar-profile/toolbar-profile.component';

@Component({
  selector: 'givve-frame-toolbar',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatButtonModule, SvgIconComponent, ToolbarProfileComponent, LetDirective],
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  frameStore = inject(FrameStore);
  breakpointObserver = inject(BreakpointObserver);

  user$ = this.frameStore.user$;
  isMobile$ = this.frameStore.isMobile$;

  toggleMenu() {
    this.frameStore.toggleMenu();
  }
}
