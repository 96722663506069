import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ClientAddressFormComponent } from '@app/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { every, isNull } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'app-address-form',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    CommonModule,
    MatSelectModule,
    MatCheckboxModule,
    ClientAddressFormComponent,
  ],
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent implements OnInit {
  addressFormGroup = input.required<FormGroup>();
  deliveryAddressFormGroup = input.required<FormGroup>();
  isSameAddressesChecked = true;

  ngOnInit() {
    this.deliveryAddressFormGroup()
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateDeliveryAddressFormGroupValidators();
      });
  }

  public updateDeliveryAddressFormGroupValidators() {
    const formGroupValue = this.deliveryAddressFormGroup().value;
    const isEmpty = every(formGroupValue, (v) => v! === '' || isNull(v));

    Object.keys(formGroupValue).forEach((k) => {
      if (k === 'address_line_2' || k === 'contact_person') {
        return;
      }

      const control = this.deliveryAddressFormGroup().get(k)!;

      if (isEmpty || this.isSameAddressesChecked) {
        control.removeValidators([Validators.required]);
      } else {
        control.addValidators([Validators.required]);
      }
      control.updateValueAndValidity({ emitEvent: false });
    });
  }
}
