import { Component, DestroyRef, OnDestroy, OnInit, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { buildSearchOptionsParameters } from '@app/shared';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Customer, Multiplier } from '@givve/ui-kit/models';
import { LetDirective } from '@ngrx/component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';
import { ClientAutoCompleteFieldStore } from './client-autocomplete-field.store';

export interface TypedForm {
  client: FormControl<string | null>;
}

/**
 * A reusable component that provides an autocomplete field for selecting a Customer or Multiplier.
 *
 * The component is able to handle both Customer & Multiplier requests, or just one of them.
 */
@Component({
  selector: 'app-client-autocomplete-field',
  providers: [ClientAutoCompleteFieldStore],
  imports: [
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    FaIconComponent,
    LetDirective,
  ],
  templateUrl: './client-autocomplete-field.component.html',
})
export class ClientAutocompleteFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private translateService = inject(TranslateService);

  clientType = input.required<['customer', 'multiplier'] | ['customer'] | ['multiplier']>();
  title = input<string>(this.translateService.instant('common.customer'));

  private _buildSearchOptionsParameters = buildSearchOptionsParameters();

  private ngControl = inject(NgControl);

  private destroyRef = inject(DestroyRef);
  private store = inject(ClientAutoCompleteFieldStore);

  form = new FormGroup<TypedForm>({
    client: new FormControl(null, []),
  });

  constructor() {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  vm$ = this.store.vm$;

  /**
   * ControlValueAccessor method
   */
  onChange = (_value: Customer | Multiplier | null) => {};

  /**
   * ControlValueAccessor method
   */
  onTouched = () => {};

  /**
   * ControlValueAccessor method
   */
  writeValue(value: string | null) {
    this.form.get('client')?.setValue(value);
  }

  /**
   * ControlValueAccessor method
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * ControlValueAccessor method
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Helper variable to prevent the client field from updating
   * while the user is typing
   */
  clientFieldIsUpdating = false;

  /**
   * Whether the client field is required or not.
   * It is used in the template to display the required asterisk.
   */
  clientFieldIsRequired = false;

  ngOnInit() {
    const control = this.ngControl?.control;

    if (control) {
      if ((this.clientFieldIsRequired = control.hasValidator(Validators.required))) {
        this.form.get('client')?.setValidators([Validators.required]);
      }
    }

    this.form
      .get('client')
      ?.valueChanges.pipe(
        tap(() => {
          this.clientFieldIsUpdating = true;
        }),
        debounceTime(400),
        distinctUntilChanged(),
        filter((value) => typeof value !== 'object'),
        tap((value) => {
          if (value) {
            this.store.searchClients({
              clientType: this.clientType(),
              requestOptions: this._buildSearchOptionsParameters(value),
            });
          } else {
            this.store.clearComponentState();
          }
          this.clientFieldIsUpdating = false;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  /**
   * Handle the client selection from the autocomplete dropdown.
   */
  handleClientSelection(client: MatAutocompleteSelectedEvent) {
    this.onChange(client.option.value);
  }

  /**
   * Clear the autocomplete field, reset the form control value and clear the store state.
   */
  private clearAutoComplete() {
    this.form.get('client')?.reset();
    this.onChange(null);
    this.store.clearComponentState();
  }

  /**
   * Check whether the selected value is actual a Client (Customer or Multiplier) object
   * rather than a plain string input value.
   */
  private isSelectedClientValid() {
    return typeof this.form.get('client')?.value !== 'string';
  }

  /**
   * When close the autocomplete check whether user has actually selected a value
   * from the autocomplete dropdown.
   */
  handleCloseAutocomplete() {
    if (!this.isSelectedClientValid()) {
      this.clearAutoComplete();
    }
  }

  /**
   * Clear text input callback
   */
  resetAutocompleteField() {
    this.clearAutoComplete();
  }

  /**
   * Material Autocomplete callback to separate control and display values
   */
  clientDisplayWith(client: Customer | Multiplier) {
    return client?.name;
  }

  ngOnDestroy() {
    this.store.clearComponentState();
  }
}
