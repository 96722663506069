<div class="container">
  <h5 mat-dialog-title>{{ 'multiplier-create-dialog.title' | translate }}</h5>
  <div
    mat-dialog-content
    class="tw-mt-2 tw-max-h-[90vh]"
  >
    <givve-stepper>
      <cdk-step
        [stepControl]="step1FormGroup"
        (interacted)="step1FormGroup.markAllAsTouched()"
        label="{{ 'common.billing_data' | translate }}"
      >
        <app-client-address-form [addressFormGroup]="addressFormGroup" />

        <form [formGroup]="vatFormGroup">
          <div class="tw-flex tw-gap-3">
            <app-client-legal-form
              [control]="$any(vatFormGroup.get('legal_form'))"
              [companyControl]="$any(addressFormGroup.get('company'))"
            />

            <mat-form-field>
              <mat-label> {{ 'common.vat-number' | translate }} </mat-label>
              <input
                formControlName="vat_number"
                matInput
                givveTrim
              />
            </mat-form-field>
            <app-client-vat-zone
              [control]="$any(vatFormGroup.get('vat_zone'))"
              [countryCodeControl]="$any(addressFormGroup.get('country_code'))"
            >
            </app-client-vat-zone>
          </div>
          <app-x-invoice-buyer-reference-field
            formControlName="x_invoice_buyer_reference"
          ></app-x-invoice-buyer-reference-field>
        </form>

        <div class="tw-mt-4 tw-flex tw-justify-end">
          <button
            color="primary"
            mat-raised-button
            matStepperNext
          >
            {{ 'common.next' | translate }}
          </button>
        </div>
      </cdk-step>
      <cdk-step
        [stepControl]="step2FormGroup"
        (interacted)="step2FormGroup.markAllAsTouched()"
        label="{{ 'multiplier-create-dialog.production_data_invoice_recipients' | translate }}"
      >
        <app-fieldset [title]="'common.production_data' | translate">
          <app-client-carrier-type
            [control]="$any(productionsDataFormGroup.get('default_carrier_type'))"
            [isRecarding]="false"
          />
          <app-client-carrier-type
            [control]="$any(productionsDataFormGroup.get('default_renew_carrier_type'))"
            [isRecarding]="true"
          />
        </app-fieldset>

        <app-fieldset
          [title]="'common.invoice_recipient_type' | translate"
          class="tw-flex-gap-3 tw-flex tw-pt-3"
        >
          <form
            [formGroup]="invoiceRecipientsFormGroup"
            class="tw-flex tw-gap-3"
          >
            <mat-form-field data-cy="multiplier-create-dialog-fee-invoice">
              <mat-label> {{ 'multiplier-create-dialog.fee_invoice' | translate }} </mat-label>
              <mat-select formControlName="fee_invoice">
                <mat-option [value]="true">{{ 'common.multi' | translate }}</mat-option>
                <mat-option [value]="false">{{ 'common.customer' | translate }}</mat-option>
              </mat-select>
              @if (invoiceRecipientsFormGroup.controls.fee_invoice.errors?.required) {
                <mat-error>
                  {{ 'common.errors.required' | translate }}
                </mat-error>
              }
            </mat-form-field>

            <mat-form-field data-cy="multiplier-create-dialog-default-load-order-invoice-recipient-type">
              <mat-label>
                {{ 'multiplier-create-dialog.default_load_order_invoice_recipient_type' | translate }}
              </mat-label>
              <mat-select formControlName="default_load_order_invoice_recipient_type">
                <mat-option [value]="'multiplier'">{{ 'common.multi' | translate }}</mat-option>
                <mat-option [value]="'customer'">{{ 'common.customer' | translate }}</mat-option>
              </mat-select>
              @if (invoiceRecipientsFormGroup.controls.default_load_order_invoice_recipient_type.errors?.required) {
                <mat-error>
                  {{ 'common.errors.required' | translate }}
                </mat-error>
              }
            </mat-form-field>

            <mat-form-field data-cy="multiplier-create-dialog-default-card-order-invoice-recipient-type">
              <mat-label>
                {{ 'multiplier-create-dialog.default_card_order_invoice_recipient_type' | translate }}
              </mat-label>
              <mat-select formControlName="default_card_order_invoice_recipient_type">
                <mat-option [value]="'multiplier'">{{ 'common.multi' | translate }}</mat-option>
                <mat-option [value]="'customer'">{{ 'common.customer' | translate }}</mat-option>
              </mat-select>
              @if (invoiceRecipientsFormGroup.controls.default_card_order_invoice_recipient_type.errors?.required) {
                <mat-error>
                  {{ 'common.errors.required' | translate }}
                </mat-error>
              }
            </mat-form-field>
          </form>
        </app-fieldset>

        <div class="tw-mt-4 tw-flex tw-justify-end">
          <button
            class="tw-mr-1"
            mat-button
            matStepperPrevious
          >
            {{ 'common.back' | translate }}
          </button>
          <button
            color="primary"
            mat-raised-button
            matStepperNext
            data-cy="multiplier-create-dialog-next-button"
          >
            {{ 'common.next' | translate }}
          </button>
        </div>
      </cdk-step>

      <cdk-step
        [stepControl]="step3FormGroup"
        (interacted)="step3FormGroup.markAllAsTouched()"
        label="{{ 'multiplier-create-dialog.account_contact_persons' | translate }}"
      >
        <form [formGroup]="b2bSuiteSettingsFormGroup">
          <mat-form-field>
            <mat-label> {{ 'common.language-email' | translate }} </mat-label>
            <mat-select
              formControlName="language_code"
              required
            >
              <mat-option
                *ngFor="let code of languageCodes"
                [value]="code"
                >{{ 'common.language_code.' + code | translate }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="b2bSuiteSettingsFormGroup.controls.language_code.errors?.required">
              {{ 'common.fill_out_field' | translate }}
            </mat-error>
          </mat-form-field>
        </form>

        <app-client-contact-persons-form [formGroup]="contactPersonsFormGroup" />

        <div class="tw-mt-4 tw-flex tw-justify-end">
          <button
            class="tw-mr-1"
            mat-button
            matStepperPrevious
          >
            {{ 'common.back' | translate }}
          </button>
          <button
            color="primary"
            mat-raised-button
            matStepperNext
            data-cy="multiplier-create-dialog-done-button"
            [disabled]="multiplierCreating || !(step1FormGroup.valid && step2FormGroup.valid && step3FormGroup.valid)"
            (click)="onDoneClick()"
          >
            <givve-button-spinner [loading]="multiplierCreating">
              {{ 'common.done' | translate }}
            </givve-button-spinner>
          </button>
        </div>
      </cdk-step>
    </givve-stepper>
  </div>
</div>
