import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Releasenote } from '@app/features/releasenotes/models/releasenote';
import { TranslateModule } from '@ngx-translate/core';
import { ReleasenoteCardComponent } from '../../shared/components/releasenote-card/releasenote-card.component';

@Component({
  selector: 'app-releasenotes-dialog',
  imports: [CommonModule, MatDialogModule, TranslateModule, MatCardModule, MatButtonModule, ReleasenoteCardComponent],
  templateUrl: './releasenotes-dialog.component.html',
})
export class ReleasenotesDialogComponent {
  data: Releasenote[] = inject(MAT_DIALOG_DATA);
}
