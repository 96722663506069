import { Injectable } from '@angular/core';
import { Document, LegalEntity } from '@app/api';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import {
  LegalEntityDeleteDocumentPayload,
  LegalEntityDownloadDocumentPayload,
  LegalEntityUpdateSanctionScanResultsStatePayload,
  LegalEntityUpdateSanctionStatePayload,
  LegalEntityUploadNewDocumentPayload,
} from '@app/features/clients/client.models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LegalEntityHttpService extends MultiObjectHTTPService<LegalEntity> {
  memberUri = new URITemplate('{+compliance_api_base}/legal_entities/{id}');
  collectionUri = new URITemplate('{+compliance_api_base}/legal_entities');
  searchUri = new URITemplate(`{+compliance_api_base}/search/legal_entities`);
  sanctionStateUri = new URITemplate(`{+compliance_api_base}/legal_entities/{id}/sanction_state`);
  sanctionScanUri = new URITemplate(`{+compliance_api_base}/legal_entities/{legalEntityId}/sanction_scan_results/{id}`);
  documentsCollectionUri = new URITemplate(`{+compliance_api_base}/legal_entities/{legalEntityId}/documents`);
  documentsMemberUri = new URITemplate(`{+compliance_api_base}/legal_entities/{legalEntityId}/documents/{id}`);

  updateSanctionState({
    legalEntityId,
    sanctionState,
  }: LegalEntityUpdateSanctionStatePayload): Observable<LegalEntity> {
    const uri = this.sanctionStateUri.build({ id: legalEntityId });
    return this.put(uri, { sanction_state: sanctionState }).pipe(map((response) => response.data));
  }

  updateSanctionScanResultsState({
    sanctionScanResultId,
    legalEntityId,
    sanctionState,
    stateChangeReason,
  }: LegalEntityUpdateSanctionScanResultsStatePayload): Observable<LegalEntity> {
    const uri = this.sanctionScanUri.build({ legalEntityId, id: sanctionScanResultId });
    return this.put(uri, {
      sanction_state: sanctionState,
      state_change_reason: stateChangeReason,
    }).pipe(map((response) => response.data));
  }

  uploadDocument(params: LegalEntityUploadNewDocumentPayload): Observable<Document> {
    const uri = this.documentsCollectionUri.build({ legalEntityId: params.legalEntityId });
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('document_type', params.documentType);
    params.comment && formData.append('comment', params.comment);
    params.source && formData.append('source', params.source);
    return this.postFormData(uri, formData).pipe(map((response) => response.data));
  }

  getDocument(params: LegalEntityDownloadDocumentPayload): Observable<Response> {
    const uri = this.documentsMemberUri.build({ legalEntityId: params.legalEntityId, id: params.documentId });
    return this.getFile(uri);
  }

  deleteDocument(params: LegalEntityDeleteDocumentPayload): Observable<Response> {
    const uri = this.documentsMemberUri.build({ legalEntityId: params.legalEntityId, id: params.documentId });
    return this.delete(uri);
  }
}
