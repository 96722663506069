import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterLink, RouterOutlet } from '@angular/router';
import { AuthFacade } from '@app/auth';
import { environment } from '@app/env';
import { CustomerCreateDialogComponent } from '@app/features/customer-create/customer-create-dialog/customer-create-dialog.component';
import { MultiplierCreateDialogComponent } from '@app/features/multiplier-create/multiplier-create-dialog/multiplier-create-dialog.component';
import { MenuStore } from '@app/shared';
import { ContentWrapperComponent } from '@app/shared/components/content-wrapper/content-wrapper.component';
import { GlobalSearchFieldComponent } from '@app/shared/components/global-search-field/global-search-field.component';
import { FrameModule } from '@givve/ui-kit/features';
import { RouterHistoryService } from '@givve/ui-kit/services';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LetDirective } from '@ngrx/component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@UntilDestroy()
@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatSidenavModule,
    RouterLink,
    GlobalSearchFieldComponent,
    RouterOutlet,
    LetDirective,
    FrameModule,
    CommonModule,
    TranslateModule,
    MatMenuModule,
    ContentWrapperComponent,
    MatListModule,
    SvgIconComponent,
    MatButtonModule,
    BreadcrumbsComponent,
  ],
})
export class FrameComponent {
  private translate = inject(TranslateService);
  public authFacade = inject(AuthFacade);
  public routerHistoryService = inject(RouterHistoryService);
  private menuStore = inject(MenuStore);
  protected dialog = inject(MatDialog);

  protected customerCreateDialogComponent = CustomerCreateDialogComponent;

  protected multiplierCreateDialogComponent = MultiplierCreateDialogComponent;
  protected clientCreateDialogConfig = {
    width: '1550px',
    maxWidth: '95vw',
  };

  version = `${environment.version}`;

  menu$ = this.menuStore.vm$;

  actionTriggered(action: string) {
    switch (action) {
      case 'createCustomer':
        this.dialog.open(this.customerCreateDialogComponent, this.clientCreateDialogConfig);
        break;
      case 'createMultiplier':
        this.dialog.open(this.multiplierCreateDialogComponent, this.clientCreateDialogConfig);
        break;
      default:
        break;
    }
  }

  onLogout() {
    this.authFacade.logout();
  }

  onOldAdminClick() {
    window.location.href = environment.adminUrlV1;
  }

  onBackClick() {
    this.menuStore.goBack();
  }
}
