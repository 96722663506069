import { Injectable, signal } from '@angular/core';
import { Comment } from '@givve/ui-kit/models';

@Injectable()
export class CommentFormService {
  #formData = signal<{ comment: Comment | null; commentText: string; operation: 'edit' | 'reply' | 'delete' | null }>({
    commentText: '',
    comment: null,
    operation: null,
  });

  readonly data = this.#formData.asReadonly();

  submitComment(commentText: string, comment: Comment, operation: 'edit' | 'reply') {
    this.#formData.set({ commentText, comment, operation });
  }

  deleteComment(comment: Comment) {
    this.#formData.set({ comment, commentText: '', operation: 'delete' });
  }
}
