import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

/**
 * This component is used to display an error message when the app fails to load on a
 * deferred loading strategy. It provides a button to reload the page.
 */
@Component({
  selector: 'app-error-placeholder',
  templateUrl: './error-placeholder.component.html',
  imports: [MatButton, FaIconComponent, TranslateModule],
})
export class ErrorPlaceholderComponent {
  tryAgain() {
    window.location.reload();
  }
}
