<form
  [formGroup]="addressFormGroup()"
  (ngSubmit)="submit.emit()"
>
  <mat-form-field data-cy="client-address-form-company">
    <mat-label> {{ 'common.company' | translate }} </mat-label>
    <input
      formControlName="company"
      matInput
      givveTrim
    />
    <mat-error *ngIf="addressFormGroup().controls.company.errors?.required">
      {{ 'common.fill_out_field' | translate }}
    </mat-error>
    <mat-error *ngIf="addressFormGroup().controls.company.errors?.pattern">
      {{ 'common.wrong_pattern_field' | translate }}
    </mat-error>
    <mat-error *ngIf="addressFormGroup().controls.company.errors?.maxlength">
      {{ 'common.errors.maxlength.length' | translate: { maxlength: 255 } }}
    </mat-error>
  </mat-form-field>
  <mat-form-field data-cy="client-address-form-contact-person">
    <mat-label> {{ 'common.contact_person' | translate }} </mat-label>
    <input
      formControlName="contact_person"
      matInput
      givveTrim
    />
    <mat-error *ngIf="addressFormGroup().controls.contact_person.errors?.pattern">
      {{ 'common.wrong_pattern_field' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field data-cy="client-address-form-address-line-1">
    <mat-label> {{ 'common.address.address_line_1' | translate }} </mat-label>
    <input
      formControlName="address_line_1"
      matInput
      givveTrim
    />
    <mat-error *ngIf="addressFormGroup().controls.address_line_1.errors?.required">
      {{ 'common.fill_out_field' | translate }}
    </mat-error>
    <mat-error *ngIf="addressFormGroup().controls.address_line_1.errors?.pattern">
      {{ 'common.wrong_pattern_field' | translate }}
    </mat-error>
    <mat-error *ngIf="addressFormGroup().controls.address_line_1.errors?.maxlength">
      {{ 'common.errors.maxlength.length' | translate: { maxlength: 50 } }}
    </mat-error>
  </mat-form-field>
  <mat-form-field data-cy="client-address-form-address-line-2">
    <mat-label> {{ 'common.address.address_line_2' | translate }} </mat-label>
    <input
      formControlName="address_line_2"
      matInput
      givveTrim
    />
    <mat-error *ngIf="addressFormGroup().controls.address_line_2.errors?.pattern">
      {{ 'common.wrong_pattern_field' | translate }}
    </mat-error>
    <mat-error *ngIf="addressFormGroup().controls.address_line_2.errors?.maxlength">
      {{ 'common.errors.maxlength.length' | translate: { maxlength: 50 } }}
    </mat-error>
  </mat-form-field>
  <div class="tw-grid tw-max-w-[800px] tw-grid-cols-2 tw-gap-2">
    <mat-form-field data-cy="client-address-form-zip-code">
      <mat-label> {{ 'common.address.zip_code' | translate }} </mat-label>
      <input
        formControlName="zip_code"
        matInput
        givveTrim
      />
      <mat-error *ngIf="addressFormGroup().controls.zip_code.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
      <mat-error *ngIf="addressFormGroup().errors?.zipInvalid">
        {{ 'common.errors.zip_code_invalid' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field data-cy="client-address-form-city">
      <mat-label> {{ 'common.address.city' | translate }} </mat-label>
      <input
        formControlName="city"
        matInput
        givveTrim
      />
      <mat-error *ngIf="addressFormGroup().controls.city.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
      <mat-error *ngIf="addressFormGroup().controls.city.errors?.pattern">
        {{ 'common.wrong_pattern_field' | translate }}
      </mat-error>
      <mat-error *ngIf="addressFormGroup().controls.city.errors?.maxlength">
        {{ 'common.errors.maxlength.length' | translate: { maxlength: 20 } }}
      </mat-error>
    </mat-form-field>
  </div>
  <mat-form-field data-cy="client-address-form-country">
    <mat-label> {{ 'common.address.country' | translate }} </mat-label>
    <mat-select formControlName="country_code">
      <mat-option
        *ngFor="let country of countryOptions"
        [value]="country.code"
      >
        {{ country.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="addressFormGroup().controls.country_code.errors?.required">
      {{ 'common.fill_out_field' | translate }}
    </mat-error>
  </mat-form-field>
  <button
    type="submit"
    hidden
  ></button>
</form>
