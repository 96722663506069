import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Person, Representative, SepaMandate, URIVariables } from '@app/api';
import { BaseHttpService } from '@app/api/services/base-http.service';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import { AssignPersonPayload } from '@app/features/clients/client.models';
import type { ApiResponse, Customer, RequestOptions } from '@givve/ui-kit/models';
import { Observable, takeLast } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomerHttpService extends MultiObjectHTTPService<Customer> {
  memberUri = new URITemplate('{+api_v1_base}/customers/{id}');
  collectionUri = new URITemplate('{+api_v1_base}/customers');
  searchUri = new URITemplate(`{+api_base}/admin/search/customers`);
  memberBankAccountsUri = new URITemplate('{+api_v1_base}/customers/{clientId}/bank_accounts');
  updateMemberBankAccountUri = new URITemplate('{+api_v1_base}/customers/{clientId}/bank_accounts/{bankAccountId}');
  memberRepresentativesUri = new URITemplate('{+api_v1_base}/customers/{clientId}/representatives');
  memberRepresentativesMemberUri = new URITemplate(
    '{+api_v1_base}/customers/{clientId}/representatives/{representativeId}'
  );
  searchRepresentativesUri = new URITemplate('{+api_base}/admin/search/representatives');
  updateRepresentativesComplianceApiKeyUri = new URITemplate(
    '{+api_v1_base}/customers/{clientId}/representatives/{representativeId}/compliance_data'
  );

  collectionIdentificationTokensUri = new URITemplate('{+api_v1_base}/customers/{clientId}/ident_tokens');

  sendIdentificationTokenUri = new URITemplate('{+api_v1_base}/customers/{clientId}/ident_tokens/{tokenId}/send');

  deleteIdentificationTokenUri = new URITemplate('{+api_v1_base}/customers/{clientId}/ident_tokens/{tokenId}');

  resetPasswordUri = new URITemplate('{+api_v1_base}/customers/{id}/reset_password');

  recardingDeliverUri = new URITemplate('{+api_v1_base}/customers/{id}/recarding/{year}/{quarter}/deliver');

  memberUriDeliveryAddress = new URITemplate('{+api_v1_base}/customers/{id}/delivery_address');

  memberUriStatus = new URITemplate('{+api_v1_base}/customers/{id}/activate');

  memberUriMultiplier = new URITemplate('{+api_v1_base}/customers/{id}/multiplier');
  memberUriAssignPersonUri = new URITemplate(
    '{+api_v1_base}/customers/{id}/representatives/{personId}/copy/{dest_customerId}'
  );

  sepaMandatesUri = new URITemplate('{+api_v1_base}/customers/{clientId}/sepa_mandates');
  sepaMandateUri = new URITemplate('{+api_v1_base}/customers/{clientId}/sepa_mandates/{mandateId}');

  getMemberBankAccounts<T>(uriVariables: URIVariables): Observable<ApiResponse<T>> {
    const uri = this.memberBankAccountsUri.build(uriVariables);
    return this.get(uri);
  }

  createMemberBankAccounts<T>(
    uriVariables: URIVariables,
    data: Record<string, unknown>
  ): Observable<Pick<ApiResponse<T>, 'meta' | 'data'>> {
    const uri = this.memberBankAccountsUri.build(uriVariables);
    return this.post(uri, data);
  }

  updateMemberBankAccounts<T>(
    uriVariables: URIVariables,
    data: Record<string, unknown>
  ): Observable<Pick<ApiResponse<T>, 'meta' | 'data'>> {
    const uri = this.updateMemberBankAccountUri.build(uriVariables);
    return this.put(uri, data);
  }

  updateRepresentative<T>(
    uriVariables: URIVariables,
    data: Record<string, unknown>
  ): Observable<Pick<ApiResponse<T>, 'meta' | 'data'>> {
    const uri = this.memberRepresentativesMemberUri.build(uriVariables);
    return this.put(uri, data);
  }

  updateRepresentativeComplianceApiKey<T>(
    uriVariables: URIVariables,
    data: Record<string, unknown>
  ): Observable<Pick<ApiResponse<T>, 'meta' | 'data'>> {
    const uri = this.updateRepresentativesComplianceApiKeyUri.build(uriVariables);
    return this.put(uri, data);
  }

  getMemberRepresentatives<T>(uriVariables: URIVariables) {
    const uri = this.memberRepresentativesUri.build(uriVariables);
    return this.getAll<T>(uri).pipe(takeLast(1));
  }

  getMemberRepresentativesMember<T>(uriVariables: URIVariables): Observable<ApiResponse<T>> {
    const uri = this.memberRepresentativesMemberUri.build(uriVariables);
    return this.get(uri);
  }

  createRepresentative<T>(
    uriVariables: URIVariables,
    data: Record<string, unknown>
  ): Observable<Pick<ApiResponse<T>, 'meta' | 'data'>> {
    const uri = this.memberRepresentativesUri.build(uriVariables);
    return this.post(uri, data);
  }

  searchRepresentatives(options: RequestOptions): Observable<ApiResponse<Representative[]>> {
    let params = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });
    params = this.appendSearchFilterParams(params, options);
    params = this.appendSearchSearchParams(params, options);

    return this.get(this.searchRepresentativesUri.build(), params);
  }

  getIdentificationTokens<T>(uriVariables: URIVariables, options: RequestOptions): Observable<ApiResponse<T>> {
    let params = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });
    options.sort && (params = params.append('sort', (options.direction === 'asc' ? '' : '-') + options.sort));
    const uri = this.collectionIdentificationTokensUri.build(uriVariables);
    return this.get(uri, params);
  }

  addIdentificationToken<T>(uriVariables: URIVariables, data: Record<string, unknown>): Observable<ApiResponse<T>> {
    const uri = this.collectionIdentificationTokensUri.build(uriVariables);
    return this.post(uri, data);
  }

  deleteIdentificationToken<T>(uriVariables: URIVariables): Observable<ApiResponse<T>> {
    const uri = this.deleteIdentificationTokenUri.build(uriVariables);
    return this.delete(uri);
  }

  updateComplianceDetails(uriVariables: URIVariables, data: Record<string, unknown>) {
    return this.updateObject(uriVariables, data);
  }

  resetPassword(uriVariables: URIVariables): Observable<void> {
    const uri = this.resetPasswordUri.build(uriVariables);
    return this.put(uri, {});
  }

  recardingDeliver(uriVariables: URIVariables): Observable<void> {
    const uri = this.recardingDeliverUri.build(uriVariables);
    return this.post(uri, {});
  }

  deleteDeliveryAddress(id: string): Observable<void> {
    const uri = this.memberUriDeliveryAddress.build({ id });
    return this.delete(uri);
  }

  toggleStatus(id: string, status: 'active' | 'inactive'): Observable<void> {
    const uri = this.memberUriStatus.build({ id });
    if (status === 'active') {
      return this.put(uri, {});
    } else {
      return this.delete(uri);
    }
  }

  updateMultiplierId(id: string, multiplier_id: string | null): Observable<ApiResponse<Customer>> {
    const uri = this.memberUriMultiplier.build({ id });
    return this.put(uri, { multiplier_id });
  }

  assignPersonToCustomer(id: string, person: Person, data: AssignPersonPayload): Observable<void> {
    return this.post(
      this.memberUriAssignPersonUri.build({ id, personId: person.id, dest_customerId: data.customer.id }),
      data
    );
  }

  getSepaMandates(uriVariables: URIVariables, options: RequestOptions): Observable<ApiResponse<SepaMandate[]>> {
    const uri = this.sepaMandatesUri.build(uriVariables);
    let params = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });
    params = this.appendSortParams(params, options);
    params = this.appendFilterParams(params, options);
    params = this.appendPageSizeParams(params, options);
    return this.get(uri, params);
  }

  createSepaMandate(uriVariables: URIVariables, data: Record<string, unknown>): Observable<ApiResponse<SepaMandate>> {
    const uri = this.sepaMandatesUri.build(uriVariables);
    return this.post(uri, data);
  }

  updateSepaMandate(uriVariables: URIVariables, data: Record<string, unknown>): Observable<ApiResponse<SepaMandate>> {
    const uri = this.sepaMandateUri.build(uriVariables);
    return this.put(uri, data);
  }

  deleteSepaMandate(uriVariables: URIVariables): Observable<void> {
    const uri = this.sepaMandateUri.build(uriVariables);
    return this.delete(uri);
  }

  deleteSepaMandateFile(uriVariables: URIVariables): Observable<void> {
    const uri = this.sepaMandateUri.build(uriVariables);
    return this.delete(`${uri}.pdf`);
  }

  addSepaMandateFile(uriVariables: URIVariables, file: File): Observable<void> {
    const uri = this.sepaMandateUri.build(uriVariables);
    const formData = new FormData();
    formData.append('pdf', file);
    return this.putFormData(uri, formData);
  }

  editSepaMandate(uriVariables: URIVariables, data: Record<string, unknown>): Observable<ApiResponse<SepaMandate>> {
    const uri = this.sepaMandateUri.build(uriVariables);
    return this.put(uri, data);
  }
}
