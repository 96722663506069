<div class="container">
  <h5 mat-dialog-title>{{ 'customer-create-dialog.title' | translate }}</h5>
  <div
    mat-dialog-content
    class="tw-mt-2 tw-max-h-[90vh]"
  >
    <givve-stepper>
      <cdk-step
        [stepControl]="step1FormGroup"
        (interacted)="step1FormGroup.markAllAsTouched()"
        label="{{ 'customer-create-dialog.address_label' | translate }}"
      >
        <app-address-form
          [addressFormGroup]="addressFormGroup"
          [deliveryAddressFormGroup]="deliveryAddressFormGroup"
        />
        <div class="tw-mt-4 tw-flex tw-justify-end">
          <button
            color="primary"
            mat-raised-button
            matStepperNext
          >
            {{ 'common.next' | translate }}
          </button>
        </div>
      </cdk-step>
      <cdk-step
        [stepControl]="complianceFormGroup"
        (interacted)="complianceFormGroup.markAllAsTouched()"
        label="{{ 'common.compliance' | translate }}"
      >
        <form [formGroup]="complianceFormGroup">
          <app-client-legal-form
            [control]="$any(complianceFormGroup.get('legal_form'))"
            [companyControl]="$any(addressFormGroup.get('company'))"
          />
          <app-register-number
            [registerTypeControl]="$any(complianceFormGroup.get('register_type'))"
            [registerCourtIdControl]="$any(complianceFormGroup.get('register_court_id'))"
            [registerNumberControl]="$any(complianceFormGroup.get('register_number'))"
            [registerNotAvailableControl]="$any(complianceFormGroup.get('register_not_available'))"
          >
          </app-register-number>

          <app-liable-persons-form [formGroup]="liablePersonsFormGroup"></app-liable-persons-form>

          <div class="tw-mt-4 tw-flex tw-justify-end">
            <button
              class="tw-mr-1"
              mat-button
              matStepperPrevious
            >
              {{ 'common.back' | translate }}
            </button>
            <button
              color="primary"
              mat-raised-button
              matStepperNext
              data-cy="customer-create-dialog-next-button"
            >
              {{ 'common.next' | translate }}
            </button>
          </div>
        </form>
      </cdk-step>
      <cdk-step
        [stepControl]="vatFormGroup"
        (interacted)="vatFormGroup.markAllAsTouched()"
        label="{{ 'customer-create-dialog.vat_label' | translate }}"
      >
        <form [formGroup]="vatFormGroup">
          <mat-form-field>
            <mat-label> {{ 'common.vat-number' | translate }} </mat-label>
            <input
              formControlName="vat_number"
              matInput
              givveTrim
            />
          </mat-form-field>
          <app-client-vat-zone
            [control]="$any(vatFormGroup.get('vat_zone'))"
            [countryCodeControl]="$any(addressFormGroup.get('country_code'))"
          />
          <app-x-invoice-buyer-reference-field
            formControlName="x_invoice_buyer_reference"
          ></app-x-invoice-buyer-reference-field>
        </form>
        <div class="tw-mt-4 tw-flex tw-justify-end">
          <button
            class="tw-mr-1"
            mat-button
            matStepperPrevious
          >
            {{ 'common.back' | translate }}
          </button>
          <button
            color="primary"
            mat-raised-button
            matStepperNext
          >
            {{ 'common.next' | translate }}
          </button>
        </div>
      </cdk-step>
      <cdk-step
        [stepControl]="accountsFormGroup"
        (interacted)="accountsFormGroup.markAllAsTouched()"
        label="{{ 'customer-create-dialog.account_label' | translate }}"
      >
        <form [formGroup]="multiplierFormGroup">
          <app-client-autocomplete-field
            [clientType]="['multiplier']"
            [title]="'common.multiplier' | translate"
            formControlName="multiplier"
          />
        </form>
        <form [formGroup]="settingsFormGroup">
          <mat-form-field>
            <mat-label> {{ 'common.language-email' | translate }} </mat-label>
            <mat-select
              formControlName="language_code"
              required
            >
              <mat-option
                *ngFor="let code of languageCodes"
                [value]="code"
                >{{ 'common.language_code.' + code | translate }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="settingsFormGroup.controls.language_code.errors?.required">
              {{ 'common.fill_out_field' | translate }}
            </mat-error>
          </mat-form-field>
        </form>

        <app-client-contact-persons-form [formGroup]="contactPersonsFormGroup" />

        <div class="tw-mt-4 tw-flex tw-justify-end">
          <button
            class="tw-mr-1"
            mat-button
            matStepperPrevious
          >
            {{ 'common.back' | translate }}
          </button>
          <button
            color="primary"
            mat-raised-button
            matStepperNext
          >
            {{ 'common.next' | translate }}
          </button>
        </div>
      </cdk-step>
      <cdk-step
        [stepControl]="productionsDataFormGroup"
        (interacted)="productionsDataFormGroup.markAllAsTouched()"
        label="{{ 'common.production_data' | translate }}"
      >
        <form [formGroup]="productionsDataFormGroup">
          <app-gps-usage-group
            [control]="$any(productionsDataFormGroup.get('gps_usage_group'))"
            [countryControl]="$any(addressFormGroup.get('country_code'))"
          ></app-gps-usage-group>
          <app-default-product-ref
            [multiplierControl]="$any(multiplierFormGroup.get('multiplier'))"
            [control]="$any(productionsDataFormGroup.get('default_product_ref'))"
          ></app-default-product-ref>
          <app-client-carrier-type
            [multiplierControl]="$any(multiplierFormGroup.get('multiplier'))"
            [control]="$any(productionsDataFormGroup.get('default_carrier_type'))"
            [isRecarding]="false"
          />
          <app-client-carrier-type
            [multiplierControl]="$any(multiplierFormGroup.get('multiplier'))"
            [control]="$any(productionsDataFormGroup.get('default_renew_carrier_type'))"
            [isRecarding]="true"
          />
        </form>
        <div class="tw-mt-4 tw-flex tw-justify-end">
          <button
            class="tw-mr-1"
            mat-button
            matStepperPrevious
          >
            {{ 'common.back' | translate }}
          </button>
          <button
            color="primary"
            mat-raised-button
            matStepperNext
          >
            {{ 'common.next' | translate }}
          </button>
        </div>
      </cdk-step>
      <cdk-step
        [stepControl]="feesFormGroup"
        (interacted)="addressFormGroup.markAllAsTouched()"
        label="{{ 'customer-create-dialog.fees_label' | translate }}"
      >
        <div class="tw-flex tw-flex-col tw-gap-3">
          <app-fieldset [title]="'customer-create-dialog.load_fees' | translate">
            <app-load-fee-form
              [formGroup]="loadFeeFormGroup"
              [multiplierControl]="$any(multiplierFormGroup.get('multiplier'))"
            >
            </app-load-fee-form
          ></app-fieldset>

          <app-fieldset
            class="tw-pt-3"
            [title]="'customer-create-dialog.card_order_fees' | translate"
          >
            <app-card-order-form
              [formGroup]="cardOrderFormGroup"
              [multiplierControl]="$any(multiplierFormGroup.get('multiplier'))"
            ></app-card-order-form
          ></app-fieldset>
        </div>

        <div class="tw-mt-4 tw-flex tw-justify-end">
          <button
            class="tw-mr-1"
            mat-button
            matStepperPrevious
          >
            {{ 'common.back' | translate }}
          </button>
          <button
            data-cy="customer-create-dialog-done-button"
            color="primary"
            [disabled]="
              (vm$ | async).customerCreating ||
              !(
                step1FormGroup.valid &&
                complianceFormGroup.valid &&
                vatFormGroup.valid &&
                multiplierFormGroup.valid &&
                productionsDataFormGroup.valid &&
                loadFeeFormGroup.valid &&
                cardOrderFormGroup.valid &&
                contactPersonsFormGroup.valid &&
                liablePersonsFormGroup.valid &&
                settingsFormGroup.valid &&
                accountsFormGroup.valid &&
                feesFormGroup.valid
              )
            "
            mat-raised-button
            matStepperNext
            (click)="onDoneClick()"
            data-cy="customer-create-dialog-done-button"
          >
            <givve-button-spinner [loading]="(vm$ | async).customerCreating">
              {{ 'common.done' | translate }}
            </givve-button-spinner>
          </button>
        </div>
      </cdk-step>
    </givve-stepper>
  </div>
</div>
