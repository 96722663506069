import { AfterViewInit, Directive, ElementRef, Renderer2, inject, input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appBgError]',
  standalone: true,
})
export class BgErrorDirective implements AfterViewInit {
  appBgError = input.required<ValidationErrors | null>();

  errorColor = 'tw-bg-[#fee2e2]';
  hintColor = 'tw-bg-[#fef3c7]';

  private el = inject(ElementRef);
  private renderer = inject(Renderer2);

  ngAfterViewInit(): void {
    if (this.appBgError()) {
      const keys = Object.keys(this.appBgError()!);

      if (!keys.length) {
        return;
      }

      const errors = keys.filter((key) => !this.appBgError()![key].hint);
      const hints = keys.filter((key) => this.appBgError()![key].hint);

      if (errors.length) {
        this.renderer.addClass(this.el.nativeElement, this.errorColor);
      } else if (hints.length) {
        this.renderer.addClass(this.el.nativeElement, this.hintColor);
      }
    }
  }
}
