import { Injectable, inject } from '@angular/core';
import { CustomerHttpService, MultiplierHttpService } from '@app/api';
import { reduceMultiToStructuredResponse } from '@app/shared/utils/reducer.utils';
import type { Customer, Multiplier, RequestOptions } from '@givve/ui-kit/models';
import { NotificationService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, exhaustMap, forkJoin, map, tap } from 'rxjs';

export interface ClientAutoCompleteFieldState {
  statusType: 'INIT' | 'LOADING' | 'DATA' | 'ERROR';
  clients: Customer[] | Multiplier[];
}

export const DEFAULT_STATE: ClientAutoCompleteFieldState = {
  statusType: 'INIT',
  clients: [],
};

@Injectable()
export class ClientAutoCompleteFieldStore extends ComponentStore<ClientAutoCompleteFieldState> {
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);
  private customerHttpService = inject(CustomerHttpService);
  private multipliersHttpService = inject(MultiplierHttpService);
  readonly statusType$ = this.select((state) => state.statusType);
  readonly clients$ = this.select((state) => state.clients);

  readonly vm$ = this.select({
    statusType: this.statusType$,
    clients: this.clients$,
  });

  constructor() {
    super(DEFAULT_STATE);
  }

  readonly searchClients = this.effect(
    (
      params$: Observable<{
        clientType: ['customer', 'multiplier'] | ['customer'] | ['multiplier'];
        requestOptions: RequestOptions;
      }>
    ) =>
      params$.pipe(
        tap(() => {
          this.patchState(() => ({ statusType: 'LOADING' }));
        }),
        exhaustMap((params) => {
          if (params.clientType.length === 1) {
            if (params.clientType[0] === 'customer') {
              return this.customerHttpService.searchSimple(params.requestOptions);
            } else {
              return this.multipliersHttpService.searchSimple(params.requestOptions);
            }
          } else {
            return forkJoin([
              this.customerHttpService.searchSimple(params.requestOptions),
              this.multipliersHttpService.searchSimple(params.requestOptions),
            ]).pipe(
              map(([customers, multipliers]) => {
                return reduceMultiToStructuredResponse<any>([customers, multipliers]);
              })
            );
          }
        }),
        tapResponse({
          next: (response) => {
            this.patchState(() => ({
              clients: (response as any).data,
              statusType: 'DATA',
            }));
          },
          error: (error: any) => {
            this.patchState(() => ({
              clients: [],
              statusType: 'ERROR',
            }));

            this.notification.open({
              message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
            });
          },
        })
      )
  );

  clearComponentState() {
    this.setState({ ...DEFAULT_STATE });
  }
}
