import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  ClientRepresentativesCreatePayload,
  ClientRepresentativesUpdatePayload,
  CustomerHttpService,
  MultiplierHttpService,
  Representative,
} from '@app/api';
import { environment } from '@app/env';
import { BankAccountUpdatePayload, ClientDetailsUpdatePayload } from '@app/features/clients/client.models';
import type { BankAccount, Customer, Multiplier, RequestOptions } from '@givve/ui-kit/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClientService {
  private customerHttpService = inject(CustomerHttpService);
  private multiplierHttpService = inject(MultiplierHttpService);

  private httpService!: CustomerHttpService | MultiplierHttpService;
  private httpClient = inject(HttpClient);

  setHttpService(type: 'customer' | 'multiplier') {
    this.httpService =
      type === 'customer'
        ? this.customerHttpService
        : type === 'multiplier'
          ? this.multiplierHttpService
          : this.customerHttpService;

    return this;
  }

  updateClient(id: string, data: ClientDetailsUpdatePayload): Observable<Customer | Multiplier> {
    return this.httpService.updateObject({ id }, data);
  }

  deleteClient(id: string): Observable<void> {
    return this.httpService.deleteObject({ id });
  }

  createClient(data): Observable<Customer | Multiplier> {
    return this.httpService.createObject({}, data);
  }

  createRepresentative(clientId: string, data: ClientRepresentativesCreatePayload): Observable<Representative> {
    const payload = {
      ...data,
      is_beneficial_owner: false,
      is_liable_person: true,
    };

    return this.httpService
      .createRepresentative<Representative>({ clientId }, payload)
      .pipe(map((response) => response.data));
  }

  createBankAccount(
    clientId: string,
    data: Pick<BankAccount, 'owner_name' | 'bic' | 'iban' | 'for_payouts'>
  ): Observable<BankAccount> {
    return this.httpService
      .createMemberBankAccounts<BankAccount>({ clientId }, { ...data, type: 'client_bank_account' })
      .pipe(map((response) => response.data));
  }

  updateBankAccount(clientId: string, bankAccountId: string, data: BankAccountUpdatePayload): Observable<BankAccount> {
    return this.httpService
      .updateMemberBankAccounts<BankAccount>({ clientId, bankAccountId }, { ...data })
      .pipe(map((response) => response.data));
  }

  updateRepresentativeEntity({
    clientId,
    representative,
    payload,
  }: {
    clientId: string;
    representative: Representative;
    payload: ClientRepresentativesUpdatePayload;
  }): Observable<Representative> {
    return this.httpService
      .updateRepresentative<Representative>({ clientId, representativeId: representative.id }, { ...payload })
      .pipe(map((response) => response.data));
  }

  getBankAccounts(clientId: string): Observable<BankAccount[]> {
    return this.httpService.getMemberBankAccounts<BankAccount[]>({ clientId }).pipe(map((response) => response.data));
  }

  getRepresentatives(clientId: string): Observable<Representative[]> {
    return this.httpService.getMemberRepresentatives<Representative>({ clientId }).pipe(map((data) => data));
  }

  getRepresentativeWithComplianceApiKey(clientId: string, representativeId: string): Observable<Representative> {
    return this.httpService
      .getMemberRepresentativesMember<Representative>({ clientId, representativeId })
      .pipe(map((response) => response.data));
  }

  getDetail<T extends Multiplier | Customer>(id: string) {
    return this.httpService.getObject({ id }) as Observable<T>;
  }

  searchRepresentativesByPersonId(personId: string): Observable<Representative[]> {
    return this.httpService
      .searchRepresentatives({ filter: { compliance_api_key: personId } })
      .pipe(map((response) => response.data));
  }

  recardingDeliver(id: string, quarter: string, year: string): Observable<void> {
    return this.httpService.recardingDeliver({ id, quarter, year });
  }

  getSepaMandates(id: string, options: RequestOptions) {
    return this.httpService.getSepaMandates({ clientId: id }, options);
  }

  createSepaMandate(id: string, data: any) {
    return this.httpService.createSepaMandate({ clientId: id }, data);
  }

  deleteSepaMandate(id: string, mandateId: string) {
    return this.httpService.deleteSepaMandate({ clientId: id, mandateId });
  }

  deleteSepaMandateFile(id: string, mandateId: string) {
    return this.httpService.deleteSepaMandateFile({ clientId: id, mandateId });
  }

  addSepaMandateFile(id: string, mandateId: string, file: File) {
    return this.httpService.addSepaMandateFile({ clientId: id, mandateId }, file);
  }

  editSepaMandate(id: string, mandateId: string, data: any) {
    return this.httpService.editSepaMandate({ clientId: id, mandateId }, data);
  }

  getJwtToken(id: string): Observable<string> {
    const url = `${environment.adminApiUrlV1}/sessions/b2b_suite/v3/${id}`;
    return this.httpClient.get(url, { responseType: 'text' });
  }
}
