import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { User } from '@givve/ui-kit/models';
import { FrameService } from '../../../services/frame.service';

@Component({
  selector: 'givve-nav-profile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nav-profile.component.html',
  styleUrls: ['./nav-profile.component.scss'],
})
export class NavProfileComponent {
  @Input({ required: true }) user: User | null = null;
  frameService = inject(FrameService);

  get id() {
    return this.user?.id;
  }

  get name() {
    return this.user?.email;
  }

  get initial() {
    return this.name?.substring(0, 1) ?? '';
  }

  onProfileClick() {
    this.frameService.profileClicked(this.user!);
  }
}
