import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import { ScheduledLoadOrder } from '@givve/ui-kit/models';
import { map } from 'rxjs';
import { URIVariables } from '../interfaces/uri-variables.interface';
import { BaseHttpService } from './base-http.service';

@Injectable({ providedIn: 'root' })
export class ScheduledLoadOrderHttpService extends MultiObjectHTTPService<ScheduledLoadOrder> {
  memberUri = new URITemplate('{+api_v1_base}/scheduled_load_orders/{id}');

  collectionUri = new URITemplate('{+api_v1_base}/scheduled_load_orders');
  searchUri = new URITemplate(`{+api_base}/admin/search/scheduled_load_orders`);
  entriesUri = new URITemplate('{+api_v1_base}/scheduled_load_orders/{id}/entries');

  entryUriPause = new URITemplate('{+api_v1_base}/scheduled_load_orders/{id}/entries/{entryId}/pause');
  entryUriActivate = new URITemplate('{+api_v1_base}/scheduled_load_orders/{id}/entries/{entryId}/activate');
  entryUri = new URITemplate('{+api_v1_base}/scheduled_load_orders/{id}/entries/{entryId}');

  pauseObject(uriVariables: URIVariables) {
    const pauseUri = new URITemplate('{+api_v1_base}/scheduled_load_orders/{id}/pause');
    const uri = pauseUri.build(uriVariables);
    return this.put(uri);
  }

  activateObject(uriVariables: URIVariables) {
    const pauseUri = new URITemplate('{+api_v1_base}/scheduled_load_orders/{id}/activate');
    const uri = pauseUri.build(uriVariables);
    return this.put(uri);
  }

  getEntries(params: Params) {
    const uri = this.entriesUri.build({ id: params.id });
    let httpParams = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });
    if (params.sort) {
      const options = {
        sort: (params.direction === 'asc' ? '' : '-') + params.sort,
      };
      httpParams = this.appendSortParams(httpParams, options);
    }
    httpParams = this.appendPageSizeParams(httpParams, params);

    return this.get(uri, httpParams);
  }

  addEntry(uriVariables: URIVariables, payload: any) {
    const uri = this.entriesUri.build(uriVariables);
    return this.post(uri, payload).pipe(map((json: any) => json.data));
  }

  editEntry(uriVariables: URIVariables, payload: any) {
    const uri = this.entryUri.build(uriVariables);
    return this.put(uri, payload).pipe(map((json: any) => json.data));
  }

  deleteEntry(uriVariables: URIVariables) {
    const uri = this.entryUri.build(uriVariables);
    return this.delete(uri);
  }

  pauseEntry(uriVariables: URIVariables) {
    const uri = this.entryUriPause.build(uriVariables);
    return this.put(uri).pipe(map((json: any) => json.data));
  }

  activateEntry(uriVariables: URIVariables) {
    const uri = this.entryUriActivate.build(uriVariables);
    return this.put(uri).pipe(map((json: any) => json.data));
  }
}
