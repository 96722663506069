import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import { UnloadOrder } from '@givve/ui-kit/models';
import { BaseHttpService } from '@givve/ui-kit/services';

@Injectable({ providedIn: 'root' })
export class UnloadOrdersHttpService extends MultiObjectHTTPService<UnloadOrder> {
  memberUri = new URITemplate('{+api_v1_base}/unload_orders/{id}');
  collectionUri = new URITemplate('{+api_v1_base}/unload_orders');
  searchUri = new URITemplate(`{+api_base}/admin/search/unload_orders`);
  entriesUri = new URITemplate('{+api_v1_base}/unload_orders/{id}/entries');

  getEntries(params: Params) {
    const uri = this.entriesUri.build({ id: params.id });
    let httpParams = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });
    if (params.sort) {
      const options = {
        sort: (params.direction === 'asc' ? '' : '-') + params.sort,
      };
      httpParams = this.appendSortParams(httpParams, options);
    }
    return this.get(uri, httpParams);
  }
}
