<form
  [formGroup]="form"
  (ngSubmit)="submit()"
>
  <h2 mat-dialog-title>{{ 'card-designs-edit-hint-comment-dialog.title.' + data.translatorKey | translate }}</h2>
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>{{ 'common.' + data.translatorKey | translate }}</mat-label>
      <input
        data-cy="edit-hint-comment-dialog-input"
        matInput
        formControlName="hint"
      />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      [mat-dialog-close]="null"
    >
      {{ 'common.cancel' | translate }}
    </button>
    <button
      data-cy="edit-hint-comment-dialog-submit-button"
      mat-raised-button
      color="primary"
      [disabled]="form.pristine"
      type="submit"
    >
      {{ 'common.ok' | translate }}
    </button>
  </mat-dialog-actions>
</form>
