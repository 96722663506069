import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { InvoiceHttpService } from '@app/api/services/invoice-http.service';
import type { ApiResponse, CardOrder, Invoice, LoadOrder, UnloadOrder } from '@givve/ui-kit/models';
import { ApiResponseLinks, StatusType } from '@givve/ui-kit/models';
import { NotificationService, RouterHistoryService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, exhaustMap, filter, takeUntil, tap } from 'rxjs';

export interface OrderInvoicesState {
  details: LoadOrder | CardOrder | UnloadOrder;
  invoices: {
    data: Invoice[];
    links: Partial<ApiResponseLinks>;
    statusType: StatusType;
  };
}

export const DEFAULT_STATE: OrderInvoicesState = {
  details: {} as LoadOrder | CardOrder | UnloadOrder,
  invoices: {
    data: [],
    statusType: 'INIT',
    links: {},
  },
};

@Injectable()
export class OrderInvoicesStore extends ComponentStore<OrderInvoicesState> {
  private http = inject(HttpClient);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);
  private invoiceHttpService = inject(InvoiceHttpService);
  private router = inject(Router);
  private routerHistoryService = inject(RouterHistoryService);
  readonly vm$ = this.select((state) => state);

  constructor() {
    super(DEFAULT_STATE);
  }

  /**
   * Load order invoices
   **/
  readonly loadOrderInvoices = this.effect((params$: Observable<{ params: Params; filterParam: string }>) => {
    return params$.pipe(
      tap(() => {
        this.patchState((state) => ({
          invoices: {
            ...state.invoices,
            statusType: 'LOADING',
          },
        }));
      }),
      exhaustMap(({ params, filterParam }) => {
        const options = {
          filter: {
            [filterParam]: {
              $in: params.id,
            },
          },
          ...(params.sort ? { sort: (params.direction === 'asc' ? '' : '-') + params.sort } : null),
        };

        return this.invoiceHttpService.getObjects({}, options).pipe(
          takeUntil(
            this.router.events.pipe(
              filter(
                (event) =>
                  event instanceof NavigationEnd &&
                  this.routerHistoryService.previousUrl?.split(';')[0] !== event.url.split(';')[0]
              )
            )
          ),
          tapResponse(
            (invoices) => {
              this.patchState({
                invoices: {
                  links: invoices.links,
                  data: invoices.data,
                  statusType: 'DATA',
                },
              });
            },
            (error) => {
              this.patchState((state) => ({
                invoices: {
                  ...state.invoices,
                  statusType: 'ERROR',
                },
              }));

              this.notification.open({
                message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
              });
            }
          )
        );
      })
    );
  });

  /**
   * Load more order invoices
   *
   */
  readonly loadMoreOrderInvoices = this.effect((link$: Observable<string>) => {
    return link$.pipe(
      tap(() => {
        this.patchState((state) => ({
          invoices: {
            ...state.invoices,
            statusType: 'LOAD_MORE',
          },
        }));
      }),
      exhaustMap((link) => {
        return this.http.get<ApiResponse<Invoice[]>>(link).pipe(
          tapResponse(
            (invoices) => {
              this.patchState((state) => ({
                invoices: {
                  ...state.invoices,
                  links: invoices.links,
                  data: [...state.invoices.data, ...invoices.data],
                  statusType: 'DATA',
                },
              }));
            },
            (error) => {
              this.patchState((state) => ({
                invoices: {
                  ...state.invoices,
                  statusType: 'ERROR',
                },
              }));

              this.notification.open({
                message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
              });
            }
          )
        );
      })
    );
  });

  setOrderDetails(loadOrder: LoadOrder | CardOrder | UnloadOrder) {
    this.setState((state) => ({
      ...state,
      details: loadOrder,
    }));
  }
}
