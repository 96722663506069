@if (this.isEditing()) {
  <givve-comment-form
    [defaultValue]="this.comment().text"
    (formSubmit)="commentFormSubmitted($event, this.comment(), 'edit')"
    (formCancel)="this.isEditing.set(false)"
    [isLoading]="computedLoading()"
  />
} @else {
  <article data-cy="givve-comment">
    <header class="comment-header">
      <div
        class="comment-info"
        data-testid="comment-info"
        data-cy="comment-info"
      >
        @if (this.comment().author) {
          <span class="comment-info__author">{{ this.comment().author?.type }}</span>
        }
        @if (this.comment().author && this.comment().created_at) {
          <span class="comment-info__separator"> - </span>
        }
        @if (this.comment().created_at) {
          <span class="comment-info__created-at">{{ this.comment().created_at | date: 'medium' }}</span>
        }
      </div>
    </header>

    <main
      class="comment-text"
      data-testid="comment-text"
      data-cy="comment-text"
    >
      <div [innerText]="this.comment().text"></div>
      @if (isEditable()) {
        <div class="comment-menu">
          <button
            mat-icon-button
            data-testid="comment-menu-button"
            data-cy="comment-menu-button"
            [matMenuTriggerFor]="menu"
            aria-label="Comment actions"
            [disabled]="computedLoading()"
          >
            <fa-icon
              [icon]="icons.faEllipsisVertical"
              [size]="'sm'"
            />
          </button>
          <mat-menu #menu="matMenu">
            <button
              class="comment-menu__button"
              data-testid="comment-reply-button"
              data-cy="comment-reply-button"
              mat-menu-item
              (click)="this.isReplying.set(true)"
            >
              <fa-icon [icon]="icons.faReply" />
              <span>{{ computedOptions().replyText }}</span>
            </button>

            <button
              class="comment-menu__button"
              data-testid="comment-edit-button"
              data-cy="comment-edit-button"
              mat-menu-item
              (click)="this.isEditing.set(true)"
            >
              <fa-icon [icon]="icons.faPenToSquare" />
              <span>{{ computedOptions().editText }}</span>
            </button>
            <button
              class="comment-menu__button"
              data-testid="comment-delete-button"
              data-cy="comment-delete-button"
              mat-menu-item
              (click)="this.commentDeleted(this.comment())"
            >
              <fa-icon [icon]="icons.faTrash" />
              <span>{{ computedOptions().deleteText }}</span>
            </button>
          </mat-menu>
        </div>
      } @else {
        <button
          mat-icon-button
          [disabled]="computedLoading()"
          [matTooltip]="computedOptions().replyText"
          (click)="this.isReplying.set(true)"
        >
          <fa-icon [icon]="icons.faReply" />
        </button>
      }
    </main>

    @if (this.isReplying()) {
      <givve-comment-form
        (formSubmit)="commentFormSubmitted($event, this.comment(), 'reply')"
        (formCancel)="this.isReplying.set(false)"
        [isLoading]="computedLoading()"
      />
    }
  </article>
}
@if (childrenComments()) {
  <div
    class="comment-children"
    data-testid="comment-children"
  >
    @for (comment of childrenComments(); track comment.id) {
      <givve-comment
        [comment]="comment"
        [comments]="comments()"
        [isEditable]="isEditable()"
        [isLoading]="isLoading()"
        class="comment"
      />
    }
  </div>
}
