<div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-6">
  <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-3">
    <fa-icon
      icon="exclamation-triangle"
      size="xl"
      class="tw-text-red-600"
    />
    <span class="tw-text-md">{{ 'error-placeholder.component.something_went_wrong' | translate }}</span>
  </div>
  <button
    mat-raised-button
    color="primary"
    (click)="tryAgain()"
  >
    {{ 'error-placeholder.component.try_again' | translate }}
  </button>
</div>
