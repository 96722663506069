import { environment } from '@app/env';
import { BaseURITemplate } from '@givve/ui-kit/services';

export class URITemplate extends BaseURITemplate {
  defaultUriVariables = {
    api_base: environment.apiUrl,
    api_v1_base: environment.adminApiUrlV1,
    compliance_api_base: environment.complianceApiUrl,
    audit_api_base: environment.auditApiUrl,
  };
}
