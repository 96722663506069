export const enum MaskExpression {
    SEPARATOR = 'separator',
    PERCENT = 'percent',
    IP = 'IP',
    CPF_CNPJ = 'CPF_CNPJ',
    MONTH = 'M',
    MONTHS = 'M0',
    MINUTE = 'm',
    HOUR = 'h',
    HOURS = 'H',
    MINUTES = 'm0',
    HOURS_HOUR = 'Hh',
    SECONDS = 's0',
    HOURS_MINUTES_SECONDS = 'Hh:m0:s0',
    EMAIL_MASK = 'A*@A*.A*',
    HOURS_MINUTES = 'Hh:m0',
    MINUTES_SECONDS = 'm0:s0',
    DAYS_MONTHS_YEARS = 'd0/M0/0000',
    DAYS_MONTHS = 'd0/M0',
    DAYS = 'd0',
    DAY = 'd',
    SECOND = 's',
    LETTER_S = 'S',
    DOT = '.',
    COMMA = ',',
    CURLY_BRACKETS_LEFT = '{',
    CURLY_BRACKETS_RIGHT = '}',
    MINUS = '-',
    OR = '||',
    HASH = '#',
    EMPTY_STRING = '',
    SYMBOL_STAR = '*',
    SYMBOL_QUESTION = '?',
    SLASH = '/',
    WHITE_SPACE = ' ',
    NUMBER_ZERO = '0',
    NUMBER_NINE = '9',
    BACKSPACE = 'Backspace',
    DELETE = 'Delete',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_UP = 'ArrowUp',
    DOUBLE_ZERO = '00',
}
