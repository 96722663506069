import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

const gpsUsageGroupOptions = ['BNY-CI-DEF', 'BNY-CI-003'];

@Component({
  selector: 'app-gps-usage-group',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    MatButtonModule,
  ],
  templateUrl: './gps-usage-group.component.html',
})
export class GpsUsageGroupComponent implements OnInit {
  control = input.required<FormControl>();
  countryControl = input.required<FormControl>();

  gpsUsageGroupOptions = gpsUsageGroupOptions;
  suggestion = '';

  ngOnInit() {
    this.countryControl().valueChanges.subscribe((value) => {
      this.suggestion = value === '276' ? 'BNY-CI-DEF' : '';
    });
  }

  onHintClick(e: Event) {
    e.stopPropagation();
    this.control().patchValue(this.suggestion);
    this.control().updateValueAndValidity();
  }
}
