import { Injectable } from '@angular/core';
import { User } from '@givve/ui-kit/models';
import { ComponentStore } from '@ngrx/component-store';

export interface FrameState {
  // only used for mobile purposes
  toggleTrigger: boolean;
  // only used for normal view
  menuNarrowed: boolean;
  isMobile: boolean;
  user: User | null;
  version: string;
  logoutText: string;
}

@Injectable({
  providedIn: 'root',
})
export class FrameStore extends ComponentStore<FrameState> {
  readonly vm$ = this.select((state) => state);
  readonly toggleTrigger$ = this.select((state) => state.toggleTrigger);
  readonly menuNarrowed$ = this.select((state) => state.menuNarrowed);
  readonly isMobile$ = this.select((state) => state.isMobile);
  readonly user$ = this.select((state) => state.user);
  readonly version$ = this.select((state) => state.version);
  readonly nav$ = this.select((state) => ({
    version: state.version,
    isMobile: state.isMobile,
    user: state.user,
    logoutText: state.logoutText,
  }));
  readonly logoutText$ = this.select((state) => state.logoutText);

  constructor() {
    super({ toggleTrigger: true, menuNarrowed: false, isMobile: false, user: null, version: '', logoutText: '' });
  }

  toggleMenu = this.updater((state) => ({ ...state, toggleTrigger: !state.toggleTrigger }));
  toggleNarrowed = this.updater((state) => ({ ...state, menuNarrowed: !state.menuNarrowed }));
  setMobile = this.updater((state, isMobile: boolean) => ({ ...state, isMobile }));
  setUser = this.updater((state, user: User) => ({ ...state, user }));
  setVersion = this.updater((state, version: string) => ({ ...state, version }));
  setLogoutText = this.updater((state, logoutText: string) => ({ ...state, logoutText }));
}
