import { Pipe, PipeTransform, inject } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'tooltipHint',
  standalone: true,
})
export class TooltipHintPipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(errors: ValidationErrors | null): string {
    let tooltip = '';

    if (errors) {
      Object.keys(errors).forEach((key) => {
        if (errors[key].hint) {
          tooltip = tooltip + this.translateService.instant('csv-upload-preview-table.errors.' + key) + '\n';
        }
      });
    }

    return tooltip;
  }
}
