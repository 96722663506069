import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { FrameService } from '../../services/frame.service';
import { FrameStore } from '../../store/frame.store';
import { NavProfileComponent } from './nav-profile/nav-profile.component';

@Component({
  selector: 'givve-frame-nav',
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    SvgIconComponent,
    RouterModule,
    LetDirective,
    TranslateModule,
    NavProfileComponent
],
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  frameStore = inject(FrameStore);
  frameService = inject(FrameService);
  nav$ = this.frameStore.nav$;

  onLogout() {
    this.frameService.triggerLogout();
  }
}
