export const VALID_ZONES = ['domestic', 'eu', 'abroad'];

export const ZONE_MAPPING: Record<string, string> = {
  '276': 'domestic', // Germany
  '040': 'eu', // Austria
  '250': 'eu', // France
  '442': 'eu', // Luxembourg
  '528': 'eu', // Netherlands
  '643': 'abroad', // Russian Federation
  '724': 'eu', // Spain
  '756': 'abroad', // Switzerland
  '826': 'eu', // United Kingdom
  '840': 'abroad', // USA
};

export const REGISTER_TYPES = ['HRA', 'HRB', 'GnR', 'PR', 'VR'];

export const REGISTER_COURTS = [
  {
    code: 'R3101',
    value: 'Aachen',
  },
  {
    code: 'Y1201',
    value: 'Altenburg',
  },
  {
    code: 'D3101',
    value: 'Amberg',
  },
  {
    code: 'D3201',
    value: 'Ansbach',
  },
  {
    code: 'Y1101',
    value: 'Apolda',
  },
  {
    code: 'R1901',
    value: 'Arnsberg',
  },
  {
    code: 'Y1102',
    value: 'Arnstadt',
  },
  {
    code: 'Y1303',
    value: 'Arnstadt Zweigstelle Ilmenau',
  },
  {
    code: 'D4102',
    value: 'Aschaffenburg',
  },
  {
    code: 'D2102',
    value: 'Augsburg',
  },
  {
    code: 'P3101',
    value: 'Aurich',
  },
  {
    code: 'M1305',
    value: 'Bad Hersfeld',
  },
  {
    code: 'M1202',
    value: 'Bad Homburg v.d.H.',
  },
  {
    code: 'T2101',
    value: 'Bad Kreuznach',
  },
  {
    code: 'R2108',
    value: 'Bad Oeynhausen',
  },
  {
    code: 'Y1301',
    value: 'Bad Salzungen',
  },
  {
    code: 'D4201',
    value: 'Bamberg',
  },
  {
    code: 'D4301',
    value: 'Bayreuth',
  },
  {
    code: 'F1103',
    value: 'Berlin',
  },
  {
    code: 'R2101',
    value: 'Bielefeld',
  },
  {
    code: 'R2201',
    value: 'Bochum',
  },
  {
    code: 'R3201',
    value: 'Bonn',
  },
  {
    code: 'P1103',
    value: 'Braunschweig',
  },
  {
    code: 'H1101',
    value: 'Bremen',
  },
  {
    code: 'U1206',
    value: 'Chemnitz',
  },
  {
    code: 'D4401',
    value: 'Coburg',
  },
  {
    code: 'R2707',
    value: 'Coesfeld',
  },
  {
    code: 'G1103',
    value: 'Cottbus',
  },
  {
    code: 'M1103',
    value: 'Darmstadt',
  },
  {
    code: 'D2201',
    value: 'Deggendorf',
  },
  {
    code: 'R2402',
    value: 'Dortmund',
  },
  {
    code: 'U1104',
    value: 'Dresden',
  },
  {
    code: 'R1202',
    value: 'Duisburg',
  },
  {
    code: 'R3103',
    value: 'Düren',
  },
  {
    code: 'R1101',
    value: 'Düsseldorf',
  },
  {
    code: 'Y1105',
    value: 'Eisenach',
  },
  {
    code: 'Y1106',
    value: 'Erfurt',
  },
  {
    code: 'M1602',
    value: 'Eschwege',
  },
  {
    code: 'R2503',
    value: 'Essen',
  },
  {
    code: 'X1112',
    value: 'Flensburg',
  },
  {
    code: 'M1201',
    value: 'Frankfurt am Main',
  },
  {
    code: 'G1207',
    value: 'Frankfurt/Oder',
  },
  {
    code: 'B1204',
    value: 'Freiburg',
  },
  {
    code: 'M1405',
    value: 'Friedberg',
  },
  {
    code: 'M1603',
    value: 'Fritzlar',
  },
  {
    code: 'M1301',
    value: 'Fulda',
  },
  {
    code: 'D3304',
    value: 'Fürth',
  },
  {
    code: 'R2507',
    value: 'Gelsenkirchen',
  },
  {
    code: 'Y1203',
    value: 'Gera',
  },
  {
    code: 'M1406',
    value: 'Gießen',
  },
  {
    code: 'Y1108',
    value: 'Gotha',
  },
  {
    code: 'P2204',
    value: 'Göttingen',
  },
  {
    code: 'Y1205',
    value: 'Greiz',
  },
  {
    code: 'R2103',
    value: 'Gütersloh',
  },
  {
    code: 'R2602',
    value: 'Hagen',
  },
  {
    code: 'K1101',
    value: 'Hamburg',
  },
  {
    code: 'R2404',
    value: 'Hamm',
  },
  {
    code: 'M1502',
    value: 'Hanau',
  },
  {
    code: 'P2305',
    value: 'Hannover',
  },
  {
    code: 'Y1109',
    value: 'Heilbad Heiligenstadt',
  },
  {
    code: 'Y1302',
    value: 'Hildburghausen',
  },
  {
    code: 'P2408',
    value: 'Hildesheim',
  },
  {
    code: 'D4501',
    value: 'Hof',
  },
  {
    code: 'V1102',
    value: 'Homburg',
  },
  {
    code: 'D5701',
    value: 'Ingolstadt',
  },
  {
    code: 'R2604',
    value: 'Iserlohn',
  },
  {
    code: 'Y1206',
    value: 'Jena',
  },
  {
    code: 'T3201',
    value: 'Kaiserslautern',
  },
  {
    code: 'M1607',
    value: 'Kassel',
  },
  {
    code: 'D2304',
    value: 'Kempten',
  },
  {
    code: 'X1517',
    value: 'Kiel',
  },
  {
    code: 'R1304',
    value: 'Kleve',
  },
  {
    code: 'T2210',
    value: 'Koblenz',
  },
  {
    code: 'R3306',
    value: 'Köln',
  },
  {
    code: 'M1203',
    value: 'Königstein',
  },
  {
    code: 'M1608',
    value: 'Korbach',
  },
  {
    code: 'R1402',
    value: 'Krefeld',
  },
  {
    code: 'T3304',
    value: 'Landau',
  },
  {
    code: 'D2404',
    value: 'Landshut',
  },
  {
    code: 'R1105',
    value: 'Langenfeld',
  },
  {
    code: 'V1103',
    value: 'Lebach',
  },
  {
    code: 'U1308',
    value: 'Leipzig',
  },
  {
    code: 'R2307',
    value: 'Lemgo',
  },
  {
    code: 'M1706',
    value: 'Limburg',
  },
  {
    code: 'X1721',
    value: 'Lübeck',
  },
  {
    code: 'T3104',
    value: 'Ludwigshafen a.Rhein',
  },
  {
    code: 'P2507',
    value: 'Lüneburg',
  },
  {
    code: 'T2304',
    value: 'Mainz',
  },
  {
    code: 'B1601',
    value: 'Mannheim',
  },
  {
    code: 'M1809',
    value: 'Marburg',
  },
  {
    code: 'Y1304',
    value: 'Meiningen',
  },
  {
    code: 'D2505',
    value: 'Memmingen',
  },
  {
    code: 'V1104',
    value: 'Merzig',
  },
  {
    code: 'R1504',
    value: 'Mönchengladbach',
  },
  {
    code: 'T2214',
    value: 'Montabaur',
  },
  {
    code: 'Y1110',
    value: 'Mühlhausen',
  },
  {
    code: 'D2601',
    value: 'München',
  },
  {
    code: 'R2713',
    value: 'Münster',
  },
  {
    code: 'N1105',
    value: 'Neubrandenburg',
  },
  {
    code: 'V1105',
    value: 'Neunkirchen',
  },
  {
    code: 'G1309',
    value: 'Neuruppin',
  },
  {
    code: 'R1102',
    value: 'Neuss',
  },
  {
    code: 'Y1111',
    value: 'Nordhausen',
  },
  {
    code: 'D3310',
    value: 'Nürnberg',
  },
  {
    code: 'M1114',
    value: 'Offenbach am Main',
  },
  {
    code: 'P3210',
    value: 'Oldenburg',
  },
  {
    code: 'P3313',
    value: 'Osnabrück',
  },
  {
    code: 'V1107',
    value: 'Ottweiler',
  },
  {
    code: 'R2809',
    value: 'Paderborn',
  },
  {
    code: 'D2803',
    value: 'Passau',
  },
  {
    code: 'X1321',
    value: 'Pinneberg',
  },
  {
    code: 'Y1209',
    value: 'Pößneck',
  },
  {
    code: 'Y1208',
    value: 'Pößneck Zweigstelle Bad Lobenstein',
  },
  {
    code: 'G1312',
    value: 'Potsdam',
  },
  {
    code: 'R2204',
    value: 'Recklinghausen',
  },
  {
    code: 'D3410',
    value: 'Regensburg',
  },
  {
    code: 'N1206',
    value: 'Rostock',
  },
  {
    code: 'Y1210',
    value: 'Rudolstadt',
  },
  {
    code: 'V1109',
    value: 'Saarbrücken',
  },
  {
    code: 'V1110',
    value: 'Saarlouis',
  },
  {
    code: 'D4608',
    value: 'Schweinfurt',
  },
  {
    code: 'N1308',
    value: 'Schwerin',
  },
  {
    code: 'R3208',
    value: 'Siegburg',
  },
  {
    code: 'R2909',
    value: 'Siegen',
  },
  {
    code: 'Y1112',
    value: 'Sömmerda',
  },
  {
    code: 'Y1113',
    value: 'Sondershausen',
  },
  {
    code: 'Y1307',
    value: 'Sonneberg',
  },
  {
    code: 'P2106',
    value: 'Stadthagen',
  },
  {
    code: 'Y1214',
    value: 'Stadtroda',
  },
  {
    code: 'R2706',
    value: 'Steinfurt',
  },
  {
    code: 'W1215',
    value: 'Stendal',
  },
  {
    code: 'V1111',
    value: 'St. Ingbert',
  },
  {
    code: 'N1209',
    value: 'Stralsund',
  },
  {
    code: 'D3413',
    value: 'Straubing',
  },
  {
    code: 'B2609',
    value: 'Stuttgart',
  },
  {
    code: 'V1112',
    value: 'St. Wendel',
  },
  {
    code: 'Y1308',
    value: 'Suhl',
  },
  {
    code: 'P2613',
    value: 'Tostedt',
  },
  {
    code: 'D2910',
    value: 'Traunstein',
  },
  {
    code: 'B2805',
    value: 'Ulm',
  },
  {
    code: 'V1115',
    value: 'Völklingen',
  },
  {
    code: 'P2716',
    value: 'Walsrode',
  },
  {
    code: 'D3508',
    value: 'Weiden i. d. OPf.',
  },
  {
    code: 'Y1114',
    value: 'Weimar',
  },
  {
    code: 'M1710',
    value: 'Wetzlar',
  },
  {
    code: 'M1906',
    value: 'Wiesbaden',
  },
  {
    code: 'T2408',
    value: 'Wittlich',
  },
  {
    code: 'R1608',
    value: 'Wuppertal',
  },
  {
    code: 'D4708',
    value: 'Würzburg',
  },
  {
    code: 'T3403',
    value: 'Zweibrücken',
  },
];

export const REGISTER_NUMBER_COURTS_MAP: Record<string, string> = {
  B: 'F1103',
  CB: 'G1103',
  FF: 'G1207',
  NP: 'G1309',
  P: 'G1312',
  HB: 'H1101',
  BHV: 'H1101',
  AH: 'X1721',
  BB: 'X1517',
  OD: 'X1721',
  BS: 'X1721',
  SE: 'X1517',
  EC: 'X1517',
  EL: 'X1321',
  EU: 'X1721',
  FL: 'X1112',
  GE: 'X1721',
  HU: 'X1112',
  IZ: 'X1321',
  KA: 'X1112',
  KI: 'X1517',
  HL: 'X1721',
  ME: 'X1321',
  MÖ: 'X1721',
  NM: 'X1517',
  NI: 'X1112',
  NO: 'X1517',
  OL: 'X1721',
  PI: 'X1321',
  PL: 'X1517',
  RZ: 'X1721',
  RE: 'X1721',
  RD: 'X1517',
  SL: 'X1112',
  SB: 'X1721',
};

export const PRODUCT_REFS = [
  '002-0008',
  '002-0010',
  '002-0011',
  '002-0012',
  '002-0001',
  '002-0009',
  '002-0005',
  '002-0013',
];

export const VALID_COUNTRIES = [
  '276',
  '040',
  '756',
  '056',
  '100',
  '156',
  '191',
  '196',
  '203',
  '208',
  '233',
  '246',
  '250',
  '300',
  '348',
  '352',
  '372',
  '380',
  '414',
  '428',
  '438',
  '440',
  '442',
  '470',
  '528',
  '578',
  '616',
  '620',
  '642',
  '643',
  '703',
  '705',
  '724',
  '752',
  '826',
  '840',
];
