import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MenuStore } from '@app/shared';
import { PublicUserInfoComponent } from '@app/shared/components/public-user-info/public-user-info.component';
import { ContentToolbarComponent, NoDataComponent } from '@givve/ui-kit/components';
import { ConfirmDialogData, ConfirmDialogService } from '@givve/ui-kit/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PublicUserStore } from './store/public-users.store';

@Component({
  selector: 'app-public-users',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    TranslateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    ContentToolbarComponent,
    NoDataComponent,
    MatButtonModule,
    PublicUserInfoComponent,
  ],
  providers: [PublicUserStore],
  templateUrl: './public-users.component.html',
})
export class PublicUsersComponent implements OnInit {
  private publicUserStore = inject(PublicUserStore);
  private confirmDialog = inject(ConfirmDialogService);
  private translate = inject(TranslateService);
  private menuStore = inject(MenuStore);

  vm$ = this.publicUserStore.vm$;

  alternativeConfirmDelete = {
    message: this.translate.instant('common.confirm_delete'),
    affirmButton: this.translate.instant('common.ok'),
    dismissButton: this.translate.instant('common.cancel'),
  } as ConfirmDialogData;

  ngOnInit(): void {
    this.menuStore.setBreadcrumbs({ title: 'common.client-users-managment', level: 'ROOT' });
  }

  onSearch(email: string | null) {
    if (email?.trim() && email.trim().length) {
      this.publicUserStore.loadUser(email.trim());
    }
  }

  onPasswordReset(id: string) {
    this.publicUserStore.passwordReset(id);
  }

  onResendInvitation(id: string) {
    this.publicUserStore.resendInvitation(id);
  }

  onDelete(id: string, customerId: string, clientType: 'customer' | 'multiplier') {
    this.confirmDialog
      .open(this.alternativeConfirmDelete)
      .affirm(() => this.publicUserStore.delete({ id, ids: [customerId], clientType }));
  }
}
