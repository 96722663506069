import { Directive, ElementRef, HostListener, inject } from '@angular/core';

@Directive({
  selector: '[appUppercaseNoWhitespaces]',
  standalone: true,
})
export class UppercaseNoWhitespacesDirective {
  private ref = inject(ElementRef);

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.ref.nativeElement.value = value.toUpperCase().split(' ').join('');
  }
}
