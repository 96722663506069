import { Injectable } from '@angular/core';
import { InvoicePaymentTransactionAssignment } from '@app/api';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';

@Injectable({ providedIn: 'root' })
export class InvoicePaymentTransactionAssignmentHttpService extends MultiObjectHTTPService<InvoicePaymentTransactionAssignment> {
  memberUri = new URITemplate('{+api_v1_base}/invoice_payment_transaction_assignments/{id}');
  collectionUri = new URITemplate('{+api_v1_base}/invoice_payment_transaction_assignments');
  searchUri = new URITemplate(`{+api_base}/admin/search/invoice_payment_transaction_assignments`);
}
