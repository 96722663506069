import { UserRole } from './api/interfaces/user.interface';
import { MenuDefinition } from './shared';

export const appComponentMenu: MenuDefinition = {
  title: 'Start',
  isStart: true,
  menu: [
    {
      title: 'frame.new_customer',
      icon: 'add',
      roles: [UserRole.SUPERUSER, UserRole.HELPDESK],
      action: 'createCustomer',
      dataCy: 'frame-list-item-new-customer',
    },
    {
      title: 'frame.new_multiplier',
      icon: 'add',
      roles: [UserRole.SUPERUSER, UserRole.HELPDESK],
      action: 'createMultiplier',
      dataCy: 'frame-list-item-new-multiplier',
    },

    {
      title: 'common.csv_upload',
      path: '/csv-upload',
      icon: 'upload',
      roles: [UserRole.SUPERUSER, UserRole.HELPDESK],
    },
    {
      title: 'common.book_keeping',
      path: '/credit-transfers',
      icon: 'suitcase',
      roles: [UserRole.SUPERUSER, UserRole.TRANSACTION_ASSIGNMENT],
    },

    {
      path: '/email-activity',
      title: 'common.email_activity',
      icon: 'email',
    },
    {
      path: '/client-users',
      title: 'common.client-users-managment',
      icon: 'user',
      roles: [UserRole.SUPERUSER, UserRole.HELPDESK],
    },
    {
      path: '/api-accesses',
      title: 'common.api_accesses',
      icon: 'settings',
      roles: [UserRole.SUPERUSER],
    },

    {
      path: '/audit-logs',
      title: 'Audit Logs',
      icon: 'error-report',
      roles: [UserRole.SUPERUSER],
    },

    {
      path: '/merchants',
      title: 'common.partner_managment',
      icon: 'store',
      roles: [UserRole.SUPERUSER, UserRole.MERCHANT_MANAGER],
    },

    {
      title: 'common.card_designs',
      icon: 'card-default',
      path: '/card-designs',
      roles: [UserRole.SUPERUSER, UserRole.HELPDESK],
    },
    {
      title: 'common.testing',
      icon: 'settings',
      path: '/push-notification',
      roles: [UserRole.SUPERUSER],
      development: true,
    },
    { path: '/admin-users', title: 'common.users', icon: 'user', roles: [UserRole.SUPERUSER] },
    {
      path: '/releasenotes',
      title: 'Releasenotes',
      icon: 'list',
    },
  ],
};
