import { Injectable, inject } from '@angular/core';
import { PublicUser } from '@app/shared/models/public-user';
import { PublicUserService } from '@app/shared/services/public-user.service';
import { NotificationService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap, tap } from 'rxjs';

export interface PublicUserState {
  statusType: 'LOADING' | 'DATA' | 'ERROR' | 'CLOSED' | 'OPEN' | 'INIT' | 'NO_DATA';
  user: PublicUser | null;
  processing: boolean;
}

export const DEFAULT_STATE: PublicUserState = {
  statusType: 'INIT',
  user: null,
  processing: false,
};

@Injectable()
export class PublicUserStore extends ComponentStore<PublicUserState> {
  private publicUsersService = inject(PublicUserService);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);

  readonly user$ = this.select((state) => state.user);
  readonly statusType$ = this.select((state) => state.statusType);
  readonly vm$ = this.select((state) => state);

  constructor() {
    super(DEFAULT_STATE);
  }

  readonly loadUser = this.effect((email$: Observable<string>) =>
    email$.pipe(
      tap(() => this.patchState({ statusType: 'LOADING' })),
      switchMap((id) =>
        this.publicUsersService.get(id).pipe(
          tapResponse(
            (user) => this.onLoadUser(user),
            (error) => {
              this.patchState({ statusType: 'ERROR' });
              this.notification.open({
                message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
              });
            }
          )
        )
      )
    )
  );

  resendInvitation = this.effect((id$: Observable<string>) => {
    return id$.pipe(
      tap(() => this.patchState({ processing: true })),
      switchMap((id) =>
        this.publicUsersService.resendInvitation(id).pipe(
          tapResponse(
            () => {
              this.notification.open({
                message: this.translate.instant('common.resend_invitation_success'),
              });
              this.patchState({ processing: false });
            },
            (error) => {
              this.notification.open({
                message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
              });
              this.patchState({ statusType: 'ERROR' });
            }
          )
        )
      )
    );
  });

  passwordReset = this.effect((id$: Observable<string>) => {
    return id$.pipe(
      tap(() => this.patchState({ processing: true })),
      switchMap((id) =>
        this.publicUsersService.passwordReset(id).pipe(
          tapResponse(
            () => {
              this.notification.open({
                message: this.translate.instant('common.password_reset_success'),
              });
              this.patchState({ processing: false });
            },
            (error) => {
              this.notification.open({
                message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
              });
              this.patchState({ statusType: 'ERROR' });
            }
          )
        )
      )
    );
  });

  delete = this.effect((data$: Observable<{ id: string; ids: string[]; clientType: 'customer' | 'multiplier' }>) => {
    return data$.pipe(
      tap(() => this.patchState({ processing: true })),
      switchMap((data) =>
        this.publicUsersService.deleteUser(data.id, data.ids, data.clientType).pipe(
          tapResponse(
            () => {
              this.notification.open({
                message: this.translate.instant('common.delete_success'),
              });
              this.patchState({ processing: false });
              this.loadUser(this.state().user!.email);
            },
            (error) => {
              this.notification.open({
                message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
              });
              this.patchState({ statusType: 'ERROR' });
            }
          )
        )
      )
    );
  });

  readonly onLoadUser = this.updater((state, user: PublicUser) => ({
    ...state,
    user,
    statusType: user ? 'DATA' : 'NO_DATA',
  }));

  readonly setClient = this.updater((state, clientType: 'customer' | 'multiplier') => ({
    ...state,
    clientType,
  }));
}
