import { Component, input } from '@angular/core';
import { MatChipOption } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-scheduled-load-order-state',
  imports: [MatChipOption, TranslateModule],
  templateUrl: './scheduled-load-order-state.component.html',
  styleUrl: './scheduled-load-order-state.component.scss',
})
export class ScheduledLoadOrderStateComponent {
  state = input.required<string>();
}
