import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FixedRowSizeTableVirtualScrollStrategyDirective } from '@app/shared/features/table-virtuell-scrolling/directives/fixed-row-size-table-virtual-scroll-strategy.directive';
import { VirtualTableDirective } from '@app/shared/features/table-virtuell-scrolling/directives/virtual-table.directive';
import { TableVirtualScrollViewportComponent } from '@app/shared/features/table-virtuell-scrolling/table-virtual-scroll-viewport.component';
import { TranslateModule } from '@ngx-translate/core';
import { BgErrorDirective } from '../bg-error.directive';
import { TooltipHintPipe } from '../tooltip-error.pipe';

@Component({
  selector: 'app-csv-upload-preview-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    TranslateModule,
    MatTooltipModule,
    BgErrorDirective,
    TooltipHintPipe,
    ScrollingModule,
    TableVirtualScrollViewportComponent,
    VirtualTableDirective,
    FixedRowSizeTableVirtualScrollStrategyDirective,
  ],
  templateUrl: './csv-upload-preview-table.component.html',
  styleUrls: ['csv-upload-preview-table.component.scss'],
})
export class CsvUploadPreviewTableComponent {
  datasource = input.required<any[]>();
  templateHeaders = input.required<string[]>();
  formArray = input.required<FormArray>();

  displayedColumns = computed(() => ['index', ...this.templateHeaders()]);
}
