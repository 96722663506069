<div class="logo-wrapper">
  <div
    class="logo"
    (click)="navigate('/')"
  >
    <img
      class="icon"
      [ngSrc]="logo"
      [height]="height"
      [width]="width"
      priority
    />
  </div>
</div>
