import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'givve-nav-meta-item',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './nav-meta-item.component.html',
  styleUrls: ['./nav-meta-item.component.scss'],
})
export class NavMetaItemComponent {
  @Input({ required: true }) icon!: string;
}
