import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { CoCsvUploadTemplateState } from '@app/features/csv-upload-new/store/co-csv-upload-template.store';
import { ProductIconComponent } from '@givve/ui-kit/components';
import { TranslateModule } from '@ngx-translate/core';
import { CsvTemplate } from '../models/csv-template';
import { CsvTemplateService } from '../services/csv-template.service';
import { CsvUploadPreviewTableComponent } from './csv-upload-preview-table/csv-upload-preview-table.component';

@Component({
  selector: 'app-csv-upload-preview-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CsvUploadPreviewTableComponent,
    MatExpansionModule,
    MatDialogModule,
    MatButtonModule,
    ProductIconComponent,
  ],
  templateUrl: './csv-upload-preview-dialog.component.html',
})
export class CsvUploadPreviewDialogComponent {
  dialogRef = inject(MatDialogRef<CsvUploadPreviewDialogComponent>);
  dialogData = inject<{ state: CoCsvUploadTemplateState; template: CsvTemplate; example: boolean }>(MAT_DIALOG_DATA);
  private csvTemplateService = inject(CsvTemplateService);

  noErrorsDataSource = this.dialogData.state
    .csvFormGroup!.controls.csvData.controls.filter((group) => group.valid)
    .map((group) => group.value);

  hintAndErrorDatasource = this.dialogData.state
    .csvFormGroup!.controls.csvData.controls.filter((group) => group.invalid)
    .map((group) => group.value);

  hasErrorEntries = this.dialogData.state.csvFormGroup!.controls.csvData.controls.some((group) => {
    const errorObj = this.csvTemplateService.getFormGroupErrors(group);
    return this.checkErrorsForRealErrors(errorObj);
  });

  formArray = this.dialogData.state.csvFormGroup!.get('csvData') as FormArray;

  checkErrorsForRealErrors(errors: { [key: string]: any }): boolean {
    let value = false;
    for (const key in errors) {
      if (typeof errors[key] === 'object') {
        value = this.checkErrorsForRealErrors(errors[key]);
      } else if (key !== 'hint') {
        value = true;
        break;
      }
    }
    return value;
  }
}
