<ng-container *ngrxLet="isMobile$ as isMobile">
  <mat-toolbar class="toolbar">
    @if (isMobile) {
      <button
        type="button"
        class="mr-auto"
        mat-icon-button
        (click)="toggleMenu()"
        >
        <svg-icon key="menu-full"></svg-icon>
      </button>
    }
    @if (!isMobile) {
      <ng-content></ng-content>
    }
    <div
      #ref
      class="h-100"
      >
      @if (!isMobile) {
        <ng-content
          select="givve-toolbar-profile"
        ></ng-content>
      }
    </div>
    @if (!isMobile && !ref?.children?.length) {
      <givve-toolbar-profile
        [user]="user$ | async"
        class="h-100"
      ></givve-toolbar-profile>
    }
  </mat-toolbar>
</ng-container>
