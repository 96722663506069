import { Injectable, inject } from '@angular/core';
import { Params, Router } from '@angular/router';
import { AuthFacade } from '@app/auth';
import { StatusType } from '@givve/ui-kit/models';
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs';
import { NavItem } from '../shared.interfaces';

export interface MenuState {
  statusType: StatusType;
  menu: NavItem[];
  history: HistoryItem[];
  title: string;
}

export const DEFAULT_MENU_STATE: MenuState = {
  statusType: 'INIT',
  menu: [],
  history: [],
  title: '',
};

@Injectable({ providedIn: 'root' })
export class MenuStore extends ComponentStore<MenuState> {
  readonly vm$ = this.select((state) => state);

  authFacade = inject(AuthFacade);
  translate = inject(TranslateService);
  router = inject(Router);

  constructor() {
    super(DEFAULT_MENU_STATE);
  }
  /**Set mainmenu by MenuDefinition. Translates every NavItem and filters them by userroles.*/
  readonly setMenu = this.effect((menuDefinition$: Observable<MenuDefinition>) => {
    return combineLatest([this.authFacade.user$, menuDefinition$]).pipe(
      filter(([user, _]) => user != null), // Proceed only if the user is logged in
      map(([user, menuDefinition]) => ({ userRoles: user?.roles, menuDefinition })), // Extract user roles and menu
      switchMap(({ userRoles, menuDefinition }) => {
        const filteredMenu = menuDefinition.menu
          ?.map((item) => {
            if (!item.roles || item.roles.length === 0 || item.roles.some((role) => userRoles?.includes(role))) {
              const queryParams = { ...item.queryParams } as Params;
              item.queryParams = queryParams;
              return {
                ...item,
                title: this.translate.instant(item.title),
              };
            } else {
              return null;
            }
          })
          .filter((item): item is NavItem => item !== null);
        return of({ filteredMenu, def: menuDefinition });
      }),
      map(({ filteredMenu, def }) => {
        this.patchState((state) => ({
          ...this.updateHistory(state, def),
          statusType: 'DATA',
          menu: filteredMenu,
        }));
      })
    );
  });

  /**Updates the navigation history represented by the breadcrumb component*/
  private updateHistory(state: MenuState, def: MenuDefinition) {
    const currPath = def.staticUrl ? def.staticUrl : window.location.pathname;
    //Get the exisisting params from the current url and save them.
    //Important for navigating back in the history
    let params = {};
    const paramString = window.location.search?.replace('?', '');
    if (paramString) {
      paramString.split(',').forEach((pair) => {
        const [key, value] = pair.split('=');
        if (key && value) {
          params = { ...params, [key]: value };
        }
      });
    }

    const newHistoryItem = {
      url: def.isStart ? '/' : currPath,
      title: this.getTitle(def),
      params: def.isStart ? {} : params,
      level: def.level,
    };

    let updatedHistory = state.history;

    if (def.isStart) {
      //If item is the start/root item replace the whole history
      updatedHistory = [newHistoryItem];
    } else if (currPath && state.history[state.history.length - 1]?.url === currPath) {
      //If we try to add an item with the same path (potentially the same item again) just update the title
      //in case it changed
      updatedHistory[updatedHistory.length - 1]!.title = newHistoryItem.title;
    } else {
      //Else check if the item is already somewhere in the history
      const idx = state.history.findIndex((h) => h.url === currPath);
      if (idx > -1) {
        //If it is already in the history, just cut off the following items
        updatedHistory = state.history.splice(0, idx + 1);
      } else {
        if (def.level) {
          const idx = state.history.findIndex((h) => h.level === def.level);
          if (idx > -1) {
            //If a item of the same level is already in the history, cut it off and the following items
            updatedHistory = state.history.splice(idx);
          }
        }

        //If its a complete new item, append it to the end
        updatedHistory = [...state.history, newHistoryItem];
      }
    }

    return { ...state, history: updatedHistory };
  }

  /**Go back to the previous path in the history.*/
  readonly goBack = () => {
    // If index is not provided, fallback to the default behavior
    const targetIndex = this.state().history.length - 2;
    // Call the updater function with the resolved index
    this.goBackToIndex(targetIndex);
  };

  /**Go back to the specified index of the history.*/
  readonly goBackToIndex = this.updater((state, targetIndex: number) => {
    // Ensure the targetIndex is within bounds
    if (targetIndex < 0 || targetIndex >= state.history.length) {
      console.warn('Invalid index provided for navigation.');
      return state; // Return unchanged state
    }
    //Same Path
    if (targetIndex == state.history.length - 1) {
      return state;
    }

    const diff = state.history.length - 1 - targetIndex;

    // Get the target entry
    const targetEntry = state.history[targetIndex]!;
    // Navigate to the target entry's URL with its query parameters
    this.router.navigate([targetEntry.url], { queryParams: targetEntry.params });

    // Cut off all entries after the selected index
    return {
      ...state,
      history: state.history.slice(0, targetIndex + 1),
    };
  });

  /**Update the counts of menuentries that display a data count*/
  readonly updateCounts = this.updater((state, totals: Record<string, number>) => {
    const updatedMenu = state.menu.map((menuItem) => {
      if (menuItem.key && totals.hasOwnProperty(menuItem.key)) {
        return {
          ...menuItem,
          dataCount: totals[menuItem.key],
        };
      }
      return menuItem;
    });
    return {
      ...state,
      menu: updatedMenu,
    };
  });

  /**Update the history without updating the menu. */
  readonly setBreadcrumbs = this.updater((state, def: MenuDefinition) => {
    return {
      ...this.updateHistory(state, def),
    };
  });

  private getTitle(def: MenuDefinition): string {
    let title = this.translate.instant(def.title);
    if (def.entityTitle) {
      title += ' ' + def.entityTitle;
    }
    return title;
  }
}

export interface HistoryItem {
  title: string;
  url: string;
  params?: Params;
  level?: MenuLevel;
}

export interface MenuDefinition {
  //Translateable title for breadcrumbs
  title: string;
  //Additional content for title thats added to title without translating.
  entityTitle?: string;
  //Menuentries to set.
  menu?: NavItem[];
  //Flag if this is the start node.
  isStart?: boolean;
  //To determine if components are on the same level and replace them in the history.
  level?: MenuLevel;
  //Static url thats set in the history.
  staticUrl?: string;
}

export type MenuLevel = 'ROOT' | 'CUSTOMER' | 'SEARCH';
