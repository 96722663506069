const getItem = (key: string) => {
  let val;
  try {
    val = localStorage.getItem(key);
  } catch (e) {
    console.error(e);
  }

  return val;
};

const setItem = (key: string, val: string) => {
  try {
    localStorage.setItem(key, val);
  } catch (e) {
    console.error(e);
  }
};

const removeItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error(e);
  }
};

const localStorageDenied = () => {
  try {
    localStorage.removeItem('test');
  } catch (e) {
    return true;
  }

  return false;
};

export { getItem, setItem, removeItem, localStorageDenied };
