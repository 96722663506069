<app-fieldset [title]="'common.contact_persons' | translate">
  <form [formGroup]="formGroup()">
    <ng-container formArrayName="contact_persons">
      @for (contactPersonForm of contactPersons().controls; track contactPersonForm; let i = $index) {
        <div
          class="tw-mt-4 tw-grid tw-grid-cols-4 tw-items-center tw-gap-4"
          [formGroup]="contactPersonForm"
        >
          <mat-form-field>
            <mat-label> {{ 'contact-persons-form.name' | translate }} </mat-label>
            <input
              data-cy="client-contact-persons-form-name-input"
              formControlName="name"
              matInput
              givveTrim
            />
          </mat-form-field>
          <mat-form-field class="tw-flex-[0_0_90px]">
            <mat-select formControlName="email_notification">
              <mat-option
                *ngFor="let value of emailNotificationValues"
                [value]="value"
                >{{ value | uppercase }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field data-cy="client-contact-persons-form-email">
            <mat-label> {{ 'contact-persons-form.email' | translate }} </mat-label>
            <input
              data-cy="client-contact-persons-form-email-input"
              formControlName="email"
              matInput
              givveTrim
            />
            <mat-error *ngIf="contactPersonForm.controls.email.errors?.email">
              {{ 'common.errors.email' | translate }}
            </mat-error>
            <mat-error *ngIf="contactPersonForm.controls.email.errors?.required">
              {{ 'common.fill_out_field' | translate }}
            </mat-error>
          </mat-form-field>
          <button
            data-cy="client-contact-persons-form-remove-button"
            mat-button
            (click)="removeContactPerson(i)"
            class="tw-flex-[0_0_65px]"
          >
            <fa-icon icon="trash" />
          </button>
        </div>
      }
    </ng-container>
  </form>
  <button
    data-cy="client-contact-persons-form-add-button"
    class="icon-text-btn tw-mb-4 tw-mr-4 tw-content-start"
    mat-button
    (click)="addContactPerson()"
  >
    {{ 'common.add_more_contacts' | translate }}
    <fa-icon
      icon="add"
      size="lg"
    />
  </button>
</app-fieldset>
