import { inject, Pipe, PipeTransform } from '@angular/core';
import { PublicUser } from '@app/shared/models/public-user';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'confStateTooltip',
  standalone: true,
})
export class ConfStateTooltipPipe implements PipeTransform {
  private translate = inject(TranslateService);
  transform(user: PublicUser): string {
    const isExpired = !user.expires_at ? false : new Date(user.expires_at) < new Date();
    if (isExpired) {
      return this.translate.instant('user.tooltip.email_invite_expired');
    } else if (!user.confirmed && !isExpired) {
      return this.translate.instant('user.tooltip.email_invite_open');
    }

    return this.translate.instant('user.tooltip.email_invite_accepted');
  }
}
