<div class="tw-relative tw-bg-gray-600">
  <img
    class="tw-block tw-max-h-[95vh] tw-max-w-full"
    [src]="imgsrc"
    [@fade]="state"
    (@fade.done)="onDone($event)"
    (load)="imgLoaded()"
  />

  @if (data.urls.length > 1) {
    <div
      class="tw-absolute tw-right-8 tw-top-1/2 -tw-translate-y-1/2 tw-cursor-pointer tw-rounded-md tw-bg-black tw-bg-opacity-30 tw-pr-1 tw-text-white"
      (click)="showNext()"
    >
      <fa-icon
        icon="chevron-right"
        size="5x"
      ></fa-icon>
    </div>

    <div
      class="tw-absolute tw-left-8 tw-top-1/2 -tw-translate-y-1/2 tw-cursor-pointer tw-rounded-md tw-bg-black tw-bg-opacity-30 tw-pl-1 tw-text-white"
      (click)="showPrevious()"
    >
      <fa-icon
        icon="chevron-left"
        size="5x"
      ></fa-icon>
    </div>

    <div
      class="tw-absolute tw-bottom-8 tw-left-1/2 tw-flex -tw-translate-x-1/2 tw-gap-2 tw-rounded-md tw-bg-black tw-bg-opacity-30 tw-p-1"
    >
      @for (item of data.urls; track $index) {
        <div
          class="tw-h-3 tw-w-3 tw-cursor-pointer tw-rounded-full tw-bg-white tw-pr-1"
          [ngClass]="{ primaryBg: $index === arrIdx }"
          (click)="jumpTo($index)"
        ></div>
      }
    </div>
  }

  @if (loading) {
    <fa-icon
      icon="gear"
      [animation]="'spin'"
      size="6x"
      class="primaryColor tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
    ></fa-icon>
  }

  <div
    class="tw-absolute tw-right-8 tw-top-8 tw-cursor-pointer tw-rounded-md tw-bg-black tw-bg-opacity-30 tw-p-1 tw-text-white"
    (click)="close()"
  >
    <fa-icon
      icon="close"
      size="2x"
    ></fa-icon>
  </div>
</div>
