import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@app/env';
import { HumanizeInvoicePaymentMethodPipe } from '@app/features/card-orders-details/pipes/humanize-invoice-payment-method.pipe';
import { InvoiceNumberComponent, InvoiceStateComponent, OrderInvoicesStore } from '@app/shared';
import { ErrorPlaceholderComponent } from '@app/shared/components/error-placeholder/error-placeholder.component';
import { OldAdminSwitchComponent } from '@app/shared/components/old-admin-switch/old-admin-switch.component';
import { AmountComponent, ContentLoadingComponent, LoadMoreComponent, NoDataComponent } from '@givve/ui-kit/components';
import { NavigateOnMatSortChangeDirective } from '@givve/ui-kit/directives';
import { Invoice } from '@givve/ui-kit/models';

import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { exhaustMap, tap } from 'rxjs';

@Component({
  selector: 'app-order-invoices',
  imports: [
    MatTableModule,
    LetDirective,
    NoDataComponent,
    OldAdminSwitchComponent,
    MatSortModule,
    DatePipe,
    HumanizeInvoicePaymentMethodPipe,
    TranslateModule,
    NavigateOnMatSortChangeDirective,
    AsyncPipe,
    AmountComponent,
    InvoiceStateComponent,
    MatCardModule,
    ContentLoadingComponent,
    LoadMoreComponent,
    ErrorPlaceholderComponent,
    InvoiceNumberComponent,
  ],
  templateUrl: './order-invoices.component.html',
})
export class OrderInvoicesComponent {
  protected redirectBasePath = `${environment.adminUrlV1}/invoices/`;

  protected displayedColumns = ['number', 'gross_amount', 'state', 'payment_method', 'type', 'booked_on', 'actions'];
  protected route = inject(ActivatedRoute);

  protected params$ = this.route.params;

  protected router = inject(Router);
  private componentStore = inject(OrderInvoicesStore);
  dataSource = new MatTableDataSource<Invoice>();

  /**
   * Observable with the data for the load order invoices page
   */
  vm$ = this.route.params.pipe(
    tap((params) => {
      const url = this.route.parent!.parent!.snapshot.url.toString();
      const filterParam = url.includes('unload-orders')
        ? 'unload_order_id'
        : url.includes('card-orders')
          ? 'card_order_id'
          : 'load_order_id';
      this.componentStore.loadOrderInvoices({ params, filterParam });
    }),
    exhaustMap(() =>
      this.componentStore.vm$.pipe(
        tap((vm) => {
          this.dataSource.data = vm.invoices.data;
        })
      )
    )
  );

  /**
   * Callback for the load more button
   */
  loadMoreOrderInvoices(link: string) {
    this.componentStore.loadMoreOrderInvoices(link);
  }
}
