import { CommonModule } from '@angular/common';
import { Component, inject, input, output } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ToolbarMenuComponent } from '@app/shared/components/toolbar-menu/toolbar-menu.component';
import { PublicUser } from '@app/shared/models/public-user';
import { ConfStateIconPipe } from '@app/shared/pipes/conf-state-icon.pipe';
import { ConfStateTooltipPipe } from '@app/shared/pipes/conf-state-tooltip.pipe';
import { FaIconComponent, FaStackComponent, FaStackItemSizeDirective } from '@fortawesome/angular-fontawesome';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';

@Component({
  selector: 'app-public-user-info',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    FaStackComponent,
    FaStackItemSizeDirective,
    SvgIconComponent,
    ConfStateIconPipe,
    ConfStateTooltipPipe,
    MatTooltipModule,
    MatButtonModule,
    MatListModule,
    MatDividerModule,
    ToolbarMenuComponent,
    TranslateModule,
    FaIconComponent,
  ],
  templateUrl: './public-user-info.component.html',
})
export class PublicUserInfoComponent {
  publicUser = input.required<PublicUser>();
  readonly = input<boolean>();
  delete = output<{ id: string; clientType: 'customer' | 'multiplier' }>();
  passwordReset = output<void>();
  resendInvitation = output<void>();

  router = inject(Router);

  assignedCustomers$ = toObservable(this.publicUser).pipe(
    map((user) => user?.related_clients.filter((c) => c.type === 'Customer'))
  );
  assignedMultipliers$ = toObservable(this.publicUser).pipe(
    map((user) => user?.related_clients.filter((c) => c.type === 'Multiplier'))
  );

  onDelete(id: string, clientType: 'customer' | 'multiplier') {
    this.delete.emit({ id, clientType });
  }

  onPasswordReset() {
    this.passwordReset.emit();
  }

  onResendInvitation() {
    this.resendInvitation.emit();
  }

  onNavigate(url: string) {
    if (!this.readonly()) {
      this.router.navigate([url]);
    }
  }
}
