import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { User } from '@givve/ui-kit/models';
import { TranslateModule } from '@ngx-translate/core';
import { FrameService } from '../../../services/frame.service';
import { FrameStore } from '../../../store/frame.store';

@Component({
  selector: 'givve-toolbar-profile',
  templateUrl: './toolbar-profile.component.html',
  styleUrls: ['./toolbar-profile.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, RouterModule, TranslateModule],
})
export class ToolbarProfileComponent {
  @Input({ required: true }) user: User | null = null;
  frameService = inject(FrameService);
  frameStore = inject(FrameStore);
  logoutText$ = this.frameStore.logoutText$;

  get id() {
    return this.user?.id;
  }

  get name() {
    return this.user?.email;
  }

  get initial() {
    return this.name?.substring(0, 1) ?? '';
  }

  onProfileClick() {
    this.frameService.profileClicked(this.user!);
  }

  onLogout() {
    this.frameService.triggerLogout();
  }
}
