import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { URITemplate } from '@app/api/services/uri-template';
import { ApiResponse } from '@givve/ui-kit/models';
import { Observable, of } from 'rxjs';
import { GlobalSearchRouterLinks, RegexRule } from '../stores/global-search.store';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  private httpClient = inject(HttpClient);

  searchUri = new URITemplate(`{+api_base}/admin/search/{collection}`);
  filterUri = new URITemplate(`{+api_base}/admin/search/{collection}`);

  loadList<T>(
    params: Params,
    regexRules: RegexRule[],
    excludedRegexValidators: RegexRule[],
    routerLink: GlobalSearchRouterLinks
  ): Observable<ApiResponse<T[]> | null> {
    const matchsExcludeRegex = this.checkIfExcludedRegexMatches(params.query, excludedRegexValidators);
    if (matchsExcludeRegex) return of(null);

    const regexRule = this.checkIfRegexMatches(params.query, regexRules);
    if (regexRule!.requestType === 'SKIP_QUERY') return of(null);

    const httpParams = this.getHttpParams(regexRule!, params);
    const httpUri = this.getHttpUri(this.globalSearchRouterLinksToCollectionUrl(routerLink));

    return this.httpClient.get<ApiResponse<T[]>>(httpUri, { params: httpParams });
  }

  loadMore(nextUrl: string): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(nextUrl);
  }

  // --------- Helper functions to prepare API Calls with URI and Params -------------

  private getHttpUri(collection: string): string {
    return this.searchUri.build({ collection });
  }

  private getHttpParams(regexRule: RegexRule, params: Params): HttpParams {
    let httpParams: HttpParams | null;
    if (regexRule.requestType === 'FILTER') {
      httpParams = this.appendFilterParam(params.query, regexRule.key!);
    }
    // will be for the SEARCH request type
    else {
      httpParams = this.appendSearchParam(params.query, regexRule.key!);
    }

    Object.keys(params).forEach((key) => {
      if (key !== 'query') {
        httpParams = this.appendSortParam(httpParams!, key, params[key]);
      }
    });

    // Default rule. Has to be removed when it is activated on BE side on default.
    httpParams = httpParams.append('rule', 'case_insensitive_wildcard');

    return httpParams;
  }

  private checkIfExcludedRegexMatches(searchParam: string, regexRules: RegexRule[]): RegexRule | undefined {
    for (let index = 0; index < regexRules.length; index++) {
      const rule = regexRules[index]!;

      if (rule.validator!.test(searchParam)) {
        return rule;
      }
    }

    return undefined;
  }

  private checkIfRegexMatches(searchParam: string, regexRules: RegexRule[]): RegexRule | undefined {
    for (let index = 0; index < regexRules.length; index++) {
      const rule = regexRules[index]!;

      if (index === regexRules.length - 1) {
        return rule;
      } else if (rule.validator!.test(searchParam)) {
        return rule;
      }
    }
  }

  private appendFilterParam(searchParam: string, key: string): HttpParams {
    return new HttpParams().set(`filter[${key}]`, "'" + searchParam + "'");
  }

  private appendSearchParam(searchParam: string, key: string): HttpParams {
    return new HttpParams().set(`search[${key}]`, "'" + searchParam + "'");
  }

  private appendSortParam(httpParams: HttpParams, sort: string, direction: string): HttpParams {
    return httpParams.append(`sort[${sort}]`, direction);
  }

  private globalSearchRouterLinksToCollectionUrl(routerLink: GlobalSearchRouterLinks): string {
    switch (routerLink) {
      case 'voucher-owners':
        return 'voucher_owners';
      case 'representatives':
        return 'representatives';
      case 'card-orders':
        return 'card_orders';
      case 'load-orders':
        return 'load_orders';
      case 'unload-orders':
        return 'unload_orders';
      case 'scheduled-load-orders':
        return 'scheduled_load_orders';
      default:
        return routerLink;
    }
  }
}
