// @ts-strict-ignore
import { Injectable } from '@angular/core';
import JSONFormatter from 'json-formatter-js';

@Injectable({
  providedIn: 'root',
})
export class JsonFormatterService {
  formatter: JSONFormatter;

  init() {
    this.formatter = new JSONFormatter('');
  }
}
