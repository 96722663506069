<button
  mat-button
  class="trigger-btn"
  [matMenuTriggerFor]="menu"
  (click)="onProfileClick()"
  data-cy="toolbar-profile-button"
  >
  @if (initial) {
    <span
      class="initial"
      >{{ initial }}</span
      >
    }
    <div class="name">
      <div class="user-name truncate">{{ name || id }}</div>
    </div>
  </button>
  <mat-menu #menu="matMenu">
    <ng-content></ng-content>
    @if (logoutText$ | async; as logoutText) {
      <button
        mat-menu-item
        (click)="onLogout()"
        data-cy="logout-button"
        >
        {{ logoutText }}
      </button>
    }
  </mat-menu>
  