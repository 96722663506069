import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import type { VoucherOwner } from '@givve/ui-kit/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VoucherOwnerHttpService extends MultiObjectHTTPService<VoucherOwner> {
  memberUri = new URITemplate('{+api_v1_base}/voucher_owners/{id}');
  pushNotificationUri = new URITemplate(`{+api_v1_base}/notifications/voucher_owners/{id}/firebase_notifications`);

  resetPasswordUri = new URITemplate(`{+api_v1_base}/voucher_owners/{id}/reset_password`);
  collectionUri = new URITemplate('{+api_v1_base}/voucher_owners');
  searchUri = new URITemplate(`{+api_base}/admin/search/voucher_owners`);
  emailUri = new URITemplate(`{+api_v1_base}/voucher_owners/{id}/email`);
  jwtTokenUri = new URITemplate(`{+api_v1_base}/sessions/card_suite/v2/{id}`);

  private httpClient = inject(HttpClient);

  pushNotification(id: string, data: any): Observable<any> {
    const uri = this.pushNotificationUri.build({ id });
    return this.post(uri, data);
  }

  resetPassword(id: string): Observable<any> {
    const uri = this.resetPasswordUri.build({ id });
    return this.put(uri);
  }

  deleteEmail(id: string): Observable<any> {
    const uri = this.emailUri.build({ id });
    return this.delete(uri);
  }

  getJwtToken(id: string): Observable<string> {
    const uri = this.jwtTokenUri.build({ id });
    return this.httpClient.get(uri, { responseType: 'text' });
  }
}
