import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { environment } from '@app/env';

import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Invoice } from '@givve/ui-kit/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-invoice-number',
  imports: [MatChipsModule, CommonModule, TranslateModule, FaIconComponent],
  templateUrl: './invoice-number.component.html',
})
export class InvoiceNumberComponent {
  invoice = input.required<Invoice | null>();
  protected redirectBasePath = `${environment.adminUrlV1}/invoices/`;
  protected apiBasePath = `${environment.adminApiUrlV1}/invoices/`;
}
