import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoadingService } from '@app/core/services/loading.service';

@Component({
  selector: 'app-disable-loading',
  template: '<router-outlet></router-outlet>',
  imports: [RouterOutlet],
})
export class DisableLoadingComponent implements OnInit {
  loadingService = inject(LoadingService);

  ngOnInit(): void {
    this.loadingService.disableLoading();
  }
}
