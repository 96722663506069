import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { PRODUCT_REFS } from '@app/features/customer-create/constants';
import { TranslateModule } from '@ngx-translate/core';

const defaultProductRef = '002-0012';

@Component({
  selector: 'app-default-product-ref',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    TranslateModule,
    MatButtonModule,
  ],
  templateUrl: './default-product-ref.component.html',
})
export class DefaultProductRefComponent implements OnInit {
  control = input.required<FormControl>();
  multiplierControl = input.required<FormControl>();

  productRefs = PRODUCT_REFS;
  suggestion = '002-0012';

  ngOnInit() {
    this.multiplierControl().valueChanges.subscribe((multiplier) => {
      this.suggestion = multiplier?.default_product_ref || defaultProductRef;
      if (multiplier?.default_product_ref) {
        this.control().patchValue(this.suggestion);
        this.control().updateValueAndValidity();
      } else {
        this.control().patchValue(null);
        this.control().updateValueAndValidity();
      }
    });
  }

  onHintClick(e: Event) {
    e.stopPropagation();
    this.control().patchValue(this.suggestion);
    this.control().updateValueAndValidity();
  }
}
