import { CommonModule, DatePipe, NgClass } from '@angular/common';
import { Component, Signal, computed, inject, input, signal, untracked } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEllipsisVertical, faPenToSquare, faReply, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Comment } from '@givve/ui-kit/models';
import { CommentFormService } from '../../services/comment-form.service';
import { CommentFormComponent } from '../comment-form/comment-form.component';

const DEFAULT_OPTIONS = {
  replyText: 'Reply',
  editText: 'Edit',
  deleteText: 'Delete',
};

@Component({
  selector: 'givve-comment',
  standalone: true,
  imports: [
    CommonModule,
    NgClass,
    DatePipe,
    MatMenuModule,
    MatButtonModule,
    CommentFormComponent,
    FontAwesomeModule,
    MatTooltipModule,
  ],
  templateUrl: './comment.component.html',
  styleUrl: './comment.component.scss',
})
export class CommentComponent {
  comment = input.required<Comment>();
  comments = input.required<Comment[]>();
  isEditable = input.required<boolean>();
  isLoading = input<boolean>(false);
  options = input<Partial<typeof DEFAULT_OPTIONS>>({
    replyText: 'Reply',
    editText: 'Edit',
    deleteText: 'Delete',
  });

  icons = {
    faEllipsisVertical,
    faReply,
    faPenToSquare,
    faTrash,
  };

  private commentFormService = inject(CommentFormService);

  /**
   * Create computed options to avoid defining the whole object in the host
   * while only need to change specific options
   *
   * @memberof CommentComponent
   */
  computedOptions: Signal<typeof DEFAULT_OPTIONS> = computed(() => ({
    ...DEFAULT_OPTIONS,
    ...this.options(),
  }));

  /**
   * Find in the comments structure a relation between parent and child.
   *
   * The childrenComments will be passed recursively to the Component
   *
   * @type {Signal<Comment[]>}
   * @memberof CommentComponent
   */
  childrenComments: Signal<Comment[]> = computed(() => {
    return this.comments().filter((comment) => comment.parent_id?.$oid === this.comment().id);
  });

  /**
   * Computed loading state based on the comment form service and the current comment
   *
   * @type {Signal<boolean>}
   * @memberof CommentComponent
   */
  computedLoading: Signal<boolean> = computed(() => {
    const { data } = this.commentFormService;
    return this.isLoading() && untracked(data).comment?.id === untracked(this.comment).id;
  });

  isEditing = signal(false);
  isDeleting = signal(false);
  isReplying = signal(false);

  /**
   * Handle the comment form data through the comment form service
   *
   * @param data
   */
  commentFormSubmitted(value, comment: Comment, operation: 'reply' | 'edit') {
    if (operation === 'reply') {
      this.isReplying.set(false);
    } else if (operation === 'edit') {
      this.isEditing.set(false);
      this.isDeleting.set(false);
    }

    this.commentFormService.submitComment(value, comment, operation);
  }

  commentDeleted(comment: Comment) {
    this.commentFormService.deleteComment(comment);
  }
}
