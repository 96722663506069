import type { BaseEntity } from '@givve/ui-kit/models';

const documentTypes = [
  'idnow_identification',
  'documentation_form',
  'partner_register_current_printing',
  'partner_register_chronological_printing',
  'partner_register_structured_content',
  'trade_register_current_printing',
  'trade_register_chronological_printing',
  'trade_register_structured_content',
  'association_register_current_printing',
  'association_register_chronological_printing',
  'association_register_structured_content',
  'coop_register_current_printing',
  'coop_register_chronological_printing',
  'coop_register_structured_content',
  'gwg_information_crif',
  'gwg_information_skyminder',
  'transparency_register_printing',
  'partner_list',
  'articles_of_incorporation',
  'charter',
  'business_declaration',
  'misc',
  'searchengine_screenshot',
  'foundation_directory_printing',
  'proof_of_stock_listing',
  'post_identification',
] as const;

export type DocumentType = (typeof documentTypes)[number];

export function isOfTypeDocumentType(val: string): val is DocumentType {
  return (documentTypes as readonly string[]).includes(val);
}

export interface Document extends BaseEntity {
  comment: string;
  document_type: DocumentType;
}
