import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { User } from '@givve/ui-kit/models';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, shareReplay, skip, tap } from 'rxjs/operators';
import { NavProfileComponent } from './components/nav/nav-profile/nav-profile.component';
import { FrameService } from './services/frame.service';
import { FrameStore } from './store/frame.store';

@Component({
  selector: 'givve-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    SvgIconComponent,
    MatToolbarModule,
    RouterModule,
    LetDirective,
    NavProfileComponent,
    TranslateModule
],
  providers: [FrameService],
})
export class FrameComponent implements AfterViewInit, OnInit {
  @Input({ required: true }) version!: string;
  @Input({ required: true }) user!: User;
  @Input({ required: true }) logoutText = '';
  @Output() profileClick = new EventEmitter<User>();
  @Output() logout = new EventEmitter<void>();
  @ViewChild('drawer') drawer!: MatDrawer;
  frameStore = inject(FrameStore);
  frameService = inject(FrameService);
  breakpointObserver = inject(BreakpointObserver);

  vm$ = this.frameStore.vm$;
  toggleMenu$ = this.frameStore.toggleTrigger$;
  menuNarrowed$ = this.frameStore.menuNarrowed$;
  isMobile$ = this.frameStore.isMobile$;
  user$ = this.frameStore.user$;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.XSmall).pipe(
    map((result) => result.matches),
    tap((isMobile) => this.frameStore.setMobile(isMobile)),
    shareReplay()
  );

  ngOnInit(): void {
    this.frameStore.setUser(this.user);
    this.frameStore.setVersion(this.version);
    this.frameStore.setLogoutText(this.logoutText);
  }

  ngAfterViewInit(): void {
    this.toggleMenu$
      .pipe(
        skip(1),
        tap(() => this.drawer.toggle())
      )
      .subscribe();

    this.frameService.logoutTrigger$.subscribe(() => {
      this.logout.emit();
    });

    this.frameService.profileClick$.subscribe((user) => {
      this.profileClick.emit(user);
    });
  }

  public onToggleNav(isMobile: boolean) {
    if (!isMobile) {
      this.frameStore.toggleNarrowed();
    } else {
      this.frameStore.toggleMenu();
    }
  }
}
