import { Injectable } from '@angular/core';
import { CardDesign } from '@app/api';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';

@Injectable({ providedIn: 'root' })
export class CustomerCardDesignsHttpService extends MultiObjectHTTPService<CardDesign> {
  memberUri = new URITemplate('{+api_v1_base}/customers/{client_id}/card_designs/{id}');
  collectionUri = new URITemplate('{+api_v1_base}/customers/{client_id}/card_designs');
  searchUri = new URITemplate(`{+api_base}/admin/search/card_designs`);
}
