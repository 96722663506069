{
  "name": "card-admin",
  "version": "18.0.185",
  "license": "MIT",
  "private": true,
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:https": "ng serve --ssl --host admin.staging.card.givve.io --port 443",
    "build:production": "ng build --configuration=production",
    "build:staging": "ng build --configuration=staging",
    "test": "jest --silent",
    "test:watch": "jest --watch",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "pretty": "prettier --write \"src/**/*.{ts,html,js,scss}\"",
    "postinstall": "husky install",
    "e2e": "ng e2e",
    "e2e:headless": "ng e2e --headless",
    "e2e:standalone": "cypress open",
    "e2e:standalone:headless": "cypress run",
    "bundle:report": "ng build --configuration=production --stats-json && webpack-bundle-analyzer dist/stats.json",
    "deploy:production": "./bin/deploy.sh production",
    "deploy:staging": "./bin/deploy.sh staging",
    "generate:component": "ng generate @ngneat/spectator:spectator-component"
  },
  "dependencies": {
    "@angular/animations": "^19.1.1",
    "@angular/cdk": "^19.1.0",
    "@angular/common": "^19.1.1",
    "@angular/compiler": "^19.1.1",
    "@angular/core": "^19.1.1",
    "@angular/forms": "^19.1.1",
    "@angular/material": "^19.1.0",
    "@angular/material-moment-adapter": "^19.1.0",
    "@angular/platform-browser": "^19.1.1",
    "@angular/platform-browser-dynamic": "^19.1.1",
    "@angular/router": "^19.1.1",
    "@auth0/angular-jwt": "^5.2.0",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/free-brands-svg-icons": "^6.5.2",
    "@fortawesome/free-regular-svg-icons": "^6.5.2",
    "@fortawesome/free-solid-svg-icons": "^6.5.2",
    "@fortawesome/pro-duotone-svg-icons": "^6.4.2",
    "@fortawesome/pro-light-svg-icons": "^6.4.2",
    "@fortawesome/pro-regular-svg-icons": "^6.4.2",
    "@fortawesome/pro-solid-svg-icons": "^6.4.2",
    "@fortawesome/pro-thin-svg-icons": "^6.4.2",
    "@fortawesome/sharp-solid-svg-icons": "^6.4.2",
    "@givve/csv-upload": "^17.16.0",
    "@givve/ui-kit": "^17.97.0",
    "@ngneat/svg-icon": "^7.1.6",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngrx/component": "^19.0.0",
    "@ngrx/component-store": "^19.0.0",
    "@ngrx/operators": "^19.0.0",
    "@ngx-translate/core": "^16.0.4",
    "@sentry/angular-ivy": "^7.120.3",
    "bowser": "^2.11.0",
    "file-saver": "^2.0.5",
    "i18n-iso-countries": "^7.10.0",
    "json-formatter-js": "^2.3.4",
    "lodash-es": "^4.17.21",
    "moment": "^2.30.1",
    "ngx-cookie-service": "^18.0.0",
    "ngx-currency": "^19.0.0",
    "ngx-editor": "^18.0.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-infinite-scroll": "^19.0.0",
    "ngx-mask": "^19.0.6",
    "ngx-papaparse": "^8.0.0",
    "ngxtension": "^4.2.1",
    "rxjs": "~7.5.0",
    "tslib": "^2.3.0",
    "uuid": "^10.0.0",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.2",
    "@angular-eslint/builder": "19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "^19.1.1",
    "@angular/compiler-cli": "^19.1.1",
    "@cypress/schematic": "^3.0.0",
    "@eslint/eslintrc": "^3.2.0",
    "@eslint/js": "^9.18.0",
    "@hirez_io/observer-spy": "^2.2.0",
    "@ngneat/spectator": "^19.0.0",
    "@ngrx/schematics": "^17.2.0",
    "@sentry/cli": "^2.40.0",
    "@types/file-saver": "^2.0.7",
    "@types/jest": "^29.5.12",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^22.10.6",
    "@types/uuid": "^10",
    "@typescript-eslint/eslint-plugin": "^8.20.0",
    "@typescript-eslint/parser": "^8.20.0",
    "clsx": "^2.1.0",
    "cy-verify-downloads": "^0.2.3",
    "cypress": "^13.17.0",
    "cypress-localstorage-commands": "^2.2.7",
    "cypress-multi-reporters": "^2.0.4",
    "eslint": "^9.18.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-jsdoc": "^48.2.9",
    "firebase-tools": "13.29.1",
    "http-server": "^14.1.1",
    "husky": "^8.0.3",
    "jest": "^29.7.0",
    "jest-auto-spies": "^3.0.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-junit": "^16.0.0",
    "jest-preset-angular": "^14.1.0",
    "junit-report-merger": "^6.0.3",
    "lint-staged": "^15.2.2",
    "mocha-junit-reporter": "^2.2.1",
    "prettier": "^3.2.5",
    "prettier-plugin-organize-imports": "^3.2.4",
    "prettier-plugin-tailwindcss": "^0.6.1",
    "tailwind-merge": "^2.2.1",
    "tailwindcss": "^3.4.1",
    "typescript": "~5.6.3",
    "typescript-strict-plugin": "^2.3.1",
    "webpack-bundle-analyzer": "^4.10.1"
  },
  "engines": {
    "node": ">=18.19.1",
    "yarn": ">=4.1.1"
  },
  "lint-staged": {
    "src/**/**/*.{ts,html}": [
      "eslint"
    ],
    "src/**/**/*.{ts,js,scss,html}": [
      "prettier --write"
    ]
  },
  "scarfSettings": {
    "enabled": false
  },
  "packageManager": "yarn@4.6.0"
}
