import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'givve-frame-logo',
  standalone: true,
  imports: [CommonModule, RouterModule, NgOptimizedImage],
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() logo = '';
  @Input() width = '181';
  @Input() height = '37';
  router = inject(Router);

  navigate(path: string) {
    this.router.navigate([path]);
  }
}
