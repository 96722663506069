<h2
  mat-dialog-title
  class="tw-flex tw-items-center tw-pt-4"
  data-cy="csv-upload-preview-table"
>
  <givve-product-icon
    class="tw-pr-4"
    [product]="dialogData.template.category"
    [size]="24"
  />
  {{ 'common.' + dialogData.template.type | translate }} -
  {{
    dialogData.template.label
      ? (dialogData.template.label | translate)
      : ('common.' + dialogData.template.category | translate)
  }}
</h2>
<mat-dialog-content class="tw-pt-1">
  <mat-accordion multi>
    @if (hintAndErrorDatasource.length) {
      <mat-expansion-panel [expanded]="hintAndErrorDatasource.length">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'csv-upload.entries-with-errors-and-hints' | translate }}
          </mat-panel-title>
          <mat-panel-description>
            {{ hintAndErrorDatasource.length }} {{ 'common.entries' | translate }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-csv-upload-preview-table
          [datasource]="hintAndErrorDatasource"
          [templateHeaders]="dialogData.state.templateHeaders"
          [formArray]="formArray"
        ></app-csv-upload-preview-table>
      </mat-expansion-panel>
    }

    @if (noErrorsDataSource.length) {
      <mat-expansion-panel [expanded]="!hintAndErrorDatasource.length">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ 'csv-upload.entries-without-errors' | translate }}</mat-panel-title>
          <mat-panel-description>
            {{ noErrorsDataSource.length }} {{ 'common.entries' | translate }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-csv-upload-preview-table
          [datasource]="noErrorsDataSource"
          [templateHeaders]="dialogData.state.templateHeaders"
          [formArray]="formArray"
        ></app-csv-upload-preview-table>
      </mat-expansion-panel>
    }
  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    [mat-dialog-close]="false"
  >
    {{ 'common.cancel' | translate }}
  </button>
  @if (!dialogData.example) {
    <button
      color="primary"
      mat-flat-button
      [disabled]="hasErrorEntries"
      [mat-dialog-close]="true"
      data-cy="csv-upload-preview-table-submit"
      type="submit"
    >
      {{
        'csv-upload-preview-table.submit-card-order'
          | translate: { value: noErrorsDataSource.length + hintAndErrorDatasource.length }
      }}
    </button>
  }
</mat-dialog-actions>
