<div
  class="tw-flex tw-items-center tw-gap-2 tw-p-6 tw-pb-0 tw-text-blue-600"
  *ngrxLet="vm$ as vm"
>
  @for (item of vm.history; track $index) {
    @if ($index > 0) {
      <span>➜</span>
    }

    <a
      class="tw-cursor-pointer tw-font-semibold tw-text-blue-600 tw-no-underline hover:tw-text-blue-900"
      (click)="onHistoryClick($index)"
      >{{ item.title }}</a
    >
  }
</div>
