<mat-list-item
  data-cy="menu-item"
  [id]="id"
  (click)="trigger()"
  [ngClass]="{ highlight: highlight }"
  >
  <svg-icon
    class="svg-icon"
    key="{{ icon }}"
  ></svg-icon>
  <div [ngClass]="{ 'highlight-font': highlight }"><ng-content></ng-content></div>
  <svg-icon
    class="svg-icon-collapsable"
    [key]="isOpen ? 'arrow-up' : 'arrow-down'"
  ></svg-icon>
</mat-list-item>
<div class="indent nav-items">
  @if (open$ | async) {
    <ng-content
      select="givve-nav-item"
    ></ng-content>
  }
</div>
