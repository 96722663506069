import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { URITemplate } from '@app/api/services/uri-template';
import { VoucherCategory } from '@app/features/csv-upload/csv-upload.models';
import { ApiResponse, CardOrder } from '@givve/ui-kit/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CsvUploadService {
  collectionUri = new URITemplate('{+api_v1_base}/card_orders');
  httpClient = inject(HttpClient);

  postCardOrder(data: {
    customer_id: string;
    entries_data: any[];
    submit_after_creation: boolean;
    voucher_category: VoucherCategory;
  }): Observable<CardOrder> {
    return this.httpClient
      .post<ApiResponse<CardOrder>>(this.collectionUri.build(), data)
      .pipe(map((response) => response.data));
  }
}
