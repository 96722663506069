import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);

// docs: https://github.com/lancedikson/bowser
// browser alias map: https://github.com/lancedikson/bowser/blob/master/src/constants.js
const isValidBrowser = browser.satisfies({
  macos: {
    safari: '>10.1',
  },

  // per platform (mobile, desktop or tablet)
  mobile: {
    safari: '>=9',
    focus: '>=8',
    'android browser': '>3.10',
    samsung_internet: '>=9',
  },

  // or in general
  chrome: '>43',
  chromium: '>43',
  firefox: '>34',
  opera: '>=22',
  edge: '>11',
});

const showNoSupportWarning = () => {
  const headline = document.createElement('h1');
  headline.innerHTML = `Dieser Browser wird leider nicht unterstützt.`;

  const textEl = document.createElement('p');
  textEl.innerHTML = `Sie besuchen diese Seite mit ${browser.getBrowserName()} ${browser.getBrowserVersion()}.<br/>Bitte verwenden Sie eine aktuelle Version von Chrome, Firefox, Opera oder Microsoft Edge.`;

  const wrapperEl = document.createElement('div');
  wrapperEl.classList.add('wrapper');
  wrapperEl.appendChild(headline);
  wrapperEl.appendChild(textEl);

  const div = document.createElement('div');
  div.classList.add('browser-not-supported');
  div.appendChild(wrapperEl);

  document.body.appendChild(div);
};

export { isValidBrowser, showNoSupportWarning };
