import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MenuStore } from '@app/shared';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'app-breadcrumbs',
  imports: [CommonModule, FontAwesomeModule, LetDirective],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss',
})
export class BreadcrumbsComponent {
  private menuStore = inject(MenuStore);
  vm$ = this.menuStore.vm$;

  onHistoryClick(idx: number) {
    this.menuStore.goBackToIndex(idx);
  }
}
