import { NgModule } from '@angular/core';
import { FrameComponent } from './frame.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { LogoComponent } from './components/logo/logo.component';
import { ContentComponent } from './components/content/content.component';
import { NavProfileComponent } from './components/nav/nav-profile/nav-profile.component';
import { NavFooterComponent } from './components/nav/nav-footer/nav-footer.component';
import { NavItemComponent } from './components/nav/nav-item/nav-item.component';
import { NavComponent } from './components/nav/nav.component';
import { NavMetaItemComponent } from './components/nav/nav-meta-item/nav-meta-item.component';
import { NavItemGroupComponent } from './components/nav/nav-item-group/nav-item-group.component';
import { ToolbarProfileComponent } from './components/toolbar/toolbar-profile/toolbar-profile.component';

@NgModule({
  declarations: [],
  imports: [
    FrameComponent,
    ToolbarComponent,
    LogoComponent,
    NavComponent,
    NavItemComponent,
    ContentComponent,
    NavProfileComponent,
    NavFooterComponent,
    NavMetaItemComponent,
    NavItemGroupComponent,
    ToolbarProfileComponent,
    NavProfileComponent,
  ],
  exports: [
    FrameComponent,
    ToolbarComponent,
    LogoComponent,
    NavComponent,
    NavItemComponent,
    ContentComponent,
    NavProfileComponent,
    NavFooterComponent,
    NavMetaItemComponent,
    NavItemGroupComponent,
    ToolbarProfileComponent,
    NavProfileComponent,
  ],
})
export class FrameModule {}
