// @ts-strict-ignore
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URIVariables } from '@app/api';
import { URITemplate } from '@app/api/services/uri-template';
import type { ApiResponse, MultipleApiResponses, RequestOptions } from '@givve/ui-kit/models';
import { Observable, forkJoin, map } from 'rxjs';
import { BaseHttpService } from './base-http.service';

@Injectable()
export abstract class MultiObjectHTTPService<T> extends BaseHttpService {
  abstract readonly memberUri: URITemplate;
  abstract readonly collectionUri: URITemplate;
  abstract readonly searchUri: URITemplate;

  private readonly defaultPageSize = 200;

  appendSortParams(params: HttpParams, options: RequestOptions): HttpParams {
    if (options?.sort && options?.direction) {
      params = params.append(`sort[${options.sort}]`, options.direction);
    } else if (options?.sort) {
      params = params.append('sort', options.sort);
    }

    return params;
  }

  appendFilterParams(params: HttpParams, options: RequestOptions): HttpParams {
    if (options?.filter) {
      for (const param of Object.keys(options.filter)) {
        for (const operator of Object.keys(options.filter[param])) {
          params = params.append(`filter[${param}][${operator}]`, options.filter[param][operator]);
        }
      }
    }
    return params;
  }

  appendPageSizeParams(params: HttpParams, options: RequestOptions): HttpParams {
    return params.append('page[size]', options?.pageSize?.toString() || this.defaultPageSize);
  }

  appendSearchFilterParams(params: HttpParams, options: RequestOptions): HttpParams {
    if (typeof options?.filter === 'object' && Object.keys(options.filter).length > 0) {
      for (const param of Object.keys(options.filter)) {
        let value = options.filter[param];
        if (typeof value === 'object') {
          value = value.map((v) => `'${v}'`).join(',');
          params = params.append(`filter[${param}]`, value);
        } else {
          params = params.append(
            `filter[${param}]`,
            ['created_at', 'updated_at'].includes(param) ? value : `'${value}'`
          );
        }
      }
    }
    return params;
  }

  appendSearchSearchParams(params: HttpParams, options: RequestOptions): HttpParams {
    if (typeof options?.search === 'object' && Object.keys(options.search).length) {
      for (const param of Object.keys(options.search)) {
        params = params.append(`search[${param}]`, `'${options.search[param]}'`);
      }
    }
    return params;
  }

  appendSearchPlainParams(params: HttpParams, options: RequestOptions): HttpParams {
    if (options?.with_scores) {
      params = params.append('with_scores', `${options.with_scores}`);
    }

    return params;
  }

  /**
   * The API by default does not allow a union of results when combining multiple
   * search and filters parameters in a query.
   *
   * In order to mitigate this behavior is has been decided to perform multiple API
   * calls based on the number of the search/filter parameters.
   */
  search(options: RequestOptions): Observable<MultipleApiResponses<[]>> {
    let uri = this.searchUri.build({});
    let params = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });

    params = this.appendSearchFilterParams(params, options);
    params = this.appendSearchSearchParams(params, options);

    const createdRequestsPerParam = params
      .toString()
      .split('&')
      .map((parameter) => {
        let params = this.appendSortParams(
          new HttpParams({ fromString: parameter, encoder: BaseHttpService.httpParamsEncoder() }),
          options
        );
        params = this.appendSearchFilterParams(params, options.conOptions);
        params = this.appendSearchSearchParams(params, options.conOptions);
        params = this.appendSearchPlainParams(params, options);

        return this.get(uri, params);
      });

    return forkJoin<MultipleApiResponses<[]>>(createdRequestsPerParam);
  }

  searchWithLinks(links: string[]): Observable<MultipleApiResponses<[]>> {
    const createdRequestsPerLink = links.map((link) => this.get(link));

    return forkJoin<MultipleApiResponses<[]>>(createdRequestsPerLink);
  }

  searchSimple(options: RequestOptions): Observable<ApiResponse<T[]>> {
    let uri = this.searchUri.build({});
    let params = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });
    params = this.appendSearchFilterParams(params, options);
    params = this.appendSearchSearchParams(params, options);
    params = this.appendSortParams(params, options);

    return this.get(uri, params);
  }

  getObjects(uriVariables: URIVariables, options?: RequestOptions): Observable<ApiResponse<T[]>> {
    const uri = this.collectionUri.build(uriVariables);
    let params = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });
    params = this.appendSortParams(params, options);
    params = this.appendFilterParams(params, options);
    params = this.appendPageSizeParams(params, options);
    return this.get(uri, params);
  }

  getObject(uriVariables: URIVariables): Observable<T> {
    const uri = this.memberUri.build(uriVariables);
    return this.get(uri).pipe(map((res: any) => res.data));
  }

  deleteObject(uriVariables: URIVariables): Observable<any> {
    const uri = this.memberUri.build(uriVariables);
    return this.delete(uri);
  }

  createObject(uriVariables: URIVariables, data?: any): Observable<T> {
    const uri = this.collectionUri.build(uriVariables);
    return this.post(uri, data).pipe(map((json: any) => json.data));
  }

  updateObject(uriVariables: URIVariables, data: any): Observable<T> {
    const uri = this.memberUri.build(uriVariables);
    return this.put(uri, data).pipe(map((json: any) => json.data));
  }

  getAllObjects(options?: RequestOptions, customUri?: string): Observable<T[]> {
    const uri = customUri ? customUri : this.collectionUri.build();
    let params = new HttpParams();
    params = this.appendSortParams(params, options);
    params = this.appendFilterParams(params, options);
    params = this.appendPageSizeParams(params, options);
    return this.getAll(uri, params);
  }
}
