import { Injectable } from '@angular/core';
import { Document, Identification, Person } from '@app/api';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import {
  PersonDeleteDocumentPayload,
  PersonDownloadDocumentPayload,
  PersonUpdateSanctionScanResultsStatePayload,
  PersonUpdateSanctionStatePayload,
} from '@app/features/clients/client.models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PeopleHttpService extends MultiObjectHTTPService<Person> {
  memberUri = new URITemplate('{+compliance_api_base}/people/{id}');
  collectionUri = new URITemplate('{+compliance_api_base}/people');
  searchUri = new URITemplate(`{+compliance_api_base}/search/people`);
  sanctionStateUri = new URITemplate(`{+compliance_api_base}/people/{id}/sanction_state`);
  sanctionScanUri = new URITemplate(`{+compliance_api_base}/people/{personId}/sanction_scan_results/{id}`);
  identificationUri = new URITemplate(`{+compliance_api_base}/people/{personId}/identifications/{id}`);
  documentsCollectionUri = new URITemplate(`{+compliance_api_base}/people/{personId}/documents`);
  documentsMemberUri = new URITemplate(`{+compliance_api_base}/people/{personId}/documents/{id}`);
  mergeUri = new URITemplate(`{+compliance_api_base}/people/{personId}/merge`);

  getIdentification(params: { personId: Person['id']; identificationId: Identification['id'] }) {
    const uri = this.identificationUri.build({ personId: params.personId, id: params.identificationId });
    return this.get(uri).pipe(map((response) => response.data));
  }

  uploadDocument(params: {
    personId: Person['id'];
    documentType: Document['document_type'];
    file: File;
    comment?: string;
  }) {
    const uri = this.documentsCollectionUri.build({ personId: params.personId });
    const formData = new FormData();
    formData.append('file', params.file);
    params.comment && formData.append('comment', params.comment);
    formData.append('document_type', params.documentType);
    return this.postFormData(uri, formData).pipe(map((response) => response.data));
  }

  mergePerson(personId: Person['id'], mergePersonId: Person['id']) {
    const uri = this.mergeUri.build({ personId });
    return this.post(uri, { merge_person_id: mergePersonId }).pipe(map((response) => response.data));
  }

  deleteDocument(params: PersonDeleteDocumentPayload) {
    const uri = this.documentsMemberUri.build({ personId: params.personId, id: params.documentId });
    return this.delete(uri);
  }

  getDocument(params: PersonDownloadDocumentPayload) {
    const uri = this.documentsMemberUri.build({ personId: params.personId, id: params.documentId });
    return this.getFile(uri);
  }

  fetchIdNowData(personId: Person['id'], id: Document['id']) {
    const uri = this.identificationUri.build({ personId, id });
    return this.patch(uri);
  }

  updateSanctionState(params: PersonUpdateSanctionStatePayload) {
    const uri = this.sanctionStateUri.build({ id: params.personId });
    return this.put(uri, { sanction_state: params.sanctionState }).pipe(map((response) => response.data));
  }

  updateSanctionScanState(params: PersonUpdateSanctionScanResultsStatePayload) {
    const uri = this.sanctionScanUri.build({ personId: params.personId, id: params.sanctionScanResultId });
    return this.put(uri, {
      sanction_state: params.sanctionState,
      state_change_reason: params.stateChangeReason,
    }).pipe(map((response) => response.data));
  }
}
