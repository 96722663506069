import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { LetDirective } from '@ngrx/component';
import { NavItem } from '../../../models/nav-item';
import { FrameStore } from '../../../store/frame.store';

@Component({
  selector: 'givve-nav-item',
  standalone: true,
  imports: [CommonModule, MatListModule, SvgIconComponent, RouterModule, LetDirective],
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent {
  @Input() item!: NavItem;
  frameStore = inject(FrameStore);

  isNarrowed$ = this.frameStore.menuNarrowed$;
}
