import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '@app/auth/store/auth.facade';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { FullscreenLayoutComponent } from '@givve/ui-kit/components';
import { LetDirective } from '@ngrx/component';

@UntilDestroy()
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  imports: [FullscreenLayoutComponent, MatButtonModule, TranslateModule, LetDirective],
})
export class NotFoundComponent {
  public authFacade = inject(AuthFacade);
  private router = inject(Router);

  isLoggedIn$ = this.authFacade.isLoggedIn$;

  onBack() {
    this.router.navigateByUrl('/login');
  }
}
