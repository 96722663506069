<mat-nav-list>
  <ng-content select="givve-nav-item"></ng-content>
  <ng-content select="givve-nav-item-group"></ng-content>
  <ng-content select="ng-container"></ng-content>
</mat-nav-list>
<div class="spacer"></div>
<div class="meta-nav">
  <ng-content select="givve-nav-meta-item"></ng-content>
</div>
<ng-container *ngrxLet="nav$ as nav">
  @if (nav.isMobile) {
    <div
      class="mobile-spacer"
    ></div>
  }
  @if (nav.isMobile) {
    <givve-nav-profile [user]="nav.user"></givve-nav-profile>
    <ng-content select="givve-nav-footer"></ng-content>
    <div
      class="logout-btn icon-link"
      (click)="onLogout()"
      >
      <svg-icon key="logout"></svg-icon>
      {{ nav.logoutText }}
    </div>
  }
  <div
    class="version-info"
    data-cy="version"
    >
    Version: {{ nav.version }}
  </div>
</ng-container>
