// @ts-strict-ignore
import { Money } from '@givve/ui-kit/models';

/**
 * transform field value to cents amount. validate correct number of decimals
 *
 * @param fieldValue
 * @returns
 */
export const fieldValueToCents = (fieldValue: string): number => {
  let cents;

  if (!fieldValue) {
    return null;
  }

  const [amount, decimals] = fieldValue.split(',');

  if (fieldValue) {
    if (!decimals) {
      // this is the case if no colon or colon but no decimals behind it
      cents = +amount * 100;
    } else {
      // truncate decimals to two digits and calc value
      const truncatedDecimals = decimals.substr(0, 2);
      cents = +amount * 100 + parseInt(truncatedDecimals, 10) * (truncatedDecimals.length === 1 ? 10 : 1);
    }
  } else {
    cents = 0;
  }
  return cents;
};

/**
 * sanitize field values to add decimals if not available
 *
 * @param fieldValue
 * @returns
 */
export const sanitizeAmountFieldValue = (fieldValue: string): string => {
  let updatedFieldValue = '';
  const [amount, decimals] = fieldValue.split(',');

  if (fieldValue) {
    if (!decimals) {
      // this is the case if no colon or colon but no decimals behind it
      updatedFieldValue = amount + ',00';
    } else {
      // truncate decimals to two digits and calc value
      const truncatedDecimals = decimals.substr(0, 2);
      updatedFieldValue = amount + ',' + truncatedDecimals + (truncatedDecimals.length === 1 ? '0' : '');
    }
  }

  return updatedFieldValue;
};

export const valueToMoney = (value: string | null | undefined): Money | null =>
  value
    ? ({
        cents: valueToCents(value),
        currency: 'EUR',
      } as Money)
    : null;

export const valueToCents = (value: string | number): number => Number((+value * 100).toFixed());
