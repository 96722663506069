import { inject } from '@angular/core';
import type { RequestOptions } from '@givve/ui-kit/models';
import { RegExpValidators, RegexService } from '@givve/ui-kit/services';
import moment from 'moment';

const allowedDateFormats = ['DD/MM/YYYY', 'D/M/YYYY', 'DD.MM.YYYY', 'D.M.YYYY', 'DD. MM. YYYY', 'D. M. YYYY'];

export const isDate = (query: string) => moment(query, allowedDateFormats, true).isValid();

/**
 * Create an proper date range format for the Search API
 */
export const queryToDateRange = (query: string) => {
  const startOfDay = moment.utc(query, allowedDateFormats).startOf('day').toISOString().split('.')[0];
  const endOfDay = moment.utc(query, allowedDateFormats).endOf('day').toISOString().split('.')[0];
  return `(${startOfDay};${endOfDay})`;
};

/**
 * Determine the proper query parameters we need for the Search API.
 *
 * We wrap the actual implementation in a closure to avoid runtime errors with Injection Context.
 */
export const buildSearchOptionsParameters = (): ((value: string) => RequestOptions) => {
  const regexService = inject(RegexService);
  return (value) => {
    let options = { search: {}, filter: {} };
    if (regexService.test(RegExpValidators.number(), value)) {
      options.filter['number'] = value;
    } else {
      options.search['name'] = value;
    }

    return options;
  };
};
