<mat-form-field>
  <mat-label> {{ 'common.x_invoice_buyer_reference' | translate }} </mat-label>
  <input
    [formControl]="$any(ngControl?.control)"
    appUppercaseNoWhitespaces
    matInput
  />
  @if (ngControl?.control?.errors?.required) {
    <mat-error>
      {{ 'common.fill_out_field' | translate }}
    </mat-error>
  }
  @if (ngControl?.control?.errors?.pattern) {
    <mat-error>
      {{ 'common.wrong_pattern' | translate }}
    </mat-error>
  }
  @if (ngControl?.control?.errors?.modulo && !ngControl?.control?.errors?.pattern) {
    <mat-error>
      {{ 'common.checksum_field' | translate }}
    </mat-error>
  }
</mat-form-field>
