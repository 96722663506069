import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CardDesign, CardDesignsHttpService, CustomerCardDesignsHttpService, CustomerHttpService } from '@app/api';
import { CsvUploadPreviewDialogComponent } from '@app/features/csv-upload-new/csv-upload-preview-dialog/csv-upload-preview-dialog.component';
import { VoucherService } from '@app/features/voucher-details/services/voucher.service';
import { CSV_PARSE_DEFAULT_CONFIG } from '@givve/csv-upload';
import { Customer, StatusType } from '@givve/ui-kit/models';
import { NotificationService } from '@givve/ui-kit/services';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Papa, ParseResult } from 'ngx-papaparse';
import { Observable, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { CSV_TEMPLATE_TYPE, CsvTemplate, CsvTemplateFormControl, CsvTemplateFormGroup } from '../models/csv-template';
import { CsvTemplateService } from '../services/csv-template.service';
import { CsvUploadService } from '../services/csv-upload.service';
import {
  checkDuplicate,
  checkDuplicateTokens,
  imageIdIsAssignedToCustomer,
  onlyActiveCustomer,
  properEmbossLine1,
  replaceWhiteSpace,
  requiresRenewal,
} from '../utils/co-template-validators';

export interface CoCsvUploadTemplateState {
  statusType: StatusType;
  templates: CsvTemplate[];
  templateHeaders: string[];
  csvFormGroup: FormGroup<{ csvData: FormArray<FormGroup> }> | null;
  csvData: any[];
  csvUploadFormGroup: FormGroup;
  validationData: {
    possibleImageIds: string[];
    possibleCustomers: Customer[];
    possibleTemplates: CsvTemplate[];
    tokens: string[];
    emails: string[];
  };
  error: Error | null;
}

export const DEFAULT_STATE: CoCsvUploadTemplateState = {
  statusType: 'INIT',
  templates: [],
  templateHeaders: [],
  csvFormGroup: null,
  csvData: [],
  csvUploadFormGroup: new FormGroup({
    template: new FormControl<CsvTemplate | null>(null, [Validators.required]),
    customer: new FormControl<Customer | null>(null, [Validators.required, onlyActiveCustomer()]),
    file: new FormControl<File | null>(null, [Validators.required]),
  }),
  validationData: {
    possibleImageIds: [],
    possibleCustomers: [],
    possibleTemplates: [],
    tokens: [],
    emails: [],
  },
  error: null,
};

@Injectable()
export class CoCsvUploadTemplateStore extends ComponentStore<CoCsvUploadTemplateState> {
  readonly vm$ = this.select((state) => state);
  readonly validationData$ = this.select((state) => state.validationData);

  private csvTemplateService = inject(CsvTemplateService);
  private csvUploadService = inject(CsvUploadService);
  private cardDesignsHttpService = inject(CardDesignsHttpService);
  private customerCardDesignService = inject(CustomerCardDesignsHttpService);
  private notificationService = inject(NotificationService);
  private translateService = inject(TranslateService);
  private voucherService = inject(VoucherService);
  private customerService = inject(CustomerHttpService);
  private matDialog = inject(MatDialog);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  papa = inject(Papa);

  constructor() {
    super(DEFAULT_STATE);

    this.patchState({ templates: this.getTemplates() });

    // sobald das Formular vollständig ausgefüllt ist, wird der CSV Preview Dialog geöffnet.
    this.state()
      .csvUploadFormGroup.statusChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((status) => {
          if (status === 'VALID') {
            this.openCsvPreviewDialog({
              csvData: this.state().csvData,
              customerId: this.state().csvUploadFormGroup.getRawValue().customer.id,
              csvTemplate: this.state().csvUploadFormGroup.getRawValue().template,
            });
          }
        })
      )
      .subscribe();
  }

  public readonly preloadDataAndValidatePossibleStructure = this.effect((file$: Observable<File>) =>
    file$.pipe(
      tap(() => this.resetState()),
      tap((file: File) => {
        this.papa.parse(file, {
          ...CSV_PARSE_DEFAULT_CONFIG,
          complete: (parseResult: ParseResult) => {
            //sollten keine Einträge in der CSV Datei sein, dann wird eine Benachrichtigung angezeigt
            if (parseResult.data.length === 0) {
              this.notificationService.open({
                message: this.translateService.instant('csv_upload.no_entries_found_in_csv'),
                config: { duration: 5000 },
              });

              return;
            }

            // Überprüfen ob die Struktur der CSV Datei mit einer der Vorlagen übereinstimmt
            const possibleTemplates = this.getTemplates()
              .map((template) => {
                const valid = this.csvTemplateService.validateCsvStructure(template.form(), parseResult.data);
                if (valid) {
                  return template;
                }
              })
              .filter((template): template is CsvTemplate => template !== undefined);

            // sollte keine passende Vorlage gefunden werden, wird eine Benachrichtigung angezeigt
            if (possibleTemplates.length === 0) {
              this.notificationService.open({
                message: this.translateService.instant('csv_upload.no_matching_template_found_with_structure_from_csv'),
                config: { duration: 5000 },
              });

              return;
            }

            // sollten mehrere oder eine passende Vorlage gefunden werden, wird die CSV Datei genommen
            this.patchState((state) => {
              state.csvUploadFormGroup.patchValue({
                file,
                template: possibleTemplates.length === 1 ? possibleTemplates[0] : null,
              });
              return {
                ...state,
                csvData: parseResult.data,
                validationData: {
                  ...state.validationData,
                  tokens: parseResult.data.filter((row: any) => row.Token).map((row: any) => row.Token),
                  emails: parseResult.data.filter((row: any) => row.Email).map((row: any) => row.Email),
                  possibleTemplates,
                },
              };
            });

            // wir nehmen, wenn vorhanden, den ersten Token aus der Liste um im nächsten Schritt nach dem Customer zu suchen
            if (this.state().validationData.tokens.length) {
              this.preloadCustomer(this.state().validationData.tokens[0]!);
            }

            // sollten wir eine Image Id in der CSV Datei finden, dann werden alle möglichen Kunden dazu geladen, die dieses Design haben
            if (parseResult.data[0]['Image Id']) {
              this.preloadCustomersFromImageId(parseResult.data[0]['Image Id']);
            }
          },
          error: (error, file) => {
            console.error(`Error parsing file "${file.name}": `, error);
            return;
          },
        });
      })
    )
  );

  private readonly preloadCustomersFromImageId = this.effect((imageId$: Observable<string>) =>
    imageId$.pipe(
      tap(() => this.patchState({ statusType: 'UPDATING' })),
      switchMap((imageId) => {
        return this.cardDesignsHttpService.searchByType({ search: imageId, sort: '-image_id' }).pipe(
          map((response) => response.data[0]),
          tapResponse(
            (detail) => {
              this.loadCustomers(detail!);
            },
            () => {
              this.patchState({ statusType: 'DATA' });
            }
          )
        );
      })
    )
  );

  readonly loadCustomers = this.effect((cardDesign$: Observable<CardDesign>) =>
    cardDesign$.pipe(
      tap(() => this.patchState({ statusType: 'UPDATING' })),
      switchMap((cardDesign) => {
        // take the first customersPageSize customer ids
        const idParamValue = cardDesign?.customers
          .slice(0, 200)
          .map((c) => c.id)
          .join(',');
        const options = {
          filter: {
            ['id']: {
              $in: idParamValue,
            },
          },
        };

        return this.customerService.getObjects({}, options).pipe(
          tapResponse(
            (response) => {
              this.patchState((state) => {
                if (response.data.length === 1) {
                  this.state().csvUploadFormGroup.patchValue({ customer: response.data[0] });
                }
                return {
                  ...state,
                  statusType: 'DATA',
                  validationData: { ...state.validationData, possibleCustomers: response.data },
                };
              });
            },
            () => {
              this.patchState({ statusType: 'DATA' });
            }
          )
        );
      })
    )
  );

  private readonly preloadCustomer = this.effect((token$: Observable<string>) =>
    token$.pipe(
      tap(() => this.patchState({ statusType: 'UPDATING' })),
      switchMap((token) => {
        return this.voucherService.loadVoucherByToken(token).pipe(
          switchMap((voucher) =>
            this.customerService.getObject({ id: voucher[0]!.customer.id }).pipe(
              tapResponse(
                (customer) => {
                  this.state().csvUploadFormGroup.patchValue({ customer });
                  this.patchState({ statusType: 'DATA' });
                },
                () => {}
              )
            )
          )
        );
      })
    )
  );

  openCsvPreviewDialog = this.effect(
    (
      data$: Observable<{
        csvData: any[];
        customerId: string;
        csvTemplate: CsvTemplate;
      }>
    ) => {
      return data$.pipe(
        tap(() => this.patchState({ statusType: 'UPDATING' })),
        mergeMap((data) => {
          return this.customerCardDesignService.getObjects({ client_id: data.customerId }).pipe(
            tap(({ data }) => {
              this.patchState((state) => ({
                ...state,
                statusType: 'DATA',
                validationData: {
                  ...state.validationData,
                  possibleImageIds: data.map((cardDesign) => cardDesign.image_id),
                },
              }));
            }),
            mergeMap(() => {
              this.setupCsvData({ csvData: data.csvData, csvTemplate: data.csvTemplate });

              return this.matDialog
                .open(CsvUploadPreviewDialogComponent, {
                  width: '90%',
                  data: { state: this.state(), template: data.csvTemplate },
                })
                .afterClosed()
                .pipe(
                  switchMap((submit: boolean) => {
                    if (submit) {
                      this.patchState({ statusType: 'UPDATING' });

                      const formArray = this.state().csvFormGroup!.get('csvData') as FormArray;
                      const csvData = formArray.getRawValue().map((row, index) => {
                        delete row.index;
                        const formGroup = formArray.at(index) as CsvTemplateFormGroup;
                        Object.keys(row).forEach((key) => {
                          const formControl = formGroup.controls[key] as CsvTemplateFormControl;
                          if (formControl.ignore) {
                            delete row[key];
                          }
                          if (formControl.removeWhenEmpty && !row[key]) {
                            delete row[key];
                          }
                        });
                        return row;
                      });

                      return this.csvUploadService
                        .postCardOrder({
                          entries_data: csvData,
                          customer_id: data.customerId,
                          voucher_category: data.csvTemplate.category,
                          submit_after_creation: true,
                        })
                        .pipe(
                          tapResponse({
                            next: (order) => {
                              this.router.navigate(['/card-orders', order.id]);
                            },
                            error: (error: Error) => {
                              this.patchState({ statusType: 'ERROR', error });
                            },
                          })
                        );
                    } else {
                      return of(null);
                    }
                  })
                );
            })
          );
        })
      );
    }
  );

  openInfoCsvPreviewDialog = this.effect((csvTemplate$: Observable<CsvTemplate>) => {
    return csvTemplate$.pipe(
      mergeMap((csvTemplate) => {
        this.patchState((state) => ({
          ...state,
          validationData: {
            ...state.validationData,
            tokens: csvTemplate.exampleData.map((row: any) => row.Token),
            emails: csvTemplate.exampleData.map((row: any) => row.Email),
            possibleImageIds: ['BONAYOU0000031'],
          },
        }));
        this.setupCsvData({ csvData: csvTemplate.exampleData ?? [{}], csvTemplate });
        return this.matDialog
          .open(CsvUploadPreviewDialogComponent, {
            width: '90%',
            data: { state: this.state(), template: csvTemplate, example: true },
          })
          .afterClosed();
      })
    );
  });

  readonly setupCsvData = this.updater((state, data: { csvData: any[]; csvTemplate: CsvTemplate }) => {
    const keysAndLabelsDeep = this.csvTemplateService.extractKeysAndLabelsDeepWithoutIgnored(data.csvTemplate);
    const templateHeaders: string[] = [];
    Object.keys(keysAndLabelsDeep).forEach((key) => {
      templateHeaders.push(keysAndLabelsDeep[key]);
    });
    const csvFormGroup = this.csvTemplateService.createFormGroup(data.csvTemplate, data.csvData);
    return {
      ...state,
      templateHeaders,
      csvFormGroup,
      csvData: data.csvData,
      statusType: 'DATA',
    };
  });

  readonly resetState = this.updater(() => {
    DEFAULT_STATE.csvUploadFormGroup.reset();
    return { ...DEFAULT_STATE, templates: this.getTemplates() };
  });

  getTemplates(): CsvTemplate[] {
    return [
      {
        type: CSV_TEMPLATE_TYPE.NEW_CARD,
        category: 'benefit_in_kind',
        label: 'csv_upload.label.benefit_in_kind_regional',
        form: () =>
          new FormGroup(
            {
              index: new CsvTemplateFormControl({}),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile 1',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile 2',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              image_id: new CsvTemplateFormControl({
                label: 'Image Id',
                validatorOrOpts: {
                  validators: [Validators.required, imageIdIsAssignedToCustomer(this)],
                },
              }),
              voucher_owner: new FormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.required, Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.required, Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
                email: new CsvTemplateFormControl({
                  label: 'Email',
                  validatorOrOpts: [Validators.email, checkDuplicate(this, 'emails', 'error')],
                  format: replaceWhiteSpace,
                }),
              }),
              constraints: new FormGroup({
                regional: new FormGroup({
                  zip_code: new CsvTemplateFormControl({
                    label: 'Regionale Einschränkung PLZ',
                    validatorOrOpts: [Validators.required],
                  }),
                }),
              }),
              operation: new CsvTemplateFormControl({ value: 'revouchering' }),
            },
            { validators: [properEmbossLine1()] }
          ),
        exampleData: exampleDataNewCardBenefitInKindAndLunch,
      },
      {
        type: CSV_TEMPLATE_TYPE.NEW_CARD,
        category: 'benefit_in_kind',
        label: 'csv_upload.label.benefit_in_kind_national_merchant',
        form: () =>
          new FormGroup(
            {
              index: new CsvTemplateFormControl({}),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile 1',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile 2',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              image_id: new CsvTemplateFormControl({
                label: 'Image Id',
                validatorOrOpts: {
                  validators: [Validators.required, imageIdIsAssignedToCustomer(this)],
                },
              }),
              voucher_owner: new FormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.required, Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.required, Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
                email: new CsvTemplateFormControl({
                  label: 'Email',
                  validatorOrOpts: [Validators.email, checkDuplicate(this, 'emails', 'error')],
                  format: replaceWhiteSpace,
                }),
              }),
              constraints: new FormGroup({
                benefit_in_kind: new FormGroup({
                  merchant_id: new CsvTemplateFormControl({
                    label: 'Einschränkung Merchant Id',
                    validatorOrOpts: [Validators.required],
                  }),
                }),
              }),
              operation: new CsvTemplateFormControl({ value: 'revouchering' }),
            },
            { validators: [properEmbossLine1()] }
          ),
        exampleData: exampleDataNewCardNationalBenefitInKInd,
      },
      {
        type: CSV_TEMPLATE_TYPE.NEW_CARD,
        category: 'benefit_in_kind',
        label: 'csv_upload.label.benefit_in_kind_behoerdenkarte',
        form: () =>
          new FormGroup(
            {
              index: new CsvTemplateFormControl({}),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile 1',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile 2',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              image_id: new CsvTemplateFormControl({
                label: 'Image Id',
                validatorOrOpts: {
                  validators: [Validators.required, imageIdIsAssignedToCustomer(this)],
                },
              }),
              voucher_owner: new FormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.required, Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.required, Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
                email: new CsvTemplateFormControl({
                  label: 'Email',
                  validatorOrOpts: [Validators.email, checkDuplicate(this, 'emails', 'error')],
                  format: replaceWhiteSpace,
                }),
              }),
              constraints: new FormGroup({
                benefit_in_kind: new CsvTemplateFormControl({ value: {} }),
              }),
              operation: new CsvTemplateFormControl({ value: 'revouchering' }),
            },
            { validators: [properEmbossLine1()] }
          ),
        exampleData: exampleDataNewCardUnlimitedBehoerdenAndBezahlkarte,
      },
      {
        type: CSV_TEMPLATE_TYPE.NEW_CARD,
        category: 'lunch',
        form: () =>
          new FormGroup(
            {
              index: new CsvTemplateFormControl({}),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile 1',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile 2',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              image_id: new CsvTemplateFormControl({
                label: 'Image Id',
                validatorOrOpts: {
                  validators: [Validators.required, imageIdIsAssignedToCustomer(this)],
                },
              }),
              voucher_owner: new FormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.required, Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.required, Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
                email: new CsvTemplateFormControl({
                  label: 'Email',
                  validatorOrOpts: [Validators.email, checkDuplicate(this, 'emails', 'error')],
                  format: replaceWhiteSpace,
                }),
              }),
              constraints: new FormGroup({
                lunch: new FormGroup({
                  zip_code: new CsvTemplateFormControl({
                    label: 'Regionale Einschränkung PLZ',
                    validatorOrOpts: [Validators.required],
                  }),
                }),
              }),
              reference_voucher_token: new CsvTemplateFormControl({
                label: 'Bezugskarte',
                removeWhenEmpty: true,
              }),
              transfer_balance: new CsvTemplateFormControl({ value: 'false' }),
              is_replacement: new CsvTemplateFormControl({ value: 'false' }),
              inherit_constraints: new CsvTemplateFormControl({ value: 'false' }),
              operation: new CsvTemplateFormControl({ value: 'revouchering' }),
            },
            { validators: [properEmbossLine1()] }
          ),
        exampleData: exampleDataNewCardBenefitInKindAndLunch,
      },
      {
        type: CSV_TEMPLATE_TYPE.NEW_CARD,
        category: 'unlimited',
        form: () =>
          new FormGroup(
            {
              index: new CsvTemplateFormControl({}),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile 1',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile 2',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              image_id: new CsvTemplateFormControl({
                label: 'Image Id',
                validatorOrOpts: {
                  validators: [Validators.required, imageIdIsAssignedToCustomer(this)],
                },
              }),
              voucher_owner: new FormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.required, Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.required, Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
                email: new CsvTemplateFormControl({
                  label: 'Email',
                  validatorOrOpts: [Validators.email, checkDuplicate(this, 'emails', 'error')],
                  format: replaceWhiteSpace,
                }),
              }),
              constraints: new FormGroup({
                unlimited: new CsvTemplateFormControl({ value: {} }),
              }),
              operation: new CsvTemplateFormControl({ value: 'revouchering' }),
            },
            { validators: [properEmbossLine1()] }
          ),
        exampleData: exampleDataNewCardUnlimitedBehoerdenAndBezahlkarte,
      },
      {
        type: CSV_TEMPLATE_TYPE.NEW_CARD,
        category: 'public_sector',
        form: () =>
          new FormGroup(
            {
              index: new CsvTemplateFormControl({}),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile 1',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile 2',
                validatorOrOpts: [Validators.required, Validators.maxLength(21)],
              }),
              image_id: new CsvTemplateFormControl({
                label: 'Image Id',
                validatorOrOpts: {
                  validators: [Validators.required, imageIdIsAssignedToCustomer(this)],
                },
              }),
              voucher_owner: new FormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
                email: new CsvTemplateFormControl({
                  label: 'Email',
                  validatorOrOpts: [Validators.email, checkDuplicate(this, 'emails', 'error')],
                  format: replaceWhiteSpace,
                }),
              }),
              constraints: new FormGroup({
                public_sector: new CsvTemplateFormControl({ value: {} }),
              }),
              operation: new CsvTemplateFormControl({ value: 'revouchering' }),
            },
            { validators: [properEmbossLine1()] }
          ),
        exampleData: exampleDataNewCardUnlimitedBehoerdenAndBezahlkarte,
      },
      {
        type: CSV_TEMPLATE_TYPE.RECARDING,
        category: 'benefit_in_kind',
        form: () =>
          new FormGroup(
            {
              index: new CsvTemplateFormControl({}),
              token: new CsvTemplateFormControl({
                label: 'Token',
                validatorOrOpts: [Validators.required, checkDuplicateTokens(this)],
                format: replaceWhiteSpace,
              }),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile 1',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile 2',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              image_id: new CsvTemplateFormControl({
                label: 'Image Id',
                validatorOrOpts: {
                  validators: [imageIdIsAssignedToCustomer(this)],
                },
              }),
              voucher_owner: new FormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
                email: new CsvTemplateFormControl({
                  label: 'Email',
                  validatorOrOpts: [Validators.email, checkDuplicate(this, 'emails', 'error')],
                  format: replaceWhiteSpace,
                }),
              }),
              operation: new CsvTemplateFormControl({ value: 'recarding' }),
            },
            { validators: [properEmbossLine1()] }
          ),
        exampleData: exampleDataRecardingAll,
      },
      {
        type: CSV_TEMPLATE_TYPE.RECARDING,
        category: 'lunch',
        form: () =>
          new FormGroup(
            {
              index: new CsvTemplateFormControl({}),
              token: new CsvTemplateFormControl({
                label: 'Token',
                validatorOrOpts: [Validators.required, checkDuplicateTokens(this)],
                format: replaceWhiteSpace,
              }),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile 1',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile 2',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              image_id: new CsvTemplateFormControl({
                label: 'Image Id',
                validatorOrOpts: {
                  validators: [imageIdIsAssignedToCustomer(this)],
                },
              }),
              voucher_owner: new FormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
                email: new CsvTemplateFormControl({
                  label: 'Email',
                  validatorOrOpts: [Validators.email, checkDuplicate(this, 'emails', 'error')],
                  format: replaceWhiteSpace,
                }),
              }),
              operation: new CsvTemplateFormControl({ value: 'recarding' }),
            },
            { validators: [properEmbossLine1()] }
          ),
        exampleData: exampleDataRecardingAll,
      },
      {
        type: CSV_TEMPLATE_TYPE.RECARDING,
        category: 'unlimited',
        form: () =>
          new FormGroup(
            {
              index: new CsvTemplateFormControl({}),
              token: new CsvTemplateFormControl({
                label: 'Token',
                validatorOrOpts: [Validators.required, checkDuplicateTokens(this)],
                format: replaceWhiteSpace,
              }),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile 1',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile 2',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              image_id: new CsvTemplateFormControl({
                label: 'Image Id',
                validatorOrOpts: {
                  validators: [imageIdIsAssignedToCustomer(this)],
                },
              }),
              voucher_owner: new FormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
                email: new CsvTemplateFormControl({
                  label: 'Email',
                  validatorOrOpts: [Validators.email, checkDuplicate(this, 'emails', 'error')],
                  format: replaceWhiteSpace,
                }),
              }),
              operation: new CsvTemplateFormControl({ value: 'recarding' }),
            },
            { validators: [properEmbossLine1()] }
          ),
        exampleData: exampleDataRecardingAll,
      },
      {
        type: CSV_TEMPLATE_TYPE.BULK_RECARDING,
        category: 'benefit_in_kind',
        description: 'csv_upload.type.bulk_recarding.benefit_in_kind',
        form: () =>
          new CsvTemplateFormGroup(
            {
              index: new CsvTemplateFormControl({}),
              KundenNr: new CsvTemplateFormControl({
                label: 'KundenNr',
                ignore: true,
              }),
              KundenName: new CsvTemplateFormControl({
                label: 'KundenName',
                ignore: true,
              }),
              VoucherID: new CsvTemplateFormControl({
                label: 'VoucherID',
                ignore: true,
              }),
              token: new CsvTemplateFormControl({
                label: 'Token',
                validatorOrOpts: [Validators.required, checkDuplicateTokens(this)],
                format: replaceWhiteSpace,
              }),
              Konfiguration: new CsvTemplateFormControl({
                label: 'Konfiguration',
                ignore: true,
              }),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile1',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile2',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              voucher_owner: new CsvTemplateFormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
              }),
              Ablaufdatum: new CsvTemplateFormControl({
                label: 'Ablaufdatum',
                ignore: true,
              }),
              Erneuern: new CsvTemplateFormControl({
                label: 'Erneuern?',
                ignore: true,
              }),
              Namensänderung: new CsvTemplateFormControl({
                label: 'Namensänderung?',
                ignore: true,
              }),
              operation: new CsvTemplateFormControl({ value: 'recarding' }),
            },
            { validators: [properEmbossLine1()] },
            requiresRenewal
          ),
        exampleData: exampleDataBulkRecarding,
      },
      {
        type: CSV_TEMPLATE_TYPE.BULK_RECARDING,
        category: 'lunch',
        form: () =>
          new CsvTemplateFormGroup(
            {
              index: new CsvTemplateFormControl({}),
              KundenNr: new CsvTemplateFormControl({
                label: 'KundenNr',
                ignore: true,
              }),
              KundenName: new CsvTemplateFormControl({
                label: 'KundenName',
                ignore: true,
              }),
              VoucherID: new CsvTemplateFormControl({
                label: 'VoucherID',
                ignore: true,
              }),
              token: new CsvTemplateFormControl({
                label: 'Token',
                validatorOrOpts: [Validators.required, checkDuplicateTokens(this)],
                format: replaceWhiteSpace,
              }),
              Konfiguration: new CsvTemplateFormControl({
                label: 'Konfiguration',
                ignore: true,
              }),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile1',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile2',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              voucher_owner: new CsvTemplateFormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
              }),
              Ablaufdatum: new CsvTemplateFormControl({
                label: 'Ablaufdatum',
                ignore: true,
              }),
              Erneuern: new CsvTemplateFormControl({
                label: 'Erneuern?',
                ignore: true,
              }),
              Namensänderung: new CsvTemplateFormControl({
                label: 'Namensänderung?',
                ignore: true,
              }),
              operation: new CsvTemplateFormControl({ value: 'recarding' }),
            },
            { validators: [properEmbossLine1()] },
            requiresRenewal
          ),
        exampleData: exampleDataBulkRecarding,
      },
      {
        type: CSV_TEMPLATE_TYPE.BULK_RECARDING,
        category: 'unlimited',
        form: () =>
          new CsvTemplateFormGroup(
            {
              index: new CsvTemplateFormControl({}),
              KundenNr: new CsvTemplateFormControl({
                label: 'KundenNr',
                ignore: true,
              }),
              KundenName: new CsvTemplateFormControl({
                label: 'KundenName',
                ignore: true,
              }),
              VoucherID: new CsvTemplateFormControl({
                label: 'VoucherID',
                ignore: true,
              }),
              token: new CsvTemplateFormControl({
                label: 'Token',
                validatorOrOpts: [Validators.required, checkDuplicateTokens(this)],
                format: replaceWhiteSpace,
              }),
              Konfiguration: new CsvTemplateFormControl({
                label: 'Konfiguration',
                ignore: true,
              }),
              emboss_line_1: new CsvTemplateFormControl({
                label: 'Prägezeile1',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              emboss_line_2: new CsvTemplateFormControl({
                label: 'Prägezeile2',
                validatorOrOpts: [Validators.maxLength(21)],
              }),
              voucher_owner: new CsvTemplateFormGroup({
                first_name: new CsvTemplateFormControl({
                  label: 'Vorname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                last_name: new CsvTemplateFormControl({
                  label: 'Nachname',
                  validatorOrOpts: [Validators.maxLength(20)],
                }),
                identifier: new CsvTemplateFormControl({
                  label: 'Personalnummer',
                }),
              }),
              Ablaufdatum: new CsvTemplateFormControl({
                label: 'Ablaufdatum',
                ignore: true,
              }),
              Erneuern: new CsvTemplateFormControl({
                label: 'Erneuern?',
                ignore: true,
              }),
              Namensänderung: new CsvTemplateFormControl({
                label: 'Namensänderung?',
                ignore: true,
              }),
              operation: new CsvTemplateFormControl({ value: 'recarding' }),
            },
            { validators: [properEmbossLine1()] },
            requiresRenewal
          ),
        exampleData: exampleDataBulkRecarding,
      },
    ];
  }
}

const exampleDataNewCardNationalBenefitInKInd = [
  {
    'Prägezeile 1': '',
    'Prägezeile 2': '',
    'Image Id': '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Email: '',
    'Einschränkung Merchant Id': '619514722af35c13b7376353',
  },
  {
    'Prägezeile 1': 'Maximilian Schmidt',
    'Prägezeile 2': 'Schmidt GmbH',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Maximilian',
    Nachname: 'Schmidt',
    Personalnummer: '123456',
    Email: 'max.schmidt@gmx.de',
    'Einschränkung Merchant Id': '619514722af35c13b7376353',
  },
  {
    'Prägezeile 1': 'Maximilian Peter Franz Mustermann Müller Schmidt',
    'Prägezeile 2': 'Mustermann Müller Schmidt GmbH',
    'Image Id': '',
    Vorname: 'Maximilian Peter Franz',
    Nachname: 'Mustermann Müller Schmidt',
    Personalnummer: '',
    Email: 'test1',
    'Einschränkung Merchant Id': '619514722af35c13b7376353',
  },
  {
    'Prägezeile 1': 'Test2',
    'Prägezeile 2': '',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Richard',
    Nachname: 'Müller',
    Personalnummer: '',
    Email: '',
    'Einschränkung Merchant Id': '619514722af35c13b7376353',
  },
];

const exampleDataNewCardBenefitInKindAndLunch = [
  {
    'Prägezeile 1': '',
    'Prägezeile 2': '',
    'Image Id': '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Email: '',
    'Regionale Einschränkung PLZ': '',
  },
  {
    'Prägezeile 1': 'Maximilian Schmidt',
    'Prägezeile 2': 'Schmidt GmbH',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Maximilian',
    Nachname: 'Schmidt',
    Personalnummer: '123456',
    Email: 'max.schmidt@gmx.de',
    'Regionale Einschränkung PLZ': '51688',
  },
  {
    'Prägezeile 1': 'Maximilian Peter Franz Mustermann Müller Schmidt',
    'Prägezeile 2': 'Mustermann Müller Schmidt GmbH',
    'Image Id': '',
    Vorname: 'Maximilian Peter Franz',
    Nachname: 'Mustermann Müller Schmidt',
    Personalnummer: '',
    Email: 'test1',
    'Regionale Einschränkung PLZ': '51688',
  },
  {
    'Prägezeile 1': 'Test2',
    'Prägezeile 2': '',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Richard',
    Nachname: 'Müller',
    Personalnummer: '',
    Email: '',
    'Regionale Einschränkung PLZ': '51687',
  },
];

const exampleDataNewCardUnlimitedBehoerdenAndBezahlkarte = [
  {
    'Prägezeile 1': '',
    'Prägezeile 2': '',
    'Image Id': '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Email: '',
  },
  {
    'Prägezeile 1': 'Maximilian Schmidt',
    'Prägezeile 2': 'Schmidt GmbH',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Maximilian',
    Nachname: 'Schmidt',
    Personalnummer: '123456',
    Email: 'max.schmidt@gmx.de',
  },
  {
    'Prägezeile 1': 'Maximilian Peter Franz Mustermann Müller Schmidt',
    'Prägezeile 2': 'Mustermann Müller Schmidt GmbH',
    'Image Id': '',
    Vorname: 'Maximilian Peter Franz',
    Nachname: 'Mustermann Müller Schmidt',
    Personalnummer: '',
    Email: 'test1',
  },
  {
    'Prägezeile 1': 'Test2',
    'Prägezeile 2': '',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Richard',
    Nachname: 'Müller',
    Personalnummer: '',
    Email: '',
  },
];

const exampleDataRecardingAll = [
  {
    Token: '',
    'Prägezeile 1': '',
    'Prägezeile 2': '',
    'Image Id': '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Email: '',
  },
  {
    Token: '380041274',
    'Prägezeile 1': 'Maximilian Schmidt',
    'Prägezeile 2': 'Schmidt GmbH',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Maximilian',
    Nachname: 'Schmidt',
    Personalnummer: '123456',
    Email: 'max.schmidt@gmx.de',
  },
  {
    Token: '380041272',
    'Prägezeile 1': 'Maximilian Peter Franz Mustermann Müller Schmidt',
    'Prägezeile 2': 'Mustermann Müller Schmidt GmbH',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Maximilian Peter Franz',
    Nachname: 'Mustermann Müller Schmidt',
    Personalnummer: '',
    Email: 'test',
  },
  {
    Token: '380041272',
    'Prägezeile 1': 'Test',
    'Prägezeile 2': 'Test',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Richard',
    Nachname: 'Müller',
    Personalnummer: '',
    Email: 'test',
  },
];

const exampleDataBulkRecarding = [
  {
    KundenNr: '',
    KundenName: '',
    VoucherID: '',
    Token: '',
    Konfiguration: '',
    Prägezeile1: '',
    Prägezeile2: '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Ablaufdatum: '',
    'Erneuern?': 'Ja',
    'Namensänderung?': '',
  },
  {
    KundenNr: '1113874',
    KundenName: 'PL Gutscheinsysteme GmbH',
    VoucherID: '618ba40a2af35c2472971fa8',
    Token: '860036508',
    Konfiguration: '',
    Prägezeile1: '',
    Prägezeile2: '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Ablaufdatum: '31.01.2025',
    'Erneuern?': 'Ja',
    'Namensänderung?': '',
  },
  {
    KundenNr: '1113875',
    KundenName: 'PL Gutscheinsysteme GmbH',
    VoucherID: '618ba40a2af35c2472971fa8',
    Token: '860036507',
    Konfiguration: 'Sachbezug',
    Prägezeile1: 'Maximilian Schmidt',
    Prägezeile2: 'Mustermann Schmidt GmbH',
    Vorname: 'Maximilian',
    Nachname: 'Schmidt',
    Personalnummer: '',
    Ablaufdatum: '31.01.2025',
    'Erneuern?': 'Ja',
    'Namensänderung?': '',
  },
  {
    KundenNr: '1113876',
    KundenName: 'PL Gutscheinsysteme GmbH',
    VoucherID: '618ba40a2af35c2472971fa8',
    Token: '860036506',
    Konfiguration: 'Sachbezug',
    Prägezeile1: 'Maximilian Peter Franz Mustermann Müller Schmidt',
    Prägezeile2: 'Mustermann Müller Schmidt GmbH',
    Vorname: 'Maximilian Peter Franz',
    Nachname: 'Mustermann Müller Schmidt',
    Personalnummer: '',
    Ablaufdatum: '31.01.2025',
    'Erneuern?': 'Ja',
    'Namensänderung?': '',
  },
  {
    KundenNr: '1113874',
    KundenName: 'PL Gutscheinsysteme GmbH',
    VoucherID: '618ba40a2af35c2472971fa8',
    Token: '860036506',
    Konfiguration: 'Sachbezug',
    Prägezeile1: 'Maximilian Musterman',
    Prägezeile2: 'Mustermann GmbH',
    Vorname: 'Maximilian ',
    Nachname: 'Mustermann',
    Personalnummer: '',
    Ablaufdatum: '31.01.2025',
    'Erneuern?': 'Ja',
    'Namensänderung?': '',
  },
];
