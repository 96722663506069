<mat-sidenav-container
  class="sidenav-container"
  *ngrxLet="isMobile$ as isMobile"
>
  <mat-sidenav
    #drawer
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
    [class.is-mobile]="isMobile"
    [class.is-wide]="(menuNarrowed$ | async) === false"
    [class.is-narrow]="menuNarrowed$ | async"
  >
    <ng-content select="givve-frame-logo"></ng-content>
    <div class="toggle-wide-icon">
      <svg-icon
        data-cy="wide-button"
        key="menu"
        (click)="onToggleNav(isMobile)"
      ></svg-icon>
    </div>

    <ng-content select="givve-frame-nav"></ng-content>
  </mat-sidenav>
  <mat-sidenav-content [class.is-wide]="(menuNarrowed$ | async) === false">
    <ng-content select="givve-frame-toolbar"></ng-content>
    <ng-content select="givve-frame-breadcrumbs"></ng-content>
    <ng-content select="givve-frame-content"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
