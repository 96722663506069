import { CommonModule } from '@angular/common';
import { Component, Input, inject, OnInit } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { map, tap } from 'rxjs';
import { FrameService } from '../../../services/frame.service';

@Component({
  selector: 'givve-nav-item-group',
  standalone: true,
  imports: [CommonModule, MatListModule, SvgIconComponent],
  templateUrl: './nav-item-group.component.html',
  styleUrls: ['./nav-item-group.component.scss'],
})
export class NavItemGroupComponent implements OnInit {
  static nextId = 0;
  id = `nav-item-group-${NavItemGroupComponent.nextId++}`;
  @Input({ required: true }) icon = '';
  @Input() highlight = false;
  @Input() segment = '';

  frameService = inject(FrameService);

  open$ = this.frameService.openGroup$.pipe(
    map((id) => id === this.id),
    tap((isOpen) => (this.isOpen = isOpen))
  );
  isOpen = false;

  trigger() {
    this.isOpen = !this.isOpen;

    this.frameService.triggerOpenGroup(this.isOpen ? this.id : '');
  }

  ngOnInit() {
    if (window.location.pathname.indexOf(this.segment) === 0) {
      setTimeout(() => this.trigger());
    }
  }
}
