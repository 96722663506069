import { Pipe, PipeTransform } from '@angular/core';
import { PublicUser } from '@app/shared/models/public-user';

@Pipe({
  name: 'confStateIcon',
  standalone: true,
})
export class ConfStateIconPipe implements PipeTransform {
  transform(user: PublicUser): string {
    const isExpired = !user.expires_at ? false : new Date(user.expires_at) < new Date();
    if (user.confirmed) {
      return 'check';
    } else if (isExpired) {
      return 'user-confirmation-expired';
    } else {
      return 'user-confirmation-open';
    }
  }
}
