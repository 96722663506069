import { UserRole } from '@app/api/interfaces/user.interface';
import { MenuDefinition } from '@app/shared';

export const pushNotificationMenu: MenuDefinition = {
  title: 'common.push_notification',
  level: 'ROOT',
  menu: [
    {
      path: '/push-notification',
      title: 'common.push_notification',
      icon: 'settings',
      roles: [UserRole.SUPERUSER],
    },

    {
      path: '/lunch-tax-rules',
      title: 'common.lunch_tax_rules',
      icon: 'load-orders',
      roles: [UserRole.SUPERUSER],
    },
  ],
};
