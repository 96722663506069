import { HttpErrorResponse } from '@angular/common/http';

const hasErrorsArray = (res) => res?.error?.errors?.length;

const getApiErrorMessage = (e: any): string => {
  let message = e?.message;

  if (hasErrorsArray(e)) {
    message = e.error.errors.map((error: any) => {
      if (error.detail && error.detail !== 'is invalid') {
        return error.detail;
      } else if (error.detail === 'is invalid') {
        return `${error.source?.attributes?.toString()} ${error.detail}`;
      } else {
        return error.code;
      }
    }).filter(Boolean);
  }

  return message || 'An unknown error occurred';
};

const getApiErrorPropertyName = (error) => {
  if (error?.source?.parameter) {
    return error.source.parameter;
  }

  if (error?.source?.pointer) {
    const propAr = error.source.pointer.split('/data/attributes/');
    return !!propAr && Array.isArray(propAr) && propAr.length > 1 ? propAr[1] : null;
  }

  return '';
};

const buildApiErrorMessage = (
  res: HttpErrorResponse
): { messageKey: string; interpolateParams: Record<string, unknown> } => {
  if (res.status === 403) {
    return {
      messageKey: 'api.utils.error.not_allowed_request',
      interpolateParams: {},
    };
  }

  if (!hasErrorsArray(res)) {
    console.log('buildApiErrorMessage :: ', res);
    return {
      messageKey: 'api.utils.error.an_error_occurred',
      interpolateParams: { value: `code: ${res.status}` },
    };
  }

  return getTranslatedMessageError(res.error.errors);
};

const errorMessageMap = {
  'password:invalid': {
    messageKey: 'api.utils.error.invalid_password',
    interpolateParams: {},
  },
  'email:already_member': {
    messageKey: 'api.utils.error.email_address_taken_for_group',
    interpolateParams: {},
  },
  'email:taken': {
    messageKey: 'api.utils.error.email_address_taken',
    interpolateParams: {},
  },
  'name:blank': {
    messageKey: 'api.utils.error.name_is_given',
    interpolateParams: {},
  },
  'name:too_short': {
    messageKey: 'api.utils.error.short_group_name',
    interpolateParams: {},
  },
  'name:taken': {
    messageKey: 'api.utils.error.already_group_with_name',
    interpolateParams: {},
  },
  'confirmation_token:invalid': {
    messageKey: 'api.utils.error.invalid_token',
    interpolateParams: {},
  },
  'token:invalid': {
    messageKey: 'api.utils.error.invalid_token',
    interpolateParams: {},
  },
  'chit:invalid_state': {
    messageKey: 'api.utils.error.receipt_invalid_state',
    interpolateParams: {},
  },
  'chit:readonly_document': {
    messageKey: 'api.utils.error.receipt_cannot_edit',
    interpolateParams: {},
  },
  'user:not_found': {
    messageKey: 'api.utils.error.user_error',
    interpolateParams: {},
  },
  'date:taken': {
    messageKey: 'api.utils.error.cannot_change_date_for_receipt',
    interpolateParams: {},
  },
  'statement:invalid_state': {
    messageKey: 'api.utils.error.accounting_invalid_status',
    interpolateParams: {},
  },
  owner_receipt_approval_grace_period: {
    messageKey: 'api.utils.error.invalid_submission_deadline',
    interpolateParams: {},
  },
};

const getTranslatedMessageError = (errors) => {
  const error = errors[0];
  const prop = getApiErrorPropertyName(error);
  const errorKey = `${prop}:${error.title}`;
  const message = errorMessageMap[errorKey];
  if (message) {
    return message;
  }
  return {
    messageKey: `api.utils.error.an_error_occurred`,
    interpolateParams: { value: `${prop} ${error.title}` },
  };
};

export {
  getApiErrorMessage,
  getApiErrorPropertyName,
  buildApiErrorMessage,
  hasErrorsArray,
  errorMessageMap,
  getTranslatedMessageError,
};
