<div class="tw-flex tw-items-center">
  <mat-chip-option
    class="disabled-opacity-1"
    [class.tw-bg-green-500]="invoice().state === 'paid'"
    [class.tw-bg-gray-500]="invoice().state !== 'paid' && invoice().state !== 'canceled'"
    [class.tw-bg-red-500]="invoice().state === 'canceled'"
    disabled
  >
    <div class="tw-text-white">
      {{ 'invoice-state.' + invoice().state | translateWithFallback: 'invoice-state.unpaid' | translate }}
    </div>
  </mat-chip-option>
  <span
    class="tw-ml-1 tw-cursor-pointer tw-underline"
    *ngIf="invoice().cancellation_number?.length > 2"
    (click)="onCancellationNumberClicked()"
  >
    {{ invoice().cancellation_number }}
  </span>
</div>
