import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SearchFieldControlComponent } from '@givve/ui-kit/components';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-global-search-field',
  templateUrl: './global-search-field.component.html',
  styleUrl: './global-search-field.component.scss',
  imports: [SearchFieldControlComponent, FormsModule, TranslateModule, AsyncPipe],
})
export class GlobalSearchFieldComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  params$ = this.route.queryParams;

  onQueryChange(value: string) {
    let newParams = {};
    value = value?.trim().split(',').join('');
    if (!value) {
      const { query, ...rest } = this.route.snapshot.queryParams;
      newParams = { ...rest };
    } else {
      newParams = {
        ...this.route.snapshot.queryParams,
        query: value,
      } as NavigationExtras;
    }

    this.navigateTo(newParams);
  }

  private navigateTo(params: NavigationExtras) {
    let navigateCommands = ['./search'];
    const tabPath = this.router.url.split('/')[2];
    if (tabPath) {
      navigateCommands.push(tabPath);
    }
    this.router.navigate(navigateCommands, { queryParams: params });
  }
}
