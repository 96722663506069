<form
  [formGroup]="form"
  autocomplete="off"
  *ngrxLet="vm$ as vm"
>
  <mat-form-field hideRequiredMarker>
    <mat-label>{{ title() }}</mat-label>
    <div class="tw-flex tw-flex-row tw-justify-between">
      <input
        type="text"
        matInput
        placeholder="{{ 'client-autocomplete-field.component.placeholder' | translate }}"
        formControlName="client"
        [matAutocomplete]="auto"
        [readonly]="vm.statusType === 'LOADING'"
        data-cy="client-autocomplete-field-text-input"
      />
      @if (vm.statusType === 'LOADING') {
        <mat-spinner [diameter]="17"></mat-spinner>
      }
      @if (vm.statusType !== 'LOADING' && form.get('client').value) {
        <div
          class="tw-inline-flex tw-cursor-pointer"
          (click)="resetAutocompleteField()"
          data-cy="client-autocomplete-field-clear-button"
        >
          <fa-icon icon="circle-xmark" />
        </div>
      }
    </div>
    <mat-autocomplete
      (closed)="handleCloseAutocomplete()"
      #auto="matAutocomplete"
      [displayWith]="clientDisplayWith"
      (optionSelected)="handleClientSelection($event)"
      data-cy="client-autocomplete-field-autocomplete"
    >
      <ng-container>
        @if (form.get('client').value && !vm.clients.length && vm.statusType !== 'INIT') {
          <mat-option
            disabled
            data-cy="client-autocomplete-field-option-not-found"
          >
            {{
              'client-autocomplete-field.component.no_matching_value' | translate: { value: form.get('client').value }
            }}
          </mat-option>
        }
        @for (client of vm.clients; track client.id) {
          <mat-option
            [value]="client"
            data-cy="client-autocomplete-field-option"
          >
            {{ client.number }} --- {{ client.name }}
          </mat-option>
        }
      </ng-container>
    </mat-autocomplete>
    @if (clientFieldIsRequired) {
      <mat-hint>{{ 'common.required' | translate }}</mat-hint>
    }
    @if (form.controls.client.errors?.required) {
      <mat-error>
        {{ 'common.errors.required' | translate }}
      </mat-error>
    }
  </mat-form-field>
</form>
