import { Injectable } from '@angular/core';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import type { Invoice } from '@givve/ui-kit/models';

@Injectable({ providedIn: 'root' })
export class InvoiceHttpService extends MultiObjectHTTPService<Invoice> {
  memberUri = new URITemplate('{+api_v1_base}/invoices/{id}');
  collectionUri = new URITemplate('{+api_v1_base}/invoices');
  searchUri = new URITemplate(`{+api_base}/admin/search/invoices`);
  markAsPaidUri = new URITemplate('{+api_v1_base}/invoices/{id}/mark_as_paid');

  markAsPaid(id: string) {
    const uri = this.markAsPaidUri.build({ id });
    return this.put(uri);
  }
}
