import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { URITemplate } from '@app/api/services/uri-template';
import { PublicUser } from '@app/shared/models/public-user';
import { ApiResponse } from '@givve/ui-kit/models';
import { EMPTY, Observable, expand, map, scan } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicUserService {
  collectionUri = new URITemplate('{+api_v1_base}/public_users');

  httpClient = inject(HttpClient);

  getAll(id: string, clientType: 'customer' | 'multiplier'): Observable<PublicUser[]> {
    const filterForKey = clientType === 'customer' ? 'customer_ids' : 'multiplier_ids';
    let params = new HttpParams();
    params = params.append(`${filterForKey}[]`, id);
    params = params.append('page[size]', '200');
    return this.httpClient.get<ApiResponse<PublicUser[]>>(this.collectionUri.build(), { params }).pipe(
      expand((res: ApiResponse<PublicUser[]>) =>
        res.links.next ? this.httpClient.get<ApiResponse<PublicUser[]>>(res.links.next) : EMPTY
      ),
      scan((acc: PublicUser[], res: ApiResponse<PublicUser[]>) => acc.concat(res.data), [])
    );
  }

  get(email: string): Observable<PublicUser> {
    let params = new HttpParams().set('email', email);
    return this.httpClient
      .get<ApiResponse<PublicUser[]>>(this.collectionUri.build(), { params })
      .pipe(map((res) => res.data[0]!));
  }

  createUser(user: PublicUser): Observable<PublicUser> {
    return this.httpClient.post<ApiResponse<PublicUser>>(this.collectionUri.build(), user).pipe(map((res) => res.data));
  }

  deleteUser(id: string, ids: string[], clientType: 'customer' | 'multiplier'): Observable<void> {
    const filterForKey = clientType === 'customer' ? 'customer_ids' : 'multiplier_ids';
    let params = new HttpParams();
    params = params.append(`${filterForKey}[]`, ids.join(','));
    return this.httpClient.delete<void>(this.collectionUri.build() + '/' + id, { params });
  }

  passwordReset(id: string): Observable<void> {
    return this.httpClient.put<void>(this.collectionUri.build() + '/' + id + '/reset_password', {});
  }

  resendInvitation(id: string): Observable<void> {
    return this.httpClient.put<void>(this.collectionUri.build() + '/' + id + '/resend_invitation', {});
  }
}
