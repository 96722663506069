import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { URITemplate } from '@app/api/services/uri-template';
import { LockType } from '@app/features/clients/voucher-tab/store/client-vouchers.store';
import { VoucherDetails } from '@app/shared/models/voucher-details';
import { ApiResponse, Money } from '@givve/ui-kit/models';
import { Observable, map } from 'rxjs';
import { CashAllowance } from '../models/cash-allowance';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  private httpClient = inject(HttpClient);

  voucherUri = new URITemplate(`{+api_v1_base}/vouchers/{id}`);
  simplevoucherUri = new URITemplate(`{+api_v1_base}/vouchers`);
  cashAllowanceUri = new URITemplate(`{+api_v1_base}/public_sector/cash_allowances/{id}`);

  loadVoucher(id: string): Observable<VoucherDetails> {
    return this.httpClient
      .get<ApiResponse<VoucherDetails>>(this.voucherUri.build({ id }))
      .pipe(map((voucher) => voucher.data));
  }

  loadVoucherByToken(token: string): Observable<VoucherDetails[]> {
    return this.httpClient
      .get<ApiResponse<VoucherDetails[]>>(this.simplevoucherUri.build() + '?filter[token][$in]=' + token)
      .pipe(map((voucher) => voucher.data));
  }

  lockVoucher(voucherId: string, level: LockType): Observable<VoucherDetails> {
    return this.httpClient.put<VoucherDetails>(this.voucherUri.build({ id: voucherId }) + '/lock/' + level, null);
  }

  unlockVoucher(voucherId: string, level: LockType): Observable<VoucherDetails> {
    return this.httpClient.delete<VoucherDetails>(this.voucherUri.build({ id: voucherId }) + '/lock/' + level);
  }

  toggleRenewVoucher(voucherId: string, renew: boolean): Observable<VoucherDetails> {
    return this.httpClient.put<VoucherDetails>(this.voucherUri.build({ id: voucherId }), { renew });
  }

  toggleExcludedLoadOrder(voucherId: string, hide: boolean): Observable<VoucherDetails> {
    return this.httpClient.put<VoucherDetails>(this.voucherUri.build({ id: voucherId }), {
      excluded_from_load_order: hide,
    });
  }

  cancelVoucher(voucherId: string): Observable<VoucherDetails> {
    return this.httpClient.put<VoucherDetails>(this.voucherUri.build({ id: voucherId }) + '/discontinue', null);
  }

  resetAkkumulator(voucherId: string): Observable<VoucherDetails> {
    return this.httpClient.delete<VoucherDetails>(this.voucherUri.build({ id: voucherId }) + '/accumulator');
  }

  unlockPin(voucherId: string): Observable<VoucherDetails> {
    return this.httpClient.delete<VoucherDetails>(this.voucherUri.build({ id: voucherId }) + '/pin/lock');
  }

  unlockCVC(voucherId: string): Observable<VoucherDetails> {
    return this.httpClient.delete<VoucherDetails>(this.voucherUri.build({ id: voucherId }) + '/cvc2/lock');
  }

  transferMoney(voucherId: string, body: { to: string; amount: Money }): Observable<VoucherDetails> {
    return this.httpClient.put<VoucherDetails>(this.voucherUri.build({ id: voucherId }) + '/balance_transfer', body);
  }

  loadMoney(voucherId: string, body: { description: string; amount: Money }): Observable<VoucherDetails> {
    return this.httpClient.put<VoucherDetails>(this.voucherUri.build({ id: voucherId }) + '/load', body);
  }

  loadGPSData(voucherId: string): Observable<void> {
    return this.httpClient.put<void>(this.voucherUri.build({ id: voucherId }) + '/details', {});
  }

  updateReplacement(voucherId: string, replacement_id: string | null): Observable<VoucherDetails> {
    return this.httpClient.put<VoucherDetails>(this.voucherUri.build({ id: voucherId }), {
      replacement_id: replacement_id,
    });
  }

  updateGpsUsageGroup(voucherId: string, gps_usage_group: string, gps_mcc_group: string): Observable<VoucherDetails> {
    return this.httpClient.put<VoucherDetails>(this.voucherUri.build({ id: voucherId }) + '/gps_groups', {
      gps_mcc_group,
      gps_usage_group,
    });
  }

  loadCashAllowanceData(voucherId: string): Observable<CashAllowance> {
    return this.httpClient
      .get<ApiResponse<CashAllowance>>(this.cashAllowanceUri.build({ id: voucherId }))
      .pipe(map((response) => response.data));
  }

  updateCashAllowanceData(voucherId: string, body: CashAllowance): Observable<CashAllowance> {
    return this.httpClient
      .put<ApiResponse<CashAllowance>>(this.cashAllowanceUri.build({ id: voucherId }), body)
      .pipe(map((response) => response.data));
  }
}
