import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-hint-comment-dialog',
  imports: [TranslateModule, MatDialogModule, MatButton, MatFormFieldModule, MatInput, ReactiveFormsModule],
  templateUrl: './edit-hint-comment-dialog.component.html',
})
export class EditHintCommentDialogComponent {
  private fb = inject(FormBuilder);
  private dialogRef = inject(MatDialogRef<EditHintCommentDialogComponent>);

  protected data: { hint: string; translatorKey: 'hint' | 'comment' } = inject(MAT_DIALOG_DATA);

  form = this.fb.group({
    hint: [this.data.hint],
  });

  submit() {
    this.dialogRef.close(this.form.getRawValue().hint);
  }
}
