import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { ApiResponse } from '@givve/ui-kit/models';
import { EMPTY, Observable, expand, scan } from 'rxjs';

/**
 * We have remove the replacement of the symbol ";" Angular does
 * internally since it is used to set the range of date fields from the API
 *
 * https://github.com/angular/angular/blob/13.3.4/packages/common/http/src/params.ts#L102
 */
export const STANDARD_ENCODING_REPLACEMENTS: { [x: string]: string } = {
  '40': '@',
  '3A': ':',
  '24': '$',
  '2C': ',',
  '2B': '+',
  '3D': '=',
  '3F': '?',
  '2F': '/',
};
const STANDARD_ENCODING_REGEX = /%(\d[a-f0-9])/gi;

const standardEncoding = (v: string): string =>
  encodeURIComponent(v).replace(STANDARD_ENCODING_REGEX, (s, t) => STANDARD_ENCODING_REPLACEMENTS[t] ?? s);

export const BASE_HTTP_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Accept-Version': 'v2',
};

@Injectable({ providedIn: 'root' })
export abstract class BaseHttpService {
  public http = inject(HttpClient);

  static httpParamsEncoder(): HttpParameterCodec {
    return {
      encodeKey(key) {
        return standardEncoding(key);
      },
      encodeValue(value) {
        return standardEncoding(value);
      },
      decodeKey(key) {
        return decodeURIComponent(key);
      },
      decodeValue(value) {
        return decodeURIComponent(value);
      },
    };
  }

  private headers(additionalHeaders = {}): HttpHeaders {
    const headersConfig = {
      ...BASE_HTTP_HEADERS,
      ...additionalHeaders,
    };

    return new HttpHeaders(headersConfig);
  }

  get(url: string, params = new HttpParams()): Observable<any> {
    return this.http.get(url, { headers: this.headers(), params });
  }

  getAll<T>(url: string, params = new HttpParams()): Observable<T[]> {
    return this.http.get<ApiResponse<T[]>>(url, { headers: this.headers(), params }).pipe(
      expand((res: ApiResponse<T[]>) => (res.links.next ? this.http.get<ApiResponse<T[]>>(res.links.next) : EMPTY)),
      scan((acc: T[], res: ApiResponse<T[]>) => acc.concat(res.data), [])
    );
  }

  // TODO: Move it to base-http.service of shared lib;
  getFile(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response',
      headers: this.headers({
        Accept: 'application/octet-stream; charset=utf-8',
        'Content-Type': 'application/octet-stream; charset=utf-8',
      }),
    });
  }

  postFormData(url: string, data: FormData): Observable<any> {
    return this.http.post(url, data);
  }

  post(
    url: string,
    data: any,
    headers: Record<string, unknown> = {},
    responseType?: any,
    options?: any
  ): Observable<any> {
    return this.http.post(url, data, {
      ...options,
      headers: this.headers(headers),
      ...(responseType && { responseType: responseType }),
    });
  }

  put(url: string, data: Record<string, unknown> = {}, headers: Record<string, unknown> = {}): Observable<any> {
    return this.http.put(url, JSON.stringify(data), { headers: this.headers(headers) });
  }

  putFormData(url: string, data: FormData): Observable<any> {
    return this.http.put(url, data);
  }

  patch(url: string, data: Record<string, unknown> = {}, headers: Record<string, unknown> = {}): Observable<any> {
    return this.http.patch(url, JSON.stringify(data), { headers: this.headers(headers) });
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url, { headers: this.headers() });
  }
}
