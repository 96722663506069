@if (item.disabled || !(item.path || item.href)) {
  <mat-list-item
    *ngrxLet="isNarrowed$ as isNarrowed"
    data-cy="menu-item"
    [disabled]="item.disabled"
  >
    <svg-icon
      class="svg-icon"
      [ngClass]="{ narrowed: isNarrowed }"
      [key]="item.icon"
    ></svg-icon>
    <ng-container *ngTemplateOutlet="name"></ng-container>
  </mat-list-item>
} @else if (item.path) {
  <a
    mat-list-item
    *ngrxLet="isNarrowed$ as isNarrowed"
    routerLinkActive="active"
    data-cy="menu-item"
    [routerLink]="item.path"
    [queryParams]="item.queryParams ? item.queryParams : null"
    [routerLinkActiveOptions]="
      item?.routerLinkActiveOptions ? item.routerLinkActiveOptions : { exact: item.path === '/' }
    "
    [disabled]="item.disabled"
  >
    <svg-icon
      class="svg-icon"
      [ngClass]="{ narrowed: isNarrowed }"
      [key]="item.icon"
    ></svg-icon>
    <ng-container *ngTemplateOutlet="name"></ng-container>
  </a>
} @else if (item.href) {
  <a
    mat-list-item
    *ngrxLet="isNarrowed$ as isNarrowed"
    [href]="item.href"
    target="_blank"
    data-cy="menu-item"
    [disabled]="item.disabled"
  >
    <svg-icon
      class="svg-icon"
      [ngClass]="{ narrowed: isNarrowed }"
      [key]="item.icon"
    ></svg-icon>
    <ng-container *ngTemplateOutlet="name"></ng-container>
  </a>
}
<ng-template #name>
  <ng-content></ng-content>
</ng-template>
