import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UppercaseNoWhitespacesDirective } from '@app/shared/directives/uppercase-no-whitespaces.directive';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-x-invoice-buyer-reference-field',
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    UppercaseNoWhitespacesDirective,
  ],
  templateUrl: './x-invoice-buyer-reference-field.component.html',
})
export class XInvoiceBuyerReferenceFieldComponent implements ControlValueAccessor, OnInit {
  disabled = input<boolean>(false);
  required = input<boolean>(false);

  onChange: any = () => {};
  onTouched: any = () => {};

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const transformedValue = value.toUpperCase().split(' ').join('');
    this.onChange(transformedValue);
    this.onTouched();
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  constructor(public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.ngControl.control?.setValidators([
      Validators.pattern(/^(\d{2,12})(?:-([A-Z\d]{1,30}))?-(\d{2})$/),
      moduloValidator(),
    ]);
  }

  writeValue(value: string): void {
    // Update the internal state of the custom control
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    // Save the callback function to be called when the value changes
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Save the callback function to be called when the control is touched
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.ngControl.control?.disable() : this.ngControl.control?.enable();
  }
}

const moduloValidator = () => {
  return (control: AbstractControl<string>): ValidationErrors | null => {
    const value = control.value?.trim().split('-').join('');

    if (value) {
      const parsedValues = value.split('').map((char) => parseInt(char, 36));

      // Check if all parsed values are valid numbers
      if (parsedValues.some(isNaN)) {
        return { pattern: true };
      }

      const parsedValue = BigInt(parsedValues.join(''));

      if (parsedValue && parsedValue % BigInt(97) !== BigInt(1)) {
        return { modulo: true };
      }
    }

    return null;
  };
};
