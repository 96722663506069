// @ts-strict-ignore
import type { BaseEntity, MultipleApiResponses } from '@givve/ui-kit/models';

export const removeFromListByIds = (_list: BaseEntity[], ids: string[]) => {
  if (!_list || _list.length === 0) {
    return;
  }
  const list = [..._list];
  ids.forEach((id) => {
    const targetIndex = list.findIndex((el) => el.id === id);
    if (targetIndex !== -1) {
      list.splice(targetIndex, 1);
    }
  });
  return list;
};

export const replaceItemInList = (_list: BaseEntity[], target: any) => {
  if (!_list || _list.length === 0) {
    return;
  }
  const list = [..._list];
  const targetIndex = list.findIndex((el) => el.id === target.id);
  if (targetIndex !== -1) {
    list.splice(targetIndex, 1, target);
  }
  return list;
};

export const toggleById = (_list: any[], id: string, selected: boolean) => {
  if (!_list || _list.length === 0) {
    return;
  }
  const list = [..._list];
  const targetIndex = list.findIndex((el) => el.id === id);
  if (targetIndex !== -1) {
    list.splice(targetIndex, 1, { ..._list[targetIndex], selected });
  }

  return list;
};

export const replaceItemsInList = (_list: BaseEntity[], targets: any) => {
  if (!_list || _list.length === 0) {
    return;
  }
  let list = [..._list];
  targets.forEach((target) => {
    list = replaceItemInList(list, target);
  });
  return list;
};

export const reduceMultiToStructuredResponse = <T>(multiResponses: MultipleApiResponses<T>) => {
  return multiResponses.reduce(
    (finalResponse, current) => ({
      meta: finalResponse.meta.concat(current.meta),
      data: finalResponse.data.concat(current.data),
      links: {
        first: finalResponse.links.first.concat(current.links.first).filter(Boolean),
        last: finalResponse.links.last.concat(current.links.last).filter(Boolean),
        prev: finalResponse.links.prev.concat(current.links.prev).filter(Boolean),
        next: finalResponse.links.next.concat(current.links.next).filter(Boolean),
        self: finalResponse.links.self.concat(current.links.self).filter(Boolean),
      },
    }),
    {
      meta: [],
      data: [],
      links: {
        first: [],
        last: [],
        prev: [],
        next: [],
        self: [],
      },
    }
  );
};
