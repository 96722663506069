import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import type { ApiResponse, LoadOrder } from '@givve/ui-kit/models';
import { BaseHttpService } from '@givve/ui-kit/services';

@Injectable({ providedIn: 'root' })
export class LoadOrdersHttpService extends MultiObjectHTTPService<LoadOrder> {
  memberUri = new URITemplate('{+api_v1_base}/load_orders/{id}');
  collectionUri = new URITemplate('{+api_v1_base}/load_orders');
  searchUri = new URITemplate(`{+api_base}/admin/search/load_orders`);
  entriesUri = new URITemplate('{+api_v1_base}/load_orders/{id}/entries');

  createInvoice(id: string) {
    return this.http.put(`${this.memberUri.build({ id })}/submit`, {});
  }

  performNow(id: string) {
    return this.http.put(this.memberUri.build({ id }) + '/perform_now', {});
  }

  unload(id: string) {
    return this.http.put<ApiResponse<LoadOrder>>(`${this.memberUri.build({ id })}/unload`, null);
  }

  getEntries(params: Params) {
    const uri = this.entriesUri.build({ id: params.id });
    let httpParams = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });
    if (params.sort) {
      const options = {
        sort: (params.direction === 'asc' ? '' : '-') + params.sort,
      };
      httpParams = this.appendSortParams(httpParams, options);
    }
    return this.get(uri, httpParams);
  }
}
