@defer {
  <ng-container *ngrxLet="vm$ as vm">
    <mat-card class="tw-mt-4 tw-overflow-hidden">
      <mat-card-content class="!tw-p-0">
        @if (vm.invoices.statusType === 'LOADING') {
          <givve-content-loading
            loading
            [diameter]="30"
          />
        } @else if (!vm.invoices.data.length) {
          <givve-no-data>
            {{ 'common.no_data' | translate }}
          </givve-no-data>
        } @else {
          <mat-table
            data-cy="order-invoices-table"
            [dataSource]="dataSource"
            matSort
            matSortDisableClear
            [matSortActive]="(params$ | async)['sort']"
            [matSortDirection]="(params$ | async)['direction']"
            givveNavigateOnMatSortChange
          >
            <ng-container matColumnDef="number">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                {{ 'common.invoice' | translate }}
              </mat-header-cell>
              <mat-cell
                data-cy="order-invoices-number-column"
                *matCellDef="let invoice"
              >
                <app-invoice-number [invoice]="invoice" />
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="gross_amount">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                {{ 'common.gross_amount' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let invoice">
                <givve-amount [amount]="invoice.gross_amount" />
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="state">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                {{ 'common.status' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let invoice">
                <app-invoice-state [invoice]="invoice"></app-invoice-state>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                {{ 'common.type' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let invoice"> {{ invoice.type }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="payment_method">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                {{ 'common.payment_method' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let invoice">
                {{ invoice.payment_method | humanizeInvoicePaymentMethodPipe }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="booked_on">
              <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                {{ 'common.submitted_at' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let invoice"> {{ invoice.booked_on | date }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let invoice">
                <app-old-admin-switch [link]="redirectBasePath + invoice.id"></app-old-admin-switch>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" />

            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              class="!tw-cursor-default !tw-bg-transparent"
            />
          </mat-table>

          @if (vm.invoices.links?.next) {
            <givve-load-more
              [loading]="vm.invoices.statusType === 'LOAD_MORE'"
              (loadMore)="loadMoreOrderInvoices(vm.invoices.links.next)"
              [text]="'common.load_more' | translate"
            />
          }
        }
      </mat-card-content>
    </mat-card>
  </ng-container>
} @loading (minimum 500ms) {
  <givve-content-loading
    loading
    [diameter]="30"
  />
} @error {
  <app-error-placeholder />
}
