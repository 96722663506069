import { LunchTaxRulesService } from './../services/lunch-tax-rules.service';

import { Injectable, inject } from '@angular/core';
import { NotificationService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, tap } from 'rxjs';
import { LunchTaxRules } from './../models/lunch-tax-rules';

export interface LunchTaxRulesState {
  lunchTaxRules: LunchTaxRules;
  statusType: 'LOADING' | 'DATA' | 'ERROR' | 'INIT';
}

export const DEFAULT_STATE: LunchTaxRulesState = {
  lunchTaxRules: {
    country_code: 'DE',
  },
  statusType: 'INIT',
};

@Injectable({
  providedIn: 'root',
})
export class LunchTaxRulesStore extends ComponentStore<LunchTaxRulesState> {
  private notification = inject(NotificationService);
  private lunchTaxRulesService = inject(LunchTaxRulesService);
  private translate = inject(TranslateService);
  readonly vm$ = this.select((state) => state);

  constructor() {
    super(DEFAULT_STATE);
  }

  readonly calculateLunchTax = this.effect<LunchTaxRules>((data$) =>
    data$.pipe(
      tap(() => this.patchState({ statusType: 'LOADING' })),
      switchMap((data) =>
        this.lunchTaxRulesService.calculateLunchTax(data).pipe(
          tapResponse(
            (lunchTaxRules) => this.patchState({ lunchTaxRules, statusType: 'DATA' }),
            (error) => {
              this.patchState({ statusType: 'ERROR' });
              this.notification.open({
                message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
              });
            }
          )
        )
      )
    )
  );
}
