<app-table-virtual-scroll-viewport [rowHeight]="'40'">
  <table
    mat-table
    [appVirtualDataSource]="datasource()"
  >
    <ng-container matColumnDef="index">
      <th
        mat-header-cell
        *matHeaderCellDef
      ></th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.index }}
      </td>
    </ng-container>
    @for (column of displayedColumns(); track column; let i = $index) {
      @if (column !== 'index') {
        <ng-container [matColumnDef]="column">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            {{ 'csv-upload-preview-table.header.' + column | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [attr.data-cy]="column + '-' + element.index"
            [appBgError]="
              formArray()
                .at(element.index - 1)
                .get(column).errors
            "
            [matTooltip]="
              formArray()
                .at(element.index - 1)
                .get(column).errors | tooltipHint
            "
          >
            <div>
              {{
                formArray()
                  .at(element.index - 1)
                  .get(column).value || '&nbsp;'
              }}
            </div>
            <div class="tw-text-red-500">
              @for (
                item of formArray()
                  .at(element.index - 1)
                  .get(column).errors | keyvalue;
                track $index
              ) {
                @if (
                  !formArray()
                    .at(element.index - 1)
                    .get(column).errors[item.key].hint
                ) {
                  <span [attr.data-cy]="column + '-' + element.index + '-' + item.key">
                    {{
                      'csv-upload-preview-table.errors.' + item.key
                        | translate
                          : formArray()
                              .at(element.index - 1)
                              .get(column).errors[item.key]
                    }}
                  </span>
                } @else {
                  <!-- emtpy span for e2e tests that we can show that we have an hint -->
                  <span [attr.data-cy]="column + '-' + element.index + '-' + item.key"></span>
                }
              }
            </div>
          </td>
        </ng-container>
      }
    }

    <tr
      [style.height.px]="40"
      mat-header-row
      *matHeaderRowDef="displayedColumns(); sticky: true"
    ></tr>
    <tr
      class="tw-h-10"
      mat-row
      *matRowDef="let row; columns: displayedColumns()"
    ></tr>
  </table>
</app-table-virtual-scroll-viewport>
