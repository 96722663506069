import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CardDesign, URIVariables } from '@app/api';
import { BaseHttpService } from '@app/api/services/base-http.service';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import { ApiResponse, Customer, RequestOptions } from '@givve/ui-kit/models';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CardDesignsHttpService extends MultiObjectHTTPService<CardDesign> {
  memberUri = new URITemplate('{+api_v1_base}/card_designs/{id}');
  collectionUri = new URITemplate('{+api_v1_base}/card_designs');
  searchUri = new URITemplate(`{+api_base}/admin/search/card_designs`);
  alternativesUri = new URITemplate(`{+api_v1_base}/card_designs/{id}/alternatives`);
  alternativesMemberUri = new URITemplate(`{+api_v1_base}/card_designs/{card_design_id}/alternatives/{id}`);
  searchByTypeUri = new URITemplate(`{+api_v1_base}/search?type=card_design`);

  toggleBlocked(uriVariables: URIVariables, state: string): Observable<void> {
    const uri = `${this.memberUri.build(uriVariables)}/${state === 'live' ? 'block' : 'unblock'}`;
    return this.put(uri, {});
  }

  searchByType(requestOptions: RequestOptions): Observable<ApiResponse<CardDesign[]>> {
    const uri = this.searchByTypeUri.build();
    let params = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });
    if (requestOptions.search) {
      params = params.append('query', requestOptions.search as string);
    }
    params = this.appendSortParams(params, requestOptions);
    params = this.appendPageSizeParams(params, requestOptions);
    return this.get(uri, params);
  }

  addAlternative(uriVariables: URIVariables, file: File): Observable<void> {
    const uri = this.alternativesUri.build(uriVariables);
    const formData = new FormData();
    formData.append('image', file);
    return this.postFormData(uri, formData);
  }

  deleteAlternative(uriVariables: URIVariables): Observable<void> {
    const uri = this.alternativesMemberUri.build(uriVariables);
    return this.delete(uri);
  }

  submitAlternative(uriVariables: URIVariables): Observable<void> {
    const uri = `${this.alternativesMemberUri.build(uriVariables)}/submit`;
    return this.put(uri);
  }

  createCardDesign(file: File, customer: Customer): Observable<CardDesign> {
    const uri = this.collectionUri.build();
    const formData = new FormData();
    formData.append('image', file);
    if (customer) {
      formData.append('customer_id', customer.id);
    }
    return this.postFormData(uri, formData).pipe(map((response) => response.data));
  }
}
