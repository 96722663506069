import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BaseHttpService } from '@app/api/services/base-http.service';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import { CardOrderBilling, TrackingData } from '@app/features/card-orders-details/card-orders.model';
import type { ApiResponse, CardOrder } from '@givve/ui-kit/models';
import { Observable, map } from 'rxjs';
import { CardOrderEntry } from '../interfaces/card-order-entry.interface';

@Injectable({ providedIn: 'root' })
export class CardOrderHttpService extends MultiObjectHTTPService<CardOrder> {
  memberUri = new URITemplate('{+api_v1_base}/card_orders/{id}');
  collectionUri = new URITemplate('{+api_v1_base}/card_orders');
  searchUri = new URITemplate(`{+api_base}/admin/search/card_orders`);
  entriesUri = new URITemplate('{+api_v1_base}/card_orders/{id}/entries');
  entriesMemberUri = new URITemplate('{+api_v1_base}/card_orders/{id}/entries/{entryId}');

  getCardOrderEntries(params: Params): Observable<ApiResponse<CardOrderEntry[]>> {
    const uri = this.entriesUri.build({ id: params.id });
    const options = {
      ...(params.sort && { sort: (params.direction === 'asc' ? '' : '-') + params.sort }),
      pageSize: 200,
    };
    let httpParams = new HttpParams({ encoder: BaseHttpService.httpParamsEncoder() });
    httpParams = this.appendSortParams(httpParams, options);
    httpParams = this.appendPageSizeParams(httpParams, options);
    return this.get(uri, httpParams);
  }

  createCardOrder(cardOrderId: string) {
    return this.http.put(this.memberUri.build({ id: cardOrderId }) + '/create_cards', {});
  }

  deleteCardOrder(cardOrderId: string) {
    return this.deleteObject({ id: cardOrderId });
  }

  createCardOrderInvoice(cardOrderId: string) {
    return this.http.put(this.memberUri.build({ id: cardOrderId }) + '/submit', {});
  }

  updateCardOrderEntry(cardOrderId: string, cardOrderEntry: CardOrderEntry) {
    const uri = this.entriesMemberUri.build({ id: cardOrderId, entryId: cardOrderEntry.id });
    return this.http.put(uri, cardOrderEntry);
  }

  updateCardOrder(cardOrderId: string, data: CardOrder | CardOrderBilling): Observable<CardOrder> {
    const uri = this.memberUri.build({ id: cardOrderId });
    return this.http.put<ApiResponse<CardOrder>>(uri, data).pipe(map((response) => response.data));
  }

  loadTrackingData(cardOrderId: string): Observable<TrackingData> {
    return this.http
      .post<ApiResponse<TrackingData>>(this.memberUri.build({ id: cardOrderId }) + '/track_shipment', {})
      .pipe(map((response) => response.data));
  }
}
