import { Representative } from '@app/api';
import { Transaction } from '@app/features/voucher-details/models/transaction';
import { TransactionGroup } from '@app/features/voucher-details/models/transaction-group';
import {
  CardOrder,
  Customer,
  LoadOrder,
  Multiplier,
  ScheduledLoadOrder,
  UnloadOrder,
  Voucher,
  VoucherOwner,
} from '@givve/ui-kit/models';

export function copyEssentialsForItInbox(
  customer: Customer | Multiplier,
  entity:
    | Voucher
    | VoucherOwner
    | Representative
    | LoadOrder
    | UnloadOrder
    | TransactionGroup
    | ScheduledLoadOrder
    | CardOrder
    | Transaction
    | null = null,
  type: ItInboxEssentialType
): void {
  const copyMessage = `${createMainData(customer, entity, type)}`;

  navigator.clipboard.writeText(copyMessage);
}

function createMainData(
  customer: Customer | Multiplier,
  entity:
    | Voucher
    | VoucherOwner
    | Representative
    | LoadOrder
    | Transaction
    | UnloadOrder
    | TransactionGroup
    | ScheduledLoadOrder
    | CardOrder
    | null,
  type: ItInboxEssentialType
): string {
  const isCustomer = 'unload_orders_blocked' in customer;

  let customerId = '';
  let multiplierId = '';

  if (isCustomer) {
    customerId = customer.id ? 'Customer-Id: ' + customer.id : '';
    multiplierId = (customer as Customer).multiplier_id ? 'Multiplier-Id: ' + (customer as Customer).multiplier_id : '';
  } else {
    multiplierId = 'Multiplier-Id: ' + customer.id;
  }

  const entityId = entity?.id ? type + '-Id: ' + entity.id : '';
  return `Link: ${window.location.href}

${customerId}
${multiplierId}
${entityId}`;
}

export type ItInboxEssentialType =
  | 'customer'
  | 'multiplier'
  | 'loadorder'
  | 'unloadorder'
  | 'voucher'
  | 'voucherowner'
  | 'scheduledloadorder'
  | 'cardorder'
  | 'representative'
  | 'transaction';
