<ng-container *ngrxLet="authFacade.user$ as user">
  <givve-frame
    [user]="user"
    [version]="version"
    [logoutText]="'Logout'"
    (logout)="onLogout()"
  >
    <givve-frame-logo
      data-cy="logo"
      routerLink="/"
      [width]="'213'"
      [height]="'42'"
      logo="../../../../assets/img/up-logo-with-wording.png"
    ></givve-frame-logo>
    <givve-frame-toolbar class="tw-z-50">
      @if (!routerHistoryService.currentUrl.includes('/search')) {
        <app-global-search-field class="tw-w-full tw-pr-6"></app-global-search-field>
      }
      <givve-toolbar-profile [user]="user">
        <button
          mat-menu-item
          (click)="onOldAdminClick()"
        >
          {{ 'common.old_admin' | translate }}
        </button>
      </givve-toolbar-profile>
    </givve-frame-toolbar>
    <givve-frame-nav *ngrxLet="menu$ as menu">
      @if (menu.history.length > 1) {
        <ng-container>
          <mat-list-item (click)="onBackClick()">
            <svg-icon
              key="back"
              fontSize="24px"
              class="tw-mr-[22px]"
            ></svg-icon>
            {{ 'common.back' | translate }}
          </mat-list-item>
        </ng-container>
      }

      @for (item of menu.menu; track item.path + item.title + item.dataCount) {
        <ng-container>
          <givve-nav-item
            style="--mat-list-active-indicator-shape: 0px"
            [item]="item"
            (click)="actionTriggered(item.action)"
            [attr.data-cy]="item.dataCy ? item.dataCy : 'menuentry_' + item.title"
          >
            {{ item.title }}
            @if (item.dataCount) {
              ({{ item.dataCount }})
            }
          </givve-nav-item>
        </ng-container>
      }
    </givve-frame-nav>
    <app-breadcrumbs ngProjectAs="givve-frame-breadcrumbs" />
    <givve-frame-content data-cy="main-content">
      <app-content-wrapper>
        <router-outlet></router-outlet>
      </app-content-wrapper>
    </givve-frame-content>
  </givve-frame>
</ng-container>
