import { Injectable } from '@angular/core';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import type { CreditTransfer } from '@givve/ui-kit/models';

@Injectable({ providedIn: 'root' })
export class CreditTransferHttpService extends MultiObjectHTTPService<CreditTransfer> {
  collectionUri = new URITemplate('{+api_v1_base}/credit_transfers');
  memberUri = new URITemplate('{+api_v1_base}/credit_transfers/{id}');
  submitUri = new URITemplate('{+api_v1_base}/credit_transfers/{id}/submit');
  searchUri = new URITemplate(`{+api_base}/admin/search/credit_transfers`);
}
