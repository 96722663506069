export const environment = {
  production: true,
  staging: false,
  domain: 'givve.com',
  apiUrl: 'https://card-search.givve.com/api',
  adminApiUrlV1: 'https://www.givve.com/api/admin',
  adminUrlV1: 'https://www.givve.com/admin',
  complianceApiUrl: 'https://compliance.production.card.givve.io',
  auditApiUrl: 'https://audit.production.card.givve.io',
  b2bUrl: 'https://b2b.givve.com',
  cardSuiteUrl: 'https://card.givve.com',
  sentry: {
    dsn: 'https://251c388db2fc452dbceb92f9fe6fd60f@o229584.ingest.sentry.io/1523880',
  },
  version: require('../../package.json').version,
  changelogPolling: true,
};
