import { Component, ViewEncapsulation } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-loading',
  template: `
    <div class="loading-container">
      <div class="logo-component"></div>
      <mat-progress-bar
        mode="indeterminate"
        color="accent"
      ></mat-progress-bar>
      <div class="loading-label">{{ 'common.loading' | translate }}</div>
    </div>
  `,
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [MatProgressBarModule, TranslateModule],
})
export class LoadingComponent {}
